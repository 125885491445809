import { useEffect, useState } from 'react';
import NavBar from './NavBar';
import BTCLogo from './images/btc.svg';
import BCHLogo from './images/bch.svg';
import ETHLogo from './images/eth.svg';
import LTCLogo from './images/ltc.svg';
import USDTLogo from './images/usdt.svg';
import PMLogo from './images/pm.svg';
import GPLogo from './images/gp.png';
import ITLogo from './images/it.png';

const RateView = () => {
	
	const [trate, setRate] = useState([]);
	const [set, setSet] = useState('');
	
	useEffect(() => {
		document.getElementById('maincontainer').className = 'maincontainer2';
		document.getElementById('ratetab').style.color = '#23527C';
		document.getElementById('ratetab').style.fontWeight = '700';
		document.getElementById('ratetab').style.borderBottom = '1px solid #23527C';
		function rateParams(){
			if(document.getElementById('buypmrate'))
			{
				const pmbuy = document.getElementById('buypmrate').value;
				const pmsell = document.getElementById('sellpmrate').value;
				const btcbuy = document.getElementById('buybtcrate').value;
				const btcsell = document.getElementById('sellbtcrate').value;
				const usdtbuy = document.getElementById('buyusdtrate').value;
				const usdtsell = document.getElementById('sellusdtrate').value;
				const altbuy = document.getElementById('buyaltrate').value;
				const altsell = document.getElementById('sellaltrate').value;
				const giftbuy = document.getElementById('buygiftrate').value;
				const ratedata = [
					{coin: 'PM', coinLong: 'Perfect Money USD', buy: '₦'+pmbuy, sell: '₦'+pmsell, avatar: PMLogo, key: 't1'}, 
					{coin: 'BTC', coinLong: 'Bitcoin BTC', buy: '₦'+btcbuy, sell: '₦'+btcsell, avatar: BTCLogo, key: 't2'}, 
					{coin: 'USDT', coinLong: 'Tether USDT (TRC20)', buy: '₦'+usdtbuy, sell: '₦'+usdtsell, avatar: USDTLogo, key: 't3'}, 
					{coin: 'ETH', coinLong: 'Ethereum ETH', buy: '₦'+altbuy, sell: '₦'+altsell, avatar: ETHLogo, key: 't4'},
					{coin: 'BCH', coinLong: 'Bitcoin Cash BCH', buy: '₦'+altbuy, sell: '₦'+altsell, avatar: BCHLogo, key: 't5'},
					{coin: 'LTC', coinLong: 'Litecoin LTC', buy: '₦'+altbuy, sell: '₦'+altsell, avatar: LTCLogo, key: 't6'},
					{coin: 'ITUNES', coinLong: 'iTunes Gift Card (US)', buy: '₦'+giftbuy, sell: '', avatar: ITLogo, key: 't7'},
					{coin: 'GOOGLE PLAY', coinLong: 'Google Play Card (US)', buy: '₦'+giftbuy, sell: '', avatar: GPLogo, key: 't8'},
				];
				setRate(ratedata);
			}
		}		
		rateParams();
		//setInterval(rateParams, 10000);
	},[]);
	
	if(document.getElementById('buypmrate') && set === '')
	{
		const pmbuy = document.getElementById('buypmrate').value;
		const pmsell = document.getElementById('sellpmrate').value;
		const btcbuy = document.getElementById('buybtcrate').value;
		const btcsell = document.getElementById('sellbtcrate').value;
		const usdtbuy = document.getElementById('buyusdtrate').value;
		const usdtsell = document.getElementById('sellusdtrate').value;
		const altbuy = document.getElementById('buyaltrate').value;
		const altsell = document.getElementById('sellaltrate').value;
		const giftbuy = document.getElementById('buygiftrate').value;
		const ratedata = [
			{coin: 'PM', coinLong: 'Perfect Money USD', buy: '₦'+pmbuy, sell: '₦'+pmsell, avatar: PMLogo, key: 't1'}, 
			{coin: 'BTC', coinLong: 'Bitcoin BTC', buy: '₦'+btcbuy, sell: '₦'+btcsell, avatar: BTCLogo, key: 't2'}, 
			{coin: 'USDT', coinLong: 'Tether USDT (TRC20)', buy: '₦'+usdtbuy, sell: '₦'+usdtsell, avatar: USDTLogo, key: 't3'}, 
			{coin: 'ETH', coinLong: 'Ethereum ETH', buy: '₦'+altbuy, sell: '₦'+altsell, avatar: ETHLogo, key: 't4'},
			{coin: 'BCH', coinLong: 'Bitcoin Cash BCH', buy: '₦'+altbuy, sell: '₦'+altsell, avatar: BCHLogo, key: 't5'},
			{coin: 'LTC', coinLong: 'Litecoin LTC', buy: '₦'+altbuy, sell: '₦'+altsell, avatar: LTCLogo, key: 't6'},
			{coin: 'ITUNES', coinLong: 'iTunes Gift Card (US)', buy: '₦'+giftbuy, sell: '', avatar: ITLogo, key: 't7'},
			{coin: 'GOOGLE PLAY', coinLong: 'Google Play Card (US)', buy: '₦'+giftbuy, sell: '', avatar: GPLogo, key: 't8'},
		];
		setRate(ratedata);
		setSet('1');
	}
	
	const Row = ({coin, coinLong, buy, sell, avatar}, rkey) => (
		<tr key={rkey}>
			<td className="rateRow"><img src={avatar} alt={coin} width="30" height="30"/> {coinLong}</td>
			<td><b><font color='green'>{buy}</font></b></td>
			<td><b><font color='red'>{sell}</font></b></td>
		</tr>
	);
	
  
	const rows = trate?.length > 0 ? (trate?.map((rowData) => <Row {...rowData} rkey={rowData.key}/>)) : (<tr><td colSpan='3'>Loading...</td></tr>);
	
	return [
		(<div className="container__canvas" style={rectStyleWrap} key="1">
			<div className="Context">
				<NavBar />
			</div>
		</div>),
		(<div className="[ stack ] [ container__controls ]" key="2">
		
			<div className="container__control--switch">
				<button className="switch-btn">
					<span className="switch-btnn__marker" id="switch-btnn__marker"></span>
					<span className="center-xy switch-btn__label" ></span>
					<span className="center-xy switch-btn__label" id="4r1" >RATE</span>
					<span className="center-xy switch-btn__label" ></span>
				</button>
				
			</div>
		
			<div className="stack disshow" id="ratedisplay">
				<div style={center}>
					<h4 style={lineTwo} >RATES</h4>
				</div>
				
				<div className="incsizespan_info center">
					SITE RATES
				</div>
				
				<table className="rateTable">
					<thead>
						<tr key="t0">
							<th>Coin</th>
							<th>Buy</th>
							<th>Sell</th>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
				
			</div>			
		</div>)
	]
}
 
const lineTwo = {
   fontSize: '15px',
   color: '#23527c',
   lineHeight: '45px',
   fontWeight: '700'
}

const center = {
   textAlign: 'center'   
}

const rectStyleWrap = {
   minWidth: '250px',
   gridColumn: 1
}

export default RateView;