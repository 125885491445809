import { useEffect } from 'react';
import NavBar from './NavBar';


const ForgotPasswordView = ({ onPostForgotpassword }) => {
	
	useEffect(() => {
		document.getElementById('maincontainer').className = 'maincontainer2';
		document.getElementById('fpasswordtab').style.color = '#23527C';
		document.getElementById('fpasswordtab').style.fontWeight = '700';
		document.getElementById('fpasswordtab').style.borderBottom = '1px solid #23527C';
	},[]);
	
	
	const onSubmitForgotpassword = (e) => {
		var email, msg, output, emailcode, pass, repass;
		email = document.getElementById('forgotemail').value;
		
		if(!email){
			msg = 'Enter a valid email';
			document.getElementById('forgotfeedback').innerHTML = msg;
			document.getElementById('forgotfeedback').className = 'incsizespan_error center';
			return false;
		}
		
		if(!document.getElementById('forgotcodewrap').classList.contains('hide'))
		{
			emailcode = document.getElementById('forgotcode').value;
			pass = document.getElementById('forgotpass').value;
			repass = document.getElementById('forgotrepass').value;
			
			if(!emailcode){
				msg = 'Enter a valid email code';
				document.getElementById('forgotfeedback').innerHTML = msg;
				document.getElementById('forgotfeedback').className = 'incsizespan_error center';
				return false;
			}
			else if(!pass)
			{
				msg = 'Enter a new password';
				document.getElementById('forgotfeedback').innerHTML = msg;
				document.getElementById('forgotfeedback').className = 'incsizespan_error center';
				return false;
			}
			else if(!repass)
			{
				msg = 'Reenter new password';
				document.getElementById('forgotfeedback').innerHTML = msg;
				document.getElementById('forgotfeedback').className = 'incsizespan_error center';
				return false;
			}
			else if(pass !== repass)
			{
				msg = 'Password does not match';
				document.getElementById('forgotfeedback').innerHTML = msg;
				document.getElementById('forgotfeedback').className = 'incsizespan_error center';
				return false;
			}
		}		
		
		if(emailcode){
			output = {
				'rtype': 'fpassreset',
				'email': email,
				'cpass0': emailcode,
				'cpass1': pass
			}
		}else{
			output = {
				'rtype': 'fpass',
				'email': email		
			}
		}
		onPostForgotpassword(output);
	}
	
	return [
		(<div className="container__canvas" style={rectStyleWrap} key="1">
			<div className="Context">
				<NavBar></NavBar>
			</div>
		</div>),
		(<div className="[ stack ] [ container__controls ]" key="2">
		
			<div className="container__control--switch">
				<button className="switch-btn">
					<span className="switch-btnn__marker" id="switch-btnn__marker"></span>
					<span className="center-xy switch-btn__label" ></span>
					<span className="center-xy switch-btn__label" id="4r1" >PASSWORD</span>
					<span className="center-xy switch-btn__label" ></span>
				</button>
				
			</div>
		
			<div className="stack disshow" id="forgotdisplay">
				<div style={center}>
					<h4 style={lineTwo} >FORGOT PASSWORD</h4>
				</div>
				
				<div className="incsizespan_info center">
					REGISTERED EMAIL OR PHONE
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="email" name="forgotemail" id="forgotemail" placeholder="Email Or Phone" />
				</div>
				
				<div className="container__control--input hide" id="forgotcodewrap">
					<input className="text-input" type="text" id="forgotcode" placeholder="Email Code" />
				</div>
				
				<div className="container__control--input password fixpost hide" id="forgotpasswrap">
					<input className="text-input" type="password" id="forgotpass" placeholder="New Password" />
					<span className="action_password icon-eye"></span>
				</div>
				
				<div className="container__control--input password fixpost hide" id="forgotrepasswrap">
					<input className="text-input" type="password" id="forgotrepass" placeholder="Retype New Password" />
					<span className="action_password icon-eye"></span>
				</div>	

				<div className="incsizespan center" id="forgotfeedback">
					
				</div>
				
				<div className="container__buttons">
					<button className="clipboard" id="forgotproceed" onClick={onSubmitForgotpassword}>GET CODE</button>
				</div>
			</div>			
		</div>)
	]
}


const lineTwo = {
   fontSize: '15px',
   color: '#23527c',
   lineHeight: '45px',
   fontWeight: '700'
}

const rectStyleWrap = {
   minWidth: '250px',
   gridColumn: 1
}

const center = {
   textAlign: 'center'   
}

 

export default ForgotPasswordView;