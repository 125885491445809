import { useEffect } from 'react';
import NavBar from './NavBar';

const AffiliateView = () => {
	
	useEffect(() => {
		document.getElementById('maincontainer').className = 'maincontainer2';
	},[]);
	
	
	return [
		(<div className="container__canvas" style={rectStyleWrap} key="1">
			<div className="Context">
				<NavBar />
			</div>
		</div>),
		(<div className="[ stack ] [ container__controls ]" key="2">		
			<div className="container__control--switch">
				<button className="switch-btn">
					<span className="switch-btnn__marker" id="switch-btnn__marker"></span>
					<span className="center-xy switch-btn__label" ></span>
					<span className="center-xy switch-btn__label" id="4r1" >AFFILIATE</span>
					<span className="center-xy switch-btn__label" ></span>
				</button>				
			</div>		
			<div className="stack disshow" id="privacydisplay">
				<div style={center}>
					<h4 style={lineTwo} >AFFILIATE PROGRAM</h4>
				</div>	
				<div className="container__control--input" style={{lineHeight: '2rem'}}>		

With Endopay, you stand a chance of earning <b>WITHOUT LIMIT</b><br/><br/>

<b>How does it works?</b><br/><br/>

<b>I</b> At successful registration, you will be given a unique referral code.<br/>

<b>II</b> Share the referral code with your friends or referral link https://endopay.com/?ref=UNIQUE_REFERRAL_CODE. Login to copy your link.<br/>

<b>III</b> Make sure your friends also register by quoting your referral code or following the referral link.<br/>

<b>IV</b> That is it.<br/><br/>

- Earn commission on ALL of your referrals purchases including all their future purchases - <b>FOREVER! </b><br/><br/>

For example if you advertise your link in forums, twitter, facebook, blog, email etc, you never can tell how many people can join through you. if your referrals or downlines bought $500, you will earn ₦750 INSTANTLY in your bonus account.<br/><br/>

- Our affiliate program has <b>NO LIMITATIONS!</b> The more customers you refer, the more Bonus you earn.
			</div>
		</div>
	</div>)
	]
}
 
const lineTwo = {
   fontSize: '15px',
   color: '#23527c',
   lineHeight: '45px',
   fontWeight: '700'
}

const center = {
   textAlign: 'center'   
}

const rectStyleWrap = {
   minWidth: '250px',
   gridColumn: 1
}

export default AffiliateView;