import { useState } from 'react';
import { Link } from 'react-router-dom';
import TradeSwitch from './TradeSwitch';
import Justsell from './images/justsell.png';
import bankOptions from './bankOptions';

const TradeView = ({ onPostSell, onPostBuy, onPostDeposit, alertMessage, nameLookUp, fetchBankName }) => {
	
	const [sellamount, setSellAmount] = useState('');
	const [sellcurrency, setSellCurrency] = useState('');
	const [sellcurrencydir, setSellCurrencydir] = useState('USD');
	const [sellemail, setSellEmail] = useState('');
	const [buyamount, setBuyAmount] = useState('');
	//const [buyaccount, setBuyAccount] = useState('');
	const [buycurrency, setBuyCurrency] = useState('');
	const [buycurrencydir, setBuyCurrencydir] = useState('USD');
	const [buymethod, setBuyMethod] = useState('');
	const [buyemail, setBuyEmail] = useState('');
	const [depositamount, setDepositAmount] = useState('');
	const [depositcurrency, setDepositCurrency] = useState('');
	const [depositcurrencydir, setDepositCurrencydir] = useState('USD');
	const [depositemail, setDepositEmail] = useState('');
	
	const cryptoarr = ['BTC', 'BCH', 'LTC', 'ETH'];
	const cryptoaltarr = ['BCH', 'LTC', 'ETH'];
	const giftcardarr = ['IT', 'GP'];
	var output;
	
	//ONCURRENCY CHANGE *Amount, Placeholder*
	
	var sellprealt, sellcur = sellcurrency.toLowerCase();
	if(cryptoarr.indexOf(sellcurrency) !== -1 && document.getElementById('sellcurrencydirswitch'))
	{	
		document.getElementById('sellcurrencydirswitch').className = 'disshow';
		document.getElementById('sellcurrencydirdisplay').innerHTML = sellcurrency;
		document.getElementById('sellaccountname').value = ((document.getElementById('sellcurrency').options[document.getElementById('sellcurrency').selectedIndex].text).split(' - '))[1];
		document.getElementById('sellfeedback').innerHTML = '';
		document.getElementById('sellfeedback').className = 'incsizespan center';
		if(document.getElementById('sellcurrencydir').checked){
			document.getElementById('sellamount').setAttribute('placeholder', 'Amount In '+sellcurrency);
			document.getElementById('sellcurrencydir').value = sellcurrency;
		}else{
			document.getElementById('sellamount').setAttribute('placeholder', 'Amount In USD');
			document.getElementById('sellcurrencydir').value = 'USD';
		}
		
		if(sellcurrency){
            if(sellcur !== 'btc'){
				sellprealt = localStorage.getItem("sellcur");
				if(sellprealt === sellcur){
					sellcur = 'alt';
					SkyCalculate(sellcur, 'cc', 'sellamount', 'sellsummary');
				}else{
					localStorage.setItem("sellcur", sellcur);
					//getPrice(sellcur);
					sellcur = 'alt';
					setTimeout(function(){ SkyCalculate(sellcur, 'cc', 'sellamount', 'sellsummary'); }, 800);	
				}
			}else{
				SkyCalculate(sellcur, 'cc', 'sellamount', 'sellsummary');
			}		
			document.getElementById('sellratedisplay').innerHTML = '<b>&#8358;'+(document.getElementById('sell'+sellcur+'rate').value)+'</b>';
			document.getElementById('minselldisplay').innerHTML = '<b>$'+(document.getElementById('sell'+sellcur+'min').value)+'</b>';
			document.getElementById('maxselldisplay').innerHTML = '<b>$'+(document.getElementById('sell'+sellcur+'max').value)+'</b>';			
		}
	}else if(cryptoarr.indexOf(sellcurrency) === -1 && document.getElementById('sellcurrencydirswitch')){
		document.getElementById('sellcurrencydirswitch').className = 'hide';
		document.getElementById('sellamount').setAttribute('placeholder', 'Amount In USD');
		document.getElementById('sellcurrencydir').value = 'USD';
		document.getElementById('sellfeedback').innerHTML = '';
		document.getElementById('sellfeedback').className = 'incsizespan center';
		
		if(sellcurrency){
			if(sellcur !== 'pm' && sellcur !== 'usdt'){sellcur = 'alt';}
			document.getElementById('sellaccountname').value = ((document.getElementById('sellcurrency').options[document.getElementById('sellcurrency').selectedIndex].text).split(' - '))[1];
			document.getElementById('sellratedisplay').innerHTML = '<b>&#8358;'+(document.getElementById('sell'+sellcur+'rate').value)+'</b>';
			document.getElementById('minselldisplay').innerHTML = '<b>$'+(document.getElementById('sell'+sellcur+'min').value)+'</b>';
			document.getElementById('maxselldisplay').innerHTML = '<b>$'+(document.getElementById('sell'+sellcur+'max').value)+'</b>';
			SkyCalculate(sellcur, 'cc', 'sellamount', 'sellsummary');
		}else{
			document.getElementById('sellsummary').style.display = 'none';
			document.getElementById('sellratedisplay').innerHTML = '';
			document.getElementById('minselldisplay').innerHTML = '';
			document.getElementById('maxselldisplay').innerHTML = '';
		}		
	}
	
	var buyprealt, addedrate, buycur = buycurrency.toLowerCase();
	if(cryptoarr.indexOf(buycurrency) !== -1 && document.getElementById('buycurrencydirswitch'))
	{	
		document.getElementById('buyaccountcamera').className = 'camera icon-camera';
		document.getElementById('buycurrencydirswitch').className = 'disshow';
		document.getElementById('buycurrencydirdisplay').innerHTML = buycurrency;
		document.getElementById('buyaccountname').value = ((document.getElementById('buycurrency').options[document.getElementById('buycurrency').selectedIndex].text).split(' - '))[1];
		document.getElementById('buyfeedback').innerHTML = '';
		document.getElementById('buyfeedback').className = 'incsizespan center';
		document.getElementById('buyeth').innerHTML = '';
		document.getElementById('buyeth').className = 'incsizespan center';
		document.getElementById('buyeth').style.display = 'none';
		
		if(buymethod === 'CC'){
			addedrate = parseFloat(document.getElementById('addedrate').value);
		}else{
			addedrate = 0;
		}		
		
		if(buycur === 'btc'){
			document.getElementById('buyratedisplay').innerHTML = '<b>&#8358;'+(parseFloat(document.getElementById('buy'+buycur+'rate').value) + addedrate)+'</b>';
			document.getElementById('minbuydisplay').innerHTML = '<b>$'+(document.getElementById('buy'+buycur+'min').value)+'</b>';
			document.getElementById('maxbuydisplay').innerHTML = '<b>$'+(document.getElementById('buy'+buycur+'max').value)+'</b>';
			document.getElementById('buyaccount').style.display = 'block';
			document.getElementById('buyaccount').setAttribute('placeholder', buycurrency+' Address');
			if(document.getElementById('buycurrencydir').checked){
				document.getElementById('buyamount').setAttribute('placeholder', 'Amount In '+buycurrency);
				document.getElementById('buycurrencydir').value = buycurrency;
			}else{
				document.getElementById('buyamount').setAttribute('placeholder', 'Amount In USD');
				document.getElementById('buycurrencydir').value = 'USD';
			}
		}else if(giftcardarr.indexOf(buycurrency) !== -1){
			document.getElementById('buyratedisplay').innerHTML = '<b>&#8358;'+(parseFloat(document.getElementById('buygiftrate').value) + addedrate)+'</b>';
			document.getElementById('minbuydisplay').innerHTML = '<b>$'+(document.getElementById('buygiftmin').value)+'</b>';
			document.getElementById('maxbuydisplay').innerHTML = '<b>$'+(document.getElementById('buygiftmax').value)+'</b>';
			document.getElementById('buyaccount').style.display = 'none';
		}else{
			document.getElementById('buyratedisplay').innerHTML = '<b>&#8358;'+(parseFloat(document.getElementById('buyaltrate').value) + addedrate)+'</b>';
			document.getElementById('minbuydisplay').innerHTML = '<b>$'+(document.getElementById('buyaltmin').value)+'</b>';
			document.getElementById('maxbuydisplay').innerHTML = '<b>$'+(document.getElementById('buyaltmax').value)+'</b>';
			document.getElementById('buyaccount').style.display = 'block';
			document.getElementById('buyaccount').setAttribute('placeholder', buycurrency+' Address');
			if(document.getElementById('buycurrencydir').checked){
				document.getElementById('buyamount').setAttribute('placeholder', 'Amount In '+buycurrency);
				document.getElementById('buycurrencydir').value = buycurrency;
			}else{
				document.getElementById('buyamount').setAttribute('placeholder', 'Amount In USD');
				document.getElementById('buycurrencydir').value = 'USD';
			}
		}
		if(buycurrency){			
			if(buycur !== 'btc' && giftcardarr.indexOf(buycurrency) === -1){
				buyprealt = localStorage.getItem("buycur");				
				if(buycurrency === 'ETH'){				
					document.getElementById('buyeth').innerHTML = '<b>NB: $10 ETH Fee will be deducted from the amount you are buying</b>';
					document.getElementById('buyeth').className = 'incsizespan_error center';
					document.getElementById('buyeth').style.display = 'block';
				}
				if(buyprealt === buycur){
					SkyCalculate('cc', buycur, 'buyamount', 'buysummary');
				}else{
					localStorage.setItem("buycur", buycur);
					//getPrice(buycur);
					setTimeout(function(){ SkyCalculate('cc', buycur, 'buyamount', 'buysummary'); }, 800);	
				}
			}else{
				SkyCalculate('cc', buycur, 'buyamount', 'buysummary');
			}			
		}
	}else if(cryptoarr.indexOf(buycurrency) === -1 && document.getElementById('buycurrencydirswitch')){
		document.getElementById('buyaccountcamera').className = 'camera';
		document.getElementById('buycurrencydirswitch').className = 'hide';
		document.getElementById('buyamount').setAttribute('placeholder', 'Amount In USD');
		document.getElementById('buycurrencydir').value = 'USD';
		document.getElementById('buyaccount').style.display = 'none';
		document.getElementById('buyfeedback').innerHTML = '';
		document.getElementById('buyfeedback').className = 'incsizespan center';
		document.getElementById('buyeth').innerHTML = '';
		document.getElementById('buyeth').className = 'incsizespan center';
		document.getElementById('buyeth').style.display = 'none';
		if(buymethod === 'CC'){
			addedrate = parseFloat(document.getElementById('addedrate').value);
		}else{
			addedrate = 0;
		}	
		if(buycurrency){			
			if(buycur !== 'pm' && buycur !== 'usdt' && giftcardarr.indexOf(buycurrency) === -1)
			{
				buycur = 'alt';
			}
			else if(giftcardarr.indexOf(buycurrency) !== -1)
			{
				buycur = 'gift';
			}
			document.getElementById('buyaccountname').value = ((document.getElementById('buycurrency').options[document.getElementById('buycurrency').selectedIndex].text).split(' - '))[1];
			document.getElementById('buyamount').setAttribute('placeholder', 'Amount In USD');
			document.getElementById('buyratedisplay').innerHTML = '<b>&#8358;'+(parseFloat(document.getElementById('buy'+buycur+'rate').value) + addedrate)+'</b>';
			document.getElementById('minbuydisplay').innerHTML = '<b>$'+(document.getElementById('buy'+buycur+'min').value)+'</b>';
			document.getElementById('maxbuydisplay').innerHTML = '<b>$'+(document.getElementById('buy'+buycur+'max').value)+'</b>';
			if(buycur === 'pm'){
				document.getElementById('buyaccount').style.display = 'block';
				document.getElementById('buyaccount').setAttribute('placeholder', 'PM Account[U***]');
			}
			
			if(buycur === 'usdt'){
				document.getElementById('buyaccount').style.display = 'block';
				document.getElementById('buyaccount').setAttribute('placeholder', 'Tether USD [TRC20] Address');
			}
			
			SkyCalculate('cc', buycur, 'buyamount', 'buysummary');
		}else{
			document.getElementById('buysummary').style.display = 'none';
			document.getElementById('buyratedisplay').innerHTML = '';
			document.getElementById('minbuydisplay').innerHTML = '';
			document.getElementById('maxbuydisplay').innerHTML = '';
		}	
	}

	var depositprealt, depositcur = depositcurrency.toLowerCase();
	if(cryptoarr.indexOf(depositcurrency) !== -1 && document.getElementById('depositcurrencydirswitch'))
	{	
		document.getElementById('depositcurrencydirswitch').className = 'disshow';
		document.getElementById('depositcurrencydirdisplay').innerHTML = depositcurrency;
		document.getElementById('depositaccountname').value = ((document.getElementById('depositcurrency').options[document.getElementById('depositcurrency').selectedIndex].text).split(' - '))[1];
		document.getElementById('depositfeedback').innerHTML = '';
		document.getElementById('depositfeedback').className = 'incsizespan center';
		if(document.getElementById('depositcurrencydir').checked){
			document.getElementById('depositamount').setAttribute('placeholder', 'Amount In '+depositcurrency);
			document.getElementById('depositcurrencydir').value = depositcurrency;
		}else{
			document.getElementById('depositamount').setAttribute('placeholder', 'Amount In USD');
			document.getElementById('depositcurrencydir').value = 'USD';
		}
		if(depositcurrency){
			if(depositcur !== 'btc' && depositcur !== 'cc' && depositcur !== 'bt'){
				depositprealt = localStorage.getItem("depositcur");
				if(depositprealt === depositcur){
					depositcur = 'alt';
					SkyCalculate(depositcur, 'en', 'depositamount', 'depositsummary');
				}else{
					localStorage.setItem("depositcur", depositcur);
					//getPrice(depositcur);
					depositcur = 'alt';
					setTimeout(function(){ SkyCalculate(depositcur, 'en', 'depositamount', 'depositsummary'); }, 800);	
				}
			}else{
				SkyCalculate(depositcur, 'en', 'depositamount', 'depositsummary');
			}		
			document.getElementById('depositratedisplay').innerHTML = '<b>&#8358;'+(document.getElementById('deposit'+depositcur+'rate').value)+'</b>';
			document.getElementById('mindepositdisplay').innerHTML = '<b>$'+(document.getElementById('deposit'+depositcur+'min').value)+'</b>';
			document.getElementById('maxdepositdisplay').innerHTML = '<b>$'+(document.getElementById('deposit'+depositcur+'max').value)+'</b>';
			SkyCalculate(depositcur, 'en', 'depositamount', 'depositsummary');
		}
	}else if(cryptoarr.indexOf(depositcurrency) === -1 && document.getElementById('depositcurrencydirswitch')){
		document.getElementById('depositcurrencydirswitch').className = 'hide';
		document.getElementById('depositamount').setAttribute('placeholder', 'Amount In USD');
		document.getElementById('depositcurrencydir').value = 'USD';
		document.getElementById('depositfeedback').innerHTML = '';
		document.getElementById('depositfeedback').className = 'incsizespan center';
		if(depositcurrency){
			
			if(depositcur === 'cc' || depositcur === 'bt'){
				document.getElementById('depositaccountname').value = ((document.getElementById('depositcurrency').options[document.getElementById('depositcurrency').selectedIndex].text).split(' - '))[0];
				document.getElementById('depositamount').setAttribute('placeholder', 'Amount In NGN');
				var rate = parseFloat(document.getElementById('deposit'+depositcur+'rate').value);
				var diff = parseFloat(document.getElementById('depositbtadded').value);				
				if(depositcur === 'cc'){
					if(diff > 0){
						rate = rate + diff;
					}
				}
				document.getElementById('depositratedisplay').innerHTML = '<b>&#8358;'+rate+'</b>';
				document.getElementById('mindepositdisplay').innerHTML = '<b>&#8358;'+(document.getElementById('deposit'+depositcur+'min').value)+'</b>';
				document.getElementById('maxdepositdisplay').innerHTML = '<b>&#8358;'+(document.getElementById('deposit'+depositcur+'max').value)+'</b>';
			}else if(depositcur === 'pm'){
				document.getElementById('depositaccountname').value = ((document.getElementById('depositcurrency').options[document.getElementById('depositcurrency').selectedIndex].text).split(' - '))[1];
				document.getElementById('depositratedisplay').innerHTML = '<b>&#8358;'+(document.getElementById('deposit'+depositcur+'rate').value)+'</b>';
				document.getElementById('mindepositdisplay').innerHTML = '<b>$'+(document.getElementById('deposit'+depositcur+'min').value)+'</b>';
				document.getElementById('maxdepositdisplay').innerHTML = '<b>$'+(document.getElementById('deposit'+depositcur+'max').value)+'</b>';
			}else if(depositcur === 'usdt'){
				document.getElementById('depositaccountname').value = ((document.getElementById('depositcurrency').options[document.getElementById('depositcurrency').selectedIndex].text).split(' - '))[1];
				document.getElementById('depositratedisplay').innerHTML = '<b>&#8358;'+(document.getElementById('deposit'+depositcur+'rate').value)+'</b>';
				document.getElementById('mindepositdisplay').innerHTML = '<b>$'+(document.getElementById('deposit'+depositcur+'min').value)+'</b>';
				document.getElementById('maxdepositdisplay').innerHTML = '<b>$'+(document.getElementById('deposit'+depositcur+'max').value)+'</b>';
			}else {
				document.getElementById('depositaccountname').value = ((document.getElementById('depositcurrency').options[document.getElementById('depositcurrency').selectedIndex].text).split(' - '))[1];	
				depositcur = 'alt';
				document.getElementById('depositratedisplay').innerHTML = '<b>&#8358;'+(document.getElementById('deposit'+depositcur+'rate').value)+'</b>';
				document.getElementById('mindepositdisplay').innerHTML = '<b>$'+(document.getElementById('deposit'+depositcur+'min').value)+'</b>';
				document.getElementById('maxdepositdisplay').innerHTML = '<b>$'+(document.getElementById('deposit'+depositcur+'max').value)+'</b>';
			}			
			SkyCalculate(depositcur, 'en', 'depositamount', 'depositsummary')
		}else{
			document.getElementById('depositsummary').style.display = 'none';
			document.getElementById('depositratedisplay').innerHTML = '';
			document.getElementById('mindepositdisplay').innerHTML = '';
			document.getElementById('maxdepositdisplay').innerHTML = '';
		}	
	}
	
		
	var buypreem, sellpreem, depositpreem, pattern, depattern, depattern2; 
	if(validateEmail(buyemail)){
		buypreem = localStorage.getItem("buyemail");
		if(buypreem !== buyemail){
			output = {
				type: 'cc',
				search: buyemail,
				product: 'buy',
				field: '',
				acctname: '',
				acctresolved: '',
				regresolved: 'buyreg'
			};
			nameLookUp(output);
			localStorage.setItem("buyemail", buyemail);
		}
	}else if(document.getElementById('buyemailsummary')){
		buypreem = localStorage.getItem("buyemail");
		if(buypreem !== buyemail){
			localStorage.setItem("buyemail", buyemail);
			pattern = /^[\d +]+$/i
			depattern = buyemail.match(pattern);
			depattern2 = ''+depattern;
			if(depattern !== null && (depattern2.length === 11 || depattern2.length === 13 || depattern2.length === 14)){
				output = {
					type: 'cc',
					search: buyemail,
					product: 'buy',
					field: '',
					acctname: '',
					acctresolved: '',
					regresolved: 'buyreg'
				}
				nameLookUp(output);
			}
			document.getElementById('buyemailsummary').className = 'incsizespan_error center';
			document.getElementById('buyemailsummary').innerHTML = '';
			document.getElementById('buyemailsummary').style.display = 'none';
		}		
	}
	
	if(validateEmail(sellemail)){	
		sellpreem = localStorage.getItem("sellemail");
		document.getElementById("sellreg").value = '0';
		document.getElementById('sellfeedback').innerHTML = '';
		document.getElementById('sellfeedback').className = 'incsizespan center';
		if(sellpreem !== sellemail){
			output = {
				type: 'cc',
				search: sellemail,
				product: 'sell',
				field: '',
				acctname: '',
				acctresolved: '',
				regresolved: 'sellreg'
			};
			nameLookUp(output);
			localStorage.setItem("sellemail", sellemail);
			if(document.getElementById('sellbankinfo1')){
				document.getElementById('sellbankaccount').value = '';
				document.getElementById('sellbanksummary').style.display = 'none';
				document.getElementById('sellbankinfo1').className = 'container__control--input hide';	
				document.getElementById('sellbankinfo2').className = 'container__control--select hide';	
				document.getElementById('sellbankinfo3').className = 'container__control--input hide';	
				document.getElementById('sellbanksummary').className = 'incsizespan hide';									
			}
		}
	}else if(document.getElementById('sellemailsummary')){
		document.getElementById('sellfeedback').innerHTML = '';
		document.getElementById('sellfeedback').className = 'incsizespan center';
		sellpreem = localStorage.getItem("sellemail")
		if(sellpreem !== sellemail){
			localStorage.setItem("sellemail", sellemail);
			pattern = /^[\d +]+$/i
			depattern = sellemail.match(pattern);
			depattern2 = ''+depattern;
			if(depattern !== null && (depattern2.length === 11 || depattern2.length === 13 || depattern2.length === 14)){
				output = {
					type: 'cc',
					search: sellemail,
					product: 'sell',
					field: '',
					acctname: '',
					acctresolved: '',
					regresolved: 'sellreg'
				};
				nameLookUp(output);
			}
			document.getElementById('sellemailsummary').className = 'incsizespan_error center';
			document.getElementById('sellemailsummary').innerHTML = '';
			document.getElementById('sellemailsummary').style.display = 'none';
			if(document.getElementById('sellbankinfo1')){
				document.getElementById('sellbankaccount').value = '';
				document.getElementById('sellbanksummary').style.display = 'none';
				document.getElementById('sellbankinfo1').className = 'container__control--input hide';	
				document.getElementById('sellbankinfo2').className = 'container__control--select hide';	
				document.getElementById('sellbankinfo3').className = 'container__control--input hide';	
				document.getElementById('sellbanksummary').className = 'incsizespan hide';
			}
		}		
	}
	
	if(validateEmail(depositemail)){		
		depositpreem = localStorage.getItem("depositemail");
		if(depositpreem !== depositemail){
			output = {
				type: 'cc',
				search: depositemail,
				product: 'deposit',
				field: '',
				acctname: '',
				acctresolved: '',
				regresolved: 'depositreg'
			}
			nameLookUp(output);
			localStorage.setItem("depositemail", depositemail);
		}
	}else if(document.getElementById('depositemailsummary')){
		depositpreem = localStorage.getItem("depositemail");
		if(depositpreem !== depositemail){
			localStorage.setItem("depositemail", depositemail);
			pattern = /^[\d +]+$/i
			depattern = depositemail.match(pattern);
			depattern2 = ''+depattern;
			if(depattern !== null && (depattern2.length === 11 || depattern2.length === 13 || depattern2.length === 14)){
				output = {
					type: 'cc',
					search: depositemail,
					product: 'deposit',
					field: '',
					acctname: '',
					acctresolved: '',
					regresolved: 'depositreg'
				};
				nameLookUp(output);
			}
			document.getElementById('depositemailsummary').className = 'incsizespan_error center';
			document.getElementById('depositemailsummary').innerHTML = '';
			document.getElementById('depositemailsummary').style.display = 'none';
		}		
	}
	
		
	//ONSWITCH CHANGE
	const handleSwitchChange = (e) => {
		//e.preventDefault()
		var target, thisid, thiscurid, thistype, thiscur;
        target = e.target;
        thisid = target.id;
		thiscurid = thisid.slice(0, -3);
		thistype = thisid.slice(0, -11);
		thiscur = document.getElementById(thiscurid).value;
        
        if(target.checked){		
			document.getElementById(thistype+'amount').setAttribute('placeholder', 'Amount In '+thiscur);
			document.getElementById(thistype+'currencydir').value = thiscur;
			if(thistype === 'sell'){
				document.getElementById('sellfeedback').innerHTML = '';
				document.getElementById('sellfeedback').className = 'incsizespan center';
				setSellCurrencydir(thiscur);
				SkyCalculate(thiscur.toLowerCase(), 'cc', thistype+'amount', thistype+'summary');			
			}else if(thistype === 'buy'){				
				setBuyCurrencydir(thiscur);
				SkyCalculate('cc', thiscur.toLowerCase(), thistype+'amount', thistype+'summary');
			}else if(thistype === 'deposit'){
				setDepositCurrencydir(thiscur);
				SkyCalculate(thiscur.toLowerCase(), 'en', thistype+'amount', thistype+'summary');			
			}
        }else{
			document.getElementById(thistype+'amount').setAttribute('placeholder', 'Amount In USD');
			document.getElementById(thistype+'currencydir').value = 'USD';	
			if(thistype === 'sell'){
				document.getElementById('sellfeedback').innerHTML = '';
				document.getElementById('sellfeedback').className = 'incsizespan center';
				setSellCurrencydir('USD');
				SkyCalculate(thiscur.toLowerCase(), 'cc', thistype+'amount', thistype+'summary');
			}else if(thistype === 'buy'){
				setBuyCurrencydir('USD');
				SkyCalculate('cc', thiscur.toLowerCase(), thistype+'amount', thistype+'summary');
			}else if(thistype === 'deposit'){
				setDepositCurrencydir('USD');
				SkyCalculate(thiscur.toLowerCase(), 'en', thistype+'amount', thistype+'summary');
			}
        }       
    }
	
	const handleKeyUp = (e) => {
		//e.preventDefault()
		var target, thisid, thistype, thisv; // thiscurid, thiscur,
        target = e.target;
        thisid = target.id;
		thisv = thisid.substring(0, 3);
		if(thisv === 'sel'){thistype = 'sell';}else if(thisv === 'buy'){thistype = 'buy';}else if(thisv === 'dep'){thistype = 'deposit';}
				
		if(thistype === 'sell'){
			SkyCalculate(sellcur, 'cc', 'sellamount', 'sellsummary');
		}else if(thistype === 'buy'){
			SkyCalculate('cc', buycur, 'buyamount', 'buysummary');
		}else if(thistype === 'deposit'){
			SkyCalculate(depositcur, 'en', 'depositamount', 'depositsummary');
		}		
	}
	
	const onPhoneSMS = (e) => {
		if(document.getElementById('sellsms').checked){
			document.getElementById('sellsmsphonewrap').className = 'container__control--input';	
		}else{
			document.getElementById('sellsmsphonewrap').className = 'container__control--input hide';
		}
	}
		
	const handlePhoneSMS = (e) => {
		//e.preventDefault()
		var target, thisid, thistype, thisv;
        target = e.target;
        thisid = target.id;
		thisv = thisid.substring(0, 3);	
		if(thisv === 'sel'){thistype = 'sell';}else if(thisv === 'buy'){thistype = 'buy';}else if(thisv === 'dep'){thistype = 'deposit';}
		
		if(thistype === 'sell'){
			
		}
	}
	
	const handleFetchBank = (e) => {
		e.preventDefault();
		if(document.getElementById('sellcontinue').checked){
			var a, b, c, acct, bank;
			a = 'sellbankaccount';
			b = 'sellbankname';
			c = 'sellbanksummary';
			acct = document.getElementById(a).value;
			bank = document.getElementById(b).value;
			const mainString = bank.toLowerCase();
			const searchStrings = ["opay", "palm"];
			document.getElementById(a+'name').value = '';
			document.getElementById(c).style.display = 'none';
			document.getElementById(c).className = 'incsizespansms center hide';					
			
			if(acct.length !== 10){
				if(acct.length === 11 && searchStrings.some(searchString => mainString.includes(searchString))){		
					
				}else{
					return false;
				}
			}else if(!bank){
				return false;
			}
			
			output = {
				acct: a,
				bank: b,
				display: c
			};
			fetchBankName(output);	
		}else{
			return false;
		}
	}
	
	const onSubmitSell = (e) => {
		var unit, isnew, isreg, sms, smsphone, amount, cur, altcoin, account, accountname, method, rate, email, sellbankacctconfirm, bankname, bankaccount, bankaccountname, emailcode, msg, currency;
		
		unit = document.getElementById('sellcurrencydir').value;
		isreg = document.getElementById('isreg').value;
		isnew = document.getElementById('isnew').value;
		amount = document.getElementById('sellamount').value;
		currency = document.getElementById('sellcurrency').value;
		email = document.getElementById('sellemail').value;
		sellbankacctconfirm = document.getElementById('sellreg').value;
		accountname = document.getElementById('sellaccountname').value;
		altcoin = '0';
		account = '';
		method = '';
		cur = currency.toLowerCase();
		document.getElementById('sellfeedback').innerHTML = '';
		document.getElementById('sellfeedback').className = 'incsizespan center';		
		
		if(document.getElementById('sellsms').checked){
			sms = '1';
			smsphone = document.getElementById('sellsmsphone').value;
		}else{
			sms = '0';
		}
		
		if(document.getElementById('sellcontinue') && document.getElementById('sellcontinue').checked){
			bankaccount = document.getElementById('sellbankaccount').value;
			bankname = document.getElementById('sellbankname').value;
			bankaccountname = document.getElementById('sellbankaccountname').value;
		}		
		
		if(cryptoaltarr.indexOf(sellcurrency) !== -1){
			cur = 'alt';
			altcoin = '1';
		}	
		
		if(!sellcurrency){
			msg = 'Please select a currency to sell';
			document.getElementById('sellfeedback').innerHTML = msg;
			document.getElementById('sellfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!amount)
		{
			msg = 'Please enter amount to sell';
			document.getElementById('sellfeedback').innerHTML = msg;
			document.getElementById('sellfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!email)
		{
			msg = 'Please enter email or phone';
			document.getElementById('sellfeedback').innerHTML = msg;
			document.getElementById('sellfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(email && sellemail === '0')
		{
			msg = 'Please reenter email or phone';
			document.getElementById('sellfeedback').innerHTML = msg;
			document.getElementById('sellfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!email && sellemail === '1')
		{
			msg = 'Please reenter your bank account info';
			document.getElementById('sellfeedback').innerHTML = msg;
			document.getElementById('sellfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(sellbankacctconfirm === '0')
		{
			if(document.getElementById('sellcontinue') && document.getElementById('sellcontinue').checked){
				bankaccount = document.getElementById('sellbankaccount').value;
				bankname = document.getElementById('sellbankname').value;
				bankaccountname = document.getElementById('sellbankaccountname').value;
				if(!bankaccount){
					msg = 'Please enter bank account number';
					document.getElementById('sellfeedback').innerHTML = msg;
					document.getElementById('sellfeedback').className = 'incsizespan_error center';					
					return false;
				}else if(bankaccount.length !== 10){
					msg = 'Enter valid NUBAN bank account number';
					document.getElementById('sellfeedback').innerHTML = msg;
					document.getElementById('sellfeedback').className = 'incsizespan_error center';					
					return false;
				}else if(!bankname){
					msg = 'Please select bank name';
					document.getElementById('sellfeedback').innerHTML = msg;
					document.getElementById('sellfeedback').className = 'incsizespan_error center';
					return false;
				}
			}else{
				if(document.getElementById('sellcontinue')){
					msg = 'Please check box to continue';
					document.getElementById('sellfeedback').innerHTML = msg;
					document.getElementById('sellfeedback').className = 'incsizespan_error center';
					return false;
				}else{
					document.getElementById('sellreg').value = '1';
				}
			}
		}
		if(isnew === '1'){
			emailcode = document.getElementById('sellemailcode').value;
			if(emailcode.length !== 5){
				msg = 'Please enter email code';
				document.getElementById('sellfeedback').innerHTML = msg;
				document.getElementById('sellfeedback').className = 'incsizespan_error center';
				return false;
			}else if(emailcode !== localStorage.getItem("appsellexcode")){
				msg = 'Please enter a valid email code';
				document.getElementById('sellfeedback').innerHTML = msg;
				document.getElementById('sellfeedback').className = 'incsizespan_error center';
				return false;
			}
		}

		if(sellcurrency !== currency){
			msg = 'Please select currency to sell';
			document.getElementById('sellfeedback').innerHTML = msg;
			document.getElementById('sellfeedback').className = 'incsizespan_error center';
			return false;
		}
		
		rate = document.getElementById('sell'+cur+'rate').value;
		
		if(isreg === '0' && sms === '1'){		
			output = {
				'amount': amount,
				'account': account,
				'accountname': accountname,
				'alt': altcoin,
				'bank': bankname,	
				'bankname': bankaccountname,
				'banknumber': bankaccount,
				'currency': currency,
				'direction': 'sell',
				'email': email,
				'isnew': isnew,
				'isreg': isreg,
				'method': method,
				'rate': rate,
				'rtype': 'trade',
				'sms': sms,
				'smsno': smsphone,
				'unit': unit			
			};
		}else if(isreg === '0' && sms === '0'){	
			output = {
				'amount': amount,
				'account': account,
				'accountname': accountname,
				'alt': altcoin,
				'bank': bankname,	
				'bankname': bankaccountname,
				'banknumber': bankaccount,
				'currency': currency,
				'direction': 'sell',
				'email': email,
				'isnew': isnew,
				'isreg': isreg,
				'method': method,
				'rate': rate,
				'rtype': 'trade',
				'sms': sms,
				'unit': unit		
			};
		}else{
			output = {
				'amount': amount,
				'account': account,
				'accountname': accountname,
				'alt': altcoin,
				'currency': currency,
				'bank': bankname,	
				'bankname': bankaccountname,
				'banknumber': bankaccount,
				'direction': 'sell',
				'email': email,
				'isnew': isnew,
				'isreg': isreg,
				'method': method,
				'rate': rate,
				'rtype': 'trade',
				'sms': sms,
				'unit': unit		
			};
		}
		onPostSell(output);
	}
	
	const onSubmitBuy = (e) => {
		
		var unit, isreg, sms, amount, cur, altcoin, account, accountname, method, rate, email, acctconfirm, msg, currency, giftcard;
		
		unit = document.getElementById('buycurrencydir').value;
		isreg = document.getElementById('isreg').value;
		amount = document.getElementById('buyamount').value;
		currency = document.getElementById('buycurrency').value;
		email = document.getElementById('buyemail').value;	
		acctconfirm = document.getElementById('buyreg').value;
		accountname = document.getElementById('buyaccountname').value;
		altcoin = '0';
		giftcard = '0';
		account = document.getElementById('buyaccount').value;
		method = document.getElementById('buymethod').value;
		cur = buycurrency.toLowerCase();		
		
		if(document.getElementById('buysms').checked){
			sms = '1';
		}else{
			sms = '0';
		}		
		
		
		if(cryptoaltarr.indexOf(buycurrency) !== -1){
			cur = 'alt';
			altcoin = '1';
		}

		if(giftcardarr.indexOf(buycurrency) !== -1){
			cur = 'gift';
			giftcard = '1';
		}
		
		if(!buycurrency){
			msg = 'Please select a currency to buy';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!method)
		{
			msg = 'Please select a payment method';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!amount)
		{
			msg = 'Please enter amount to buy';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(amount === '')
		{
			msg = 'Please enter amount to buy';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!account && giftcardarr.indexOf(buycurrency) === -1)
		{
			msg = 'Please enter your '+buycurrency+' account';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!email)
		{
			msg = 'Please enter email or phone';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(email && buyemail === '0')
		{
			msg = 'Please reenter email or phone';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!email && buyemail === '1')
		{
			msg = 'Please reenter your bank account info';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(acctconfirm === '0')
		{
			
		}
		
		var confirmacct = '0';
		if(document.getElementById('buyacctconfirm') && !document.getElementById('buyacctconfirm').checked){
			msg = 'Check Box if PM Account is Correct';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}else if(document.getElementById('buyacctconfirm') && document.getElementById('buyacctconfirm').checked){
			confirmacct = '1';			
		}
		
		
		if(buycurrency !== currency){
			msg = 'Please select currency to buy';
			document.getElementById('buyfeedback').innerHTML = msg;
			document.getElementById('buyfeedback').className = 'incsizespan_error center';
			return false;
		}
		
		rate = document.getElementById('buy'+cur+'rate').value;
		
		if(giftcardarr.indexOf(buycurrency) === -1 && confirmacct === '1'){
			output = {
				'amount': amount,
				'account': account,
				'accountname': accountname,
				'alt': altcoin,
				'gift': giftcard,
				'currency': currency,
				'confirm': '1',
				'direction': 'buy',
				'email': email,
				'isreg': isreg,
				'method': method,
				'rate': rate,
				'rtype': 'trade',
				'sms': sms,
				'unit': unit				
			};
		}else if(giftcardarr.indexOf(buycurrency) === -1){
			output = {
				'amount': amount,
				'account': account,
				'accountname': accountname,
				'alt': altcoin,
				'gift': giftcard,
				'currency': currency,
				'direction': 'buy',
				'email': email,
				'isreg': isreg,
				'method': method,
				'rate': rate,
				'rtype': 'trade',
				'sms': sms,
				'unit': unit				
			};
		}else{
			output = {
				'amount': amount,
				'accountname': accountname,
				'alt': altcoin,
				'gift': giftcard,
				'currency': currency,
				'direction': 'buy',
				'email': email,
				'isreg': isreg,
				'method': method,
				'rate': rate,
				'rtype': 'trade',
				'sms': sms,
				'unit': unit
			};
		}		
		onPostBuy(output);
	}
	
	const onSubmitDeposit = (e) => {
		var unit, isreg, sms, amount, cur, altcoin, account, accountname, method, rate, email, msg, currency;
		
		unit = document.getElementById('depositcurrencydir').value;
		isreg = document.getElementById('isreg').value;
		amount = document.getElementById('depositamount').value;
		currency = document.getElementById('depositcurrency').value;
		email = document.getElementById('depositemail').value;	
		accountname = document.getElementById('depositaccountname').value;
		altcoin = '0';
		account = '';
		method = document.getElementById('depositcurrency').value;
		cur = depositcurrency.toLowerCase();
		document.getElementById('depositfeedback').innerHTML = '';
		document.getElementById('depositfeedback').className = 'incsizespan center';
		
		if(document.getElementById('depositsms').checked){
			sms = '1';
		}else{
			sms = '0';
		}
		
		
		if(cryptoaltarr.indexOf(depositcurrency) !== -1){
			cur = 'alt';
			altcoin = '1';
		}		
		
		if(!depositcurrency){
			msg = 'Please select a currency to deposit';
			document.getElementById('depositfeedback').innerHTML = msg;
			document.getElementById('depositfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!amount)
		{
			msg = 'Please enter amount to deposit';
			document.getElementById('depositfeedback').innerHTML = msg;
			document.getElementById('depositfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(amount === '')
		{
			msg = 'Please enter amount to deposit';
			document.getElementById('depositfeedback').innerHTML = msg;
			document.getElementById('depositfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!email)
		{
			msg = 'Please enter email or phone';
			document.getElementById('depositfeedback').innerHTML = msg;
			document.getElementById('depositfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(email && depositemail === '0')
		{
			msg = 'Please reenter email or phone';
			document.getElementById('depositfeedback').innerHTML = msg;
			document.getElementById('depositfeedback').className = 'incsizespan_error center';
			return false;
		}
		else if(!email && depositemail === '1')
		{
			msg = 'Please reenter your bank account info';
			document.getElementById('depositfeedback').innerHTML = msg;
			document.getElementById('depositfeedback').className = 'incsizespan_error center';
			return false;
		}

		if(depositcurrency !== currency){
			msg = 'Please select currency to deposit';
			document.getElementById('depositfeedback').innerHTML = msg;
			document.getElementById('depositfeedback').className = 'incsizespan_error center';
			return false;
		}
		
		rate = document.getElementById('deposit'+cur+'rate').value;
		
		
		if(giftcardarr.indexOf(depositcurrency) === -1){
			output = {
				'amount': amount,
				'accountname': accountname,
				'alt': altcoin,
				'currency': currency,
				'direction': 'deposit',
				'email': email,
				'isreg': isreg,
				'method': method,
				'rate': rate,
				'rtype': 'trade',
				'sms': sms,
				'unit': unit
			};
		}else{
			output = {
				'amount': amount,
				'account': account,
				'accountname': accountname,
				'alt': altcoin,
				'currency': currency,
				'direction': 'deposit',
				'email': email,
				'isreg': isreg,
				'method': method,
				'rate': rate,
				'rtype': 'trade',
				'sms': sms,
				'unit': unit			
			};
		}
		onPostDeposit(output);
	}
	
	
	function isNumeric(str) 
	{
		return !isNaN(str - parseFloat(str));
	}
	
	function Round(a,v)
	{
		v = parseFloat(v);
		return v.toFixed(a);
	}
	
	function Rounder(v) 
	{
		return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	
	function validateEmail(str) 
	{
		var lastAtPos = str.lastIndexOf('@');
		var lastDotPos = str.lastIndexOf('.');
		return (lastAtPos < lastDotPos && lastAtPos > 0 && str.indexOf('@@') === -1 && lastDotPos > 2 && (str.length - lastDotPos) > 2);
	}
	
	function ScanQR()
	{
		window.LaunchQR();		
	}
	
	function SkyCalculate(fro, to, amount, display)
	{
		var type = amount.slice(0, -6).toLowerCase();
		var selectunit = document.getElementById(type+'currencydir').value;
		var selectcurr, selectcur, selectmtd, selectamount, summarydisplay, ccfee, bdfee, rate, addedrate, usdamount, ngnamount, btcamount, altamount, usdalt, ngnamountfee, pmbalance, usdbtc, convrate, diff, limit, ongnamount, totwithdrawal, withfee, bankname, bankacct, preto;	
		
		if(type === 'buy'){	
			selectcurr = document.getElementById(type+'currency').value;
			selectcur = selectcurr.toLowerCase();
			selectmtd = (document.getElementById(type+'method').value).toLowerCase();
			selectamount = document.getElementById(type+'amount').value;
			usdamount = parseFloat(document.getElementById(amount).value);
			ccfee = 0;
			bdfee = 0;
			summarydisplay = '';
			
			if(selectamount === '' || selectcur === '' || selectmtd === ''){				
				document.getElementById(display).style.display = 'none';
				return false;
			}		
			
			if(giftcardarr.indexOf(selectcurr) !== -1)
			{
				to = 'gift';
			}
			
			if(cryptoaltarr.indexOf(selectcurr) !== -1)
			{
				preto = to;
				to = 'alt';
			}
			
			rate = parseFloat(document.getElementById(type+to+'rate').value);
			
			//console.log(type+to+'rate'+selectcur);
			
			if(selectmtd === 'cc'){
				addedrate = parseFloat(document.getElementById('addedrate').value);
				if(addedrate > 0){
					rate = rate + addedrate;
				}				
				ccfee = parseFloat(document.getElementById(type+'fee').value);
			}
			if(selectmtd === 'bt'){
				bdfee = parseFloat(document.getElementById(type+'bankfee').value);
			}
			
			if(usdamount !== '' && !isNaN(usdamount) && selectcur !== '' && selectmtd !== '' && selectunit === 'USD')
			{		
				ngnamount = (usdamount * rate);
				ngnamount = Round(2,ngnamount);
				document.getElementById('buyamt').value = ngnamount;
				ngnamount = Rounder(ngnamount); 
				
			
				if(selectcur === 'btc'){
					usdbtc = parseFloat(document.getElementById('usdbtc').value);
					btcamount = usdamount * usdbtc;
					btcamount = Round(8,btcamount);
					if(isNaN(btcamount)){
						summarydisplay += '<b>Please reselect currency</b><br/>';
					}else{
						summarydisplay += '<b>~ '+btcamount+' '+selectcur.toUpperCase()+'</b><br/>';
					}				
				}else if(selectcur !== 'pm' && selectcur !== 'usdt' && giftcardarr.indexOf(selectcurr) === -1){
					usdalt = parseFloat(document.getElementById('usd'+preto).value);
					altamount = usdamount * usdalt;
					altamount = Round(8,altamount);				
					if(isNaN(altamount)){
						summarydisplay += '<b>Please reselect currency</b><br/>';
					}else{
						summarydisplay += '<b>~ '+altamount+' '+selectcur.toUpperCase()+'</b><br/>';
					}	
				}
				
				summarydisplay += 'Payment Due <b>&#8358;'+ngnamount+'</b>';
				
				ngnamountfee = usdamount * rate;			
				if(ccfee > 0){
					summarydisplay += '<br/>Gateway Fee <b>&#8358;'+ccfee+'</b>';
					ngnamountfee = ngnamountfee + ccfee;
				}
				if(bdfee > 0){
					summarydisplay += '<br/>Bank Fee <b>&#8358;'+bdfee+'</b>';
					ngnamountfee = ngnamountfee + bdfee;
				}
				
				ngnamountfee = Round(2,ngnamountfee);
				ngnamountfee = Rounder(ngnamountfee);
				summarydisplay += '<br/>Pay Total <b>&#8358;'+ngnamountfee+'</b>';
				document.getElementById(display).innerHTML = summarydisplay;
				document.getElementById(display).style.display = 'block';
				
				if(selectcur === 'pm'){
					lookUp('buyaccount', 'pm');
				}else{
					document.getElementById('buyaccountsummary').style.display = 'none';
				}
			}
			else if(usdamount !== '' && !isNaN(usdamount) && selectcur !== '' && selectmtd !== '' && selectunit !== 'USD')
			{
				if(selectcur === 'btc'){
					btcamount = usdamount;
					usdbtc = parseFloat(document.getElementById('usdbtc').value);
					usdamount = btcamount / usdbtc;
					usdamount = Round(2,usdamount);
					if(isNaN(usdamount)){
						summarydisplay += '<b>Please reselect currency</b><br/>';
					}else{
						summarydisplay += '<b>~ $'+Rounder(usdamount)+'</b><br/>';
					}				
				}else if(selectcur !== 'pm' && selectcur !== 'usdt' && giftcardarr.indexOf(selectcurr) === -1){
					altamount = usdamount;
					usdalt = parseFloat(document.getElementById('usd'+preto).value);
					usdamount = altamount / usdalt;
					usdamount = Round(2,usdamount);		
					if(isNaN(usdamount)){
						summarydisplay += '<b>Please reselect currency</b><br/>';
					}else{
						summarydisplay += '<b>~ $'+Rounder(usdamount)+'</b><br/>';
					}	
				}
				
				ngnamount = (usdamount * rate);
				ngnamount = Round(2,ngnamount);
				document.getElementById('buyamt').value = ngnamount;
				ngnamount = Rounder(ngnamount); 
				
				summarydisplay += 'Payment Due <b>&#8358;'+ngnamount+'</b>';				
				ngnamountfee = usdamount * rate;			
				if(ccfee > 0){
					summarydisplay += '<br/>Gateway Fee <b>&#8358;'+ccfee+'</b>';
					ngnamountfee = ngnamountfee + ccfee;
				}
				if(bdfee > 0){
					summarydisplay += '<br/>Bank Fee <b>&#8358;'+bdfee+'</b>';
					ngnamountfee = ngnamountfee + bdfee;
				}
				
				ngnamountfee = Round(2,ngnamountfee);
				ngnamountfee = Rounder(ngnamountfee);
				summarydisplay += '<br/>Pay Total <b>&#8358;'+ngnamountfee+'</b>';
				document.getElementById(display).innerHTML = summarydisplay;				
				document.getElementById(display).style.display = 'block';
				if(selectcur === 'pm'){
					lookUp('buyaccount', 'pm');
				}else{
					document.getElementById('buyaccountsummary').style.display = 'none';
				}
			}
			else
			{
				document.getElementById(display).style.display = 'none';
			}
		}
		
		if(type === 'sell'){
			selectcur = (document.getElementById(type+'currency').value).toLowerCase();
			usdamount = parseFloat(document.getElementById(amount).value);
			summarydisplay = '';
			
			if(fro !== 'pm' && fro !== 'btc'){
				fro = 'alt';
			}
			
			rate = parseFloat(document.getElementById(type+fro+'rate').value);

			if(usdamount !== '' && !isNaN(usdamount) && selectcur !== '' && selectunit === 'USD')
			{
		
				if(selectcur === 'btc'){
					usdbtc = parseFloat(document.getElementById('usdbtc').value);
					btcamount = usdamount * usdbtc;
					btcamount = Round(8,btcamount);
				}
				else if(selectcur === 'usdt')
				{
					usdbtc = parseFloat(document.getElementById('usd'+selectcur).value);
					btcamount = usdamount * usdbtc;
					btcamount = Round(2,btcamount);
				}
				else if(selectcur !== 'btc' && selectcur !== 'pm')
				{
					usdbtc = parseFloat(document.getElementById('usd'+selectcur).value);
					btcamount = usdamount * usdbtc;
					btcamount = Round(8,btcamount);
				}
					
				ngnamount = (usdamount * rate);
				ngnamount = Round(2,ngnamount);
				document.getElementById('sellamt').value = ngnamount;
				ngnamount = Rounder(ngnamount);				
				
				if(selectcur === 'pm'){
					pmbalance = parseFloat(usdamount + (usdamount * 0.0199)).toFixed(2);
					summarydisplay += '<b>$'+Rounder(pmbalance)+'</b> PM Balance is Required<br/>';								
				}else{
					summarydisplay += '<b>~ '+btcamount+' '+selectcur.toUpperCase()+'</b><br/>';
					if(isNaN(btcamount)){
						document.getElementById(display).innerHTML = "";
						document.getElementById(display).style.display = 'none';
						return false;
					}
				}
				
				summarydisplay += 'Receive Total <b>&#8358;'+ngnamount+'</b>';
				document.getElementById(display).innerHTML = summarydisplay;
				document.getElementById(display).style.display = 'block';
			}
			else if(usdamount !== '' && !isNaN(usdamount) && selectcur !== '' && selectunit !== 'USD')
			{	
				if(selectcur === 'btc'){
					usdbtc = parseFloat(document.getElementById('usdbtc').value);
					btcamount = usdamount;
					usdamount = btcamount / usdbtc;
					usdamount = Round(2,usdamount);
				}
				else if(selectcur === 'usdt')
				{
					usdbtc = parseFloat(document.getElementById('usd'+selectcur).value);
					btcamount = usdamount * usdbtc;
					usdamount = btcamount / usdbtc;
					usdamount = Round(2,usdamount);
				}
				else if(selectcur !== 'btc' && selectcur !== 'pm')
				{
					usdbtc = parseFloat(document.getElementById('usd'+selectcur).value);
					btcamount = usdamount;
					usdamount = btcamount / usdbtc;
					usdamount = Round(2,usdamount);
				}
				
				ngnamount = (usdamount * rate);
				ngnamount = Round(2,ngnamount);
				document.getElementById('sellamt').value = ngnamount;
				ngnamount = Rounder(ngnamount); 
				
				if(selectcur === 'pm'){
					pmbalance = parseFloat(usdamount + (usdamount * 0.0199)).toFixed(2);
					summarydisplay += '<b>$'+Rounder(pmbalance)+'</b> PM Balance is Required<br/>';								
				}else{
					summarydisplay += '<b>~ $'+Rounder(usdamount)+'</b><br/>';
					if(isNaN(usdamount)){
						document.getElementById(display).innerHTML = "";
						document.getElementById(display).style.display = 'none';
						return false;
					}
				}
				
				summarydisplay += 'Receive Total <b>&#8358;'+ngnamount+'</b>';
				document.getElementById(display).innerHTML = summarydisplay;
				document.getElementById(display).style.display = 'block';
			}
			else
			{
				document.getElementById(display).style.display = 'none';
			}
		}
		
		if(type === 'deposit'){
			selectcur = (document.getElementById(type+'currency').value).toLowerCase();
			usdamount = parseFloat(document.getElementById(amount).value);
			summarydisplay = '';
			ccfee = 0;
			bdfee = 0;
			
			if(fro !== 'pm' && fro !== 'btc' && fro !== 'btcen' && fro !== 'cc' && fro !== 'bt'){
				fro = 'alt';
			}
			
			
			rate = parseFloat(document.getElementById(type+fro+'rate').value);
			

			if(usdamount !== '' && !isNaN(usdamount) && selectcur !== '' && selectunit === 'USD')
			{
				ngnamount = (usdamount * rate);
				ngnamount = Round(2,ngnamount);
				document.getElementById('depositamt').value = ngnamount;
				ngnamount = Rounder(ngnamount);
				
				if(fro === 'pm'){
					pmbalance = parseFloat(usdamount + (usdamount * 0.0199)).toFixed(2);
					summarydisplay += '<b>$'+pmbalance+'</b> PM Balance is Required<br/>';
				}else if(fro === 'btc'){
					convrate = parseFloat(document.getElementById('btenbtrate').value);
					btcamount = (usdamount * convrate);
					btcamount = Round(8,btcamount);
					if(isNaN(btcamount)){
						return false;
					}
					summarydisplay += '<b>~ '+btcamount+' BTC</b><br/>';
				}else if(fro === 'btcen'){
					convrate = parseFloat(document.getElementById('btenbtrate').value);
					btcamount = (usdamount * convrate);
					btcamount = Round(8,btcamount);
					if(isNaN(btcamount)){
						return false;
					}
					summarydisplay += '<b>~ '+btcamount+' BTC</b><br/><span id="depositnetwork"></span>';
				}else if(fro === 'cc'){
					ccfee = parseFloat(document.getElementById(type+'fee').value);
					if(ccfee > 0){
						summarydisplay += 'Gateway Fee <b>&#8358;'+ccfee+'</b><br/>';
					}			
				}else if(fro === 'bt'){
					
				}else if(fro !== ''){
					convrate = parseFloat(document.getElementById('usd'+selectcur).value);
					btcamount = (usdamount * convrate);
					btcamount = Round(8,btcamount);
					if(isNaN(btcamount)){						
						return false;
					}
					summarydisplay += '<b>~ '+btcamount+' '+selectcur.toUpperCase()+'</b><br/>';
				}
				
				summarydisplay += 'Receive Total <b>&#8358;'+ngnamount+'</b>';
				diff = parseFloat(document.getElementById('depositbtadded').value);			
				
				if(fro === 'cc'){
					if(diff > 0){
						rate = rate + diff;
					}
					ngnamountfee = (usdamount * rate) + ccfee;
					ngnamountfee = Round(2,ngnamountfee);
					document.getElementById('depositamt').value = ngnamountfee;
					ngnamountfee = Rounder(ngnamountfee);
					summarydisplay += '<br/>Pay Total <b>&#8358;'+ngnamountfee+'</b>';
				}
				if(fro === 'bt'){
					bdfee = parseFloat(document.getElementById(type+'bankfee').value);
					if(bdfee > 0){
						summarydisplay += '<br/>Bank Fee <b>&#8358;'+bdfee+'</b>';
					}
					ngnamountfee = (usdamount * rate) + bdfee;
					ngnamountfee = Round(2,ngnamountfee);
					ngnamountfee = Rounder(ngnamountfee);
					summarydisplay += '<br/>Pay Total <b>&#8358;'+ngnamountfee+'</b>';
				}
				
				document.getElementById(display).innerHTML = summarydisplay;
				document.getElementById(display).style.display = 'block';
				
				if(fro === 'btcen'){
					//NetworkFee('deposit');
				}
			}
			else if(usdamount !== '' && !isNaN(usdamount) && selectcur !== '' && selectunit !== 'USD')
			{			
				
				if(fro === 'pm'){
					pmbalance = parseFloat(usdamount + (usdamount * 0.0199)).toFixed(2);
					summarydisplay += '<b>$'+pmbalance+'</b> PM Balance is Required<br/>';
				}else if(fro === 'btc'){
					btcamount = usdamount;
					convrate = parseFloat(document.getElementById('btenbtrate').value);
					usdamount = (btcamount / convrate);
					usdamount = Round(2,usdamount);
					if(isNaN(usdamount)){
						return false;
					}
					summarydisplay += '<b>~ $'+Rounder(usdamount)+'</b><br/>';
				}else if(fro === 'btcen'){
					btcamount = usdamount;
					convrate = parseFloat(document.getElementById('btenbtrate').value);
					usdamount = (btcamount / convrate);
					usdamount = Round(2,usdamount);
					if(isNaN(usdamount)){
						return false;
					}
					summarydisplay += '<b>~ $'+Rounder(usdamount)+'</b><br/><span id="depositnetwork"></span>';
				}else if(fro === 'cc'){
					ccfee = parseFloat(document.getElementById(type+'fee').value);
					if(ccfee > 0){
						summarydisplay += 'Gateway Fee <b>&#8358;'+ccfee+'</b><br/>';
					}			
				}else if(fro === 'bt'){
					
				}else if(fro !== ''){
					btcamount = usdamount;
					convrate = parseFloat(document.getElementById('usd'+selectcur).value);
					usdamount = (btcamount / convrate);
					usdamount = Round(2,usdamount);
					if(isNaN(usdamount)){
						return false;
					}
					summarydisplay += '<b>~ $'+Rounder(usdamount)+'</b><br/><span id="depositnetwork"></span>';				
				}
				
				ngnamount = (usdamount * rate);
				ngnamount = Round(2,ngnamount);
				document.getElementById('depositamt').value = ngnamount;
				ngnamount = Rounder(ngnamount);
				
				summarydisplay += 'Receive Total <b>&#8358;'+ngnamount+'</b>';
				diff = parseFloat(document.getElementById('depositbtadded').value);
				
				if(isNaN(usdamount)){
					return false;
				}
				
				
				if(fro === 'cc'){
					if(diff > 0){
						rate = rate + diff;
					}
					ngnamountfee = (usdamount * rate) + ccfee;
					ngnamountfee = Round(2,ngnamountfee);
					document.getElementById('depositamt').value = ngnamountfee;
					ngnamountfee = Rounder(ngnamountfee);
					summarydisplay += '<br/>Pay Total <b>&#8358;'+ngnamountfee+'</b>';
				}
				if(fro === 'bt'){
					bdfee = parseFloat(document.getElementById(type+'bankfee').value);
					if(bdfee > 0){
						summarydisplay += '<br/>Bank Fee <b>&#8358;'+bdfee+'</b>';
					}
					
					ngnamountfee = (usdamount * rate) + bdfee;
					ngnamountfee = Round(2,ngnamountfee);
					ngnamountfee = Rounder(ngnamountfee);
					summarydisplay += '<br/>Pay Total <b>&#8358;'+ngnamountfee+'</b>';
				}
				document.getElementById(display).innerHTML = summarydisplay;
				document.getElementById(display).style.display = 'block';
				
				if(fro === 'btcen'){
					//NetworkFee('deposit');
				}
			}
			else
			{
				document.getElementById(display).style.display = 'none';
			}
		}
		
		if(type === 'sendbtc'){
			usdamount = parseFloat(document.getElementById(amount).value);
			summarydisplay = '';
			ccfee = 0;
			
			rate = parseFloat(document.getElementById(fro+to+'rate').value);
			if(usdamount !== '' && selectunit === 'USD'){
				ngnamount = (usdamount * rate);
				ngnamount = Round(8,ngnamount);
				document.getElementById('sendbtcamt').value = ngnamount;
				summarydisplay += '<b>~ '+ngnamount+' BTC</b><br/><span id="sendbtcnetwork"></span>';			
				document.getElementById(display).innerHTML = summarydisplay;
				document.getElementById(display).style.display = 'block';
				//NetworkFee('sendbtc');
			}else if(usdamount !== '' && selectunit !== 'USD'){
				btcamount = usdamount;
				usdamount = (btcamount / rate);
				usdamount = Round(2,usdamount);
				usdamount = Rounder(usdamount);
				
				summarydisplay += '<b>~ $'+usdamount+'</b><br/><span id="sendbtcnetwork"></span>';			
				document.getElementById(display).innerHTML = summarydisplay;
				document.getElementById(display).style.display = 'block';
				//NetworkFee('sendbtc');
			}else{
				document.getElementById(display).style.display = 'none';
			}
		}
		
		if(type === 'withdraw'){
			usdamount = parseFloat(document.getElementById(amount).value);
			bankname = document.getElementById(type+'bank').value;
			bankacct = document.getElementById(type+'bankaccount').value;
			summarydisplay = '';
			ccfee = 0;
			
			rate = parseFloat(document.getElementById(fro+to+'rate').value);
			limit = parseFloat(document.getElementById(type+'limitfee').value);
			if(usdamount !== ''){
				ngnamount = (usdamount * rate);	
				ongnamount = ngnamount;	
				ngnamount = Round(2,ngnamount);
				document.getElementById('withdrawamt').value = ngnamount;			
				
				ngnamount = Rounder(ngnamount); 
				summarydisplay += 'Receive Total <b>&#8358;'+ngnamount+'</b><br/>';
				
				if(ongnamount < limit){
					ccfee = parseFloat(document.getElementById(type+'fee').value);
					if(ccfee > 0){
						totwithdrawal = ongnamount + ccfee;
						totwithdrawal = Round(2, totwithdrawal);
						document.getElementById('withdrawamt').value = totwithdrawal;	
						withfee = Round(2, ccfee);				
						withfee = Rounder(withfee); 
						summarydisplay += 'Withdrawal Fee <b>&#8358;'+withfee+'</b><br/>';
					}
				}		
				
				if(!bankacct){
					summarydisplay += '<a href="settings">Add Bank Details</a>';
				}else{
					summarydisplay += 'Bank Account <b>'+bankacct+'</b><br/>';
					summarydisplay += 'Bank Name <b>'+bankname+'</b>';
				}
				
				
				document.getElementById(display).innerHTML = summarydisplay;
				document.getElementById(display).style.display = 'block';
			}else{
				document.getElementById(display).style.display = 'none';
			}
		}
			
	}
		
	function lookUp(field, type)
	{
		
		var val, look, t, currency, acctresolved, acctname, regresolved;
		
		val = document.getElementById(field).value;
		val = val.trim();
		look = localStorage.getItem(field+"lookUp");	
		
		if(type === 'pm'){
			//display other account field	
			val = val.toUpperCase();
			t = field.slice(0, -7);
			
			currency = document.getElementById('buycurrency').value.toLowerCase();		
			acctresolved = t+'acct';
			acctname = t+'accountname';
			
			if(currency === 'pm'){
				document.getElementById(acctresolved).value = '0';
				document.getElementById(acctname).value = '';
			}
			if(look === val){
				return false;
			}
			
			document.getElementById(field+'summary').className = 'incsizespan center'; 
			if(val.length > 7 && val.length <= 9 && currency === 'pm'){
				document.getElementById(field+'summary').innerHTML = 'Validating Account...';
				document.getElementById(field+'summary').style.display = 'block';
			}else if(val.length >= 10 && currency === 'pm'){
				document.getElementById(field+'summary').innerHTML = 'Validating Account...';
				document.getElementById(field+'summary').style.display = 'block';
				document.getElementById(field+'summary').className = 'incsizespan incsizespan_error';
				document.getElementById(field+'summary').innerHTML = '<b>'+val+'</b> is an Invalid Perfect Money Account';
				document.getElementById(field+'summary').style.display = 'block';
				return false;
			}else{
				document.getElementById(field+'summary').innerHTML = '';
				document.getElementById(field+'summary').style.display = 'none';
				localStorage.setItem(field+"lookUp", "");
				return false;
			}
			localStorage.setItem(field+"lookUp", val);
		}
		
		if(type === 'cc'){
			
			t = field.slice(0, -5);
			regresolved = t+'reg';
			document.getElementById(regresolved).value = '0';
			document.getElementById(t+'emailsummary').className = 'incsizespan center';
			if(t === 'sell'){
				document.getElementById('sellbanksummary').className = 'incsizespan center';
			}
			
			if(look === val){
				return false;
			}
			
			if(validateEmail(val)){
				document.getElementById(t+'emailsummary').innerHTML = 'Validating email...';
				document.getElementById(t+'emailsummary').style.display = 'block';
			}else if(isNumeric(val) && val.length >= 11 && val.length <= 13){
				document.getElementById(t+'emailsummary').innerHTML = 'Validating phone...';
				document.getElementById(t+'emailsummary').style.display = 'block';
			}else{
				document.getElementById(t+'emailsummary').innerHTML = '';
				document.getElementById(t+'emailsummary').style.display = 'none';
				localStorage.setItem(field+"lookUp", "");
				return false;
			}
			localStorage.setItem(field+"lookUp", val);
		}
		
		output = {
			type: type,
			search: val,
			product: t,
			field: field,
			acctname: acctname,
			acctresolved: acctresolved,
			regresolved: regresolved
		};
		nameLookUp(output);		
	}
	
	return [
		(<div className="container__canvas" style={rectStyleWrap} key="tv1">
			<div className="Context">
				<div className="Context__header">					
					<svg width="264" height="40" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
					 <g className="layer">
					  <g fill="#000000" id="svg_1" transform="translate(0, 160) scale(0.1, -0.1)">
					   <path d="m132.01636,1565.2004c-38.64033,-21.39013 -50.23244,-29.77841 -69.0006,-49.49088c-10.48809,-10.90477 -18.76816,-20.97071 -18.76816,-23.06778c0,-1.67766 -3.86403,-8.38828 -8.83208,-15.51833c-21.52818,-31.45607 -18.21616,-100.23999 6.62405,-140.08434c18.76816,-30.61723 73.41664,-71.30041 115.36899,-86.39932c29.25625,-10.48536 92.7368,-20.55129 99.36085,-15.51833c1.10402,1.25825 -7.17606,6.71063 -19.32017,13.00184c-26.49623,13.42125 -44.16039,31.45607 -68.44859,69.62276c-25.39222,40.68318 -33.12029,76.33338 -29.25625,137.98727c2.76002,44.4579 10.48809,75.07514 25.94423,106.11179c8.83208,16.77656 -6.07205,18.45422 -33.67229,3.35532l0,0l0,0.00001l0.00001,-0.00001l0.00001,0z" id="svg_3"/>
					   <path d="m285.47368,1552.19856c-13.80012,-10.48536 -33.12029,-30.61723 -43.05637,-44.87732c-15.45613,-22.22895 -18.76816,-31.03665 -20.42418,-62.49271c-2.20802,-30.19782 -1.10402,-37.32787 6.07205,-39.42493c12.69611,-3.35532 94.39281,33.97255 126.96109,58.71799c16.00814,11.7436 33.67229,28.52016 39.19233,36.90845l11.04009,15.51833l-29.80826,20.97071c-16.56014,11.7436 -34.2243,22.64836 -39.74434,23.90661c-5.52005,1.25825 -9.93609,4.19414 -9.93609,6.29121c0,8.8077 -17.11215,2.51648 -40.29635,-15.51833l0,0l0,-0.00001l0.00002,-0.00001l0.00001,0.00001z" id="svg_4"/>
					   <path d="m439.48302,1467.89629c-11.5921,-26.42309 -105.43291,-81.78577 -163.94541,-96.04585c-14.90412,-3.77473 -28.70425,-8.38828 -30.36026,-10.48536c-1.65602,-2.09707 6.62405,-17.19598 18.21616,-33.97255c21.52818,-31.45607 73.41664,-74.65572 89.42478,-74.65572c4.96804,0 21.52818,8.38828 36.43232,18.45422c64.03255,42.36083 91.08078,119.11363 64.03255,182.8646c-8.28007,19.71247 -9.93609,21.80954 -13.80012,13.84067l0,0l-0.00001,0l-0.00001,-0.00001z" id="svg_5"/>
					  </g>
					  <text fill="#000000" fontFamily="Montserrat" fontSize="49" fontWeight="bold" id="svg_2" stroke="#000000" strokeWidth="0" textAnchor="end" transform="matrix(0.707299, 0, 0, 0.499256, 68.31151, 16.6683)" x="250.82191" y="23.05614">ENDOPAY</text>
					 </g>
					</svg>
				</div>
				<p className="Context__copy" style={{textAlign: 'center'}}><Link to="/signin" style={lineTwo}>SIGN IN</Link> <b>|</b> <Link to="/signup" style={lineTwo}>CREATE ACCOUNT</Link></p>
				<p className="Context__copy" style={{textAlign: 'center'}}><Link to="/rates" style={lineTwo}><span className="Rainbow">CHECK RATES HERE</span></Link></p>
			</div>
		</div>),
		(<div className="container__controls" key="tv2">			
			<div className="container__control--switch">
				<img src={Justsell} alt=""/>
				<TradeSwitch />
			</div>
			<div className="stack disshow" id="selldisplay">
				<div style={center}>
					<h6 style={lineOne} >SELL TO ENDOPAY</h6>
					<b><Link to="/" id="HOWTOSELL" style={lineTwo}>CLICK HERE TO SEE HOW TO SELL</Link></b><br/>
					<h4 style={lineThree}>Rate: <span id="sellratedisplay" style={redColor} ></span> | Min: <span id="minselldisplay" style={redColor} ></span> | Max: <span id="maxselldisplay" style={redColor} ></span></h4>
				</div>				
				<>
					<label id="sellcurrencydirswitch" style={{left:'calc(50% - 40px)'}}>
					<span className="switch__text"></span>
					<input className="switch__checkbox" type="checkbox" name="sellcurrencydir" id="sellcurrencydir" value={sellcurrencydir} onChange={handleSwitchChange} tabIndex="1" />
					<span className="switch__elements" aria-hidden="true">
						<span className="switch__inner">
							<span className="switch__left" id="sellcurrencydirdisplay"></span>
							<span className="switch__right">USD</span>
							<span className="switch__middle"></span>
						</span>
					</span>
					</label>
				</>
				
				<div className="container__control--select">
					<select className="select-input" name="sellcurrency" id="sellcurrency" value={sellcurrency} onChange={(e) => setSellCurrency(e.target.value)}>
						<option value="">Select Currency</option>
						<option value="BTC">BTC - Bitcoin</option>
						<option value="USDT">USDT - Tether (TRC20)</option>
						<option value="PM">PM - Perfect Money</option>
						<option value="BCH">BCH - Bitcoin Cash</option>
						<option value="ETH">ETH - Ethereum</option>
						<option value="LTC">LTC - Litecoin</option>
					</select>
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="text" name="sellamount" id="sellamount" placeholder="Amount In USD" value={sellamount} onChange={(e) => setSellAmount(e.target.value)} onKeyUp={handleKeyUp}/>
				</div>
				
				<div className="incsizespan center" id="sellsummary">
					
				</div>	

				<div className="container__control--input">
					<input className="text-input" type="email" name="sellemail" id="sellemail" placeholder="Email Or Phone" value={sellemail} onChange={(e) => setSellEmail(e.target.value)}/>
				</div>	
				
				<div className="incsizespan center" id="sellemailsummary">
					
				</div>
				
				<div className="container__control--input hide" id="sellbankinfo1">
					<input className="text-input" type="text" id="sellbankaccount" placeholder="Bank Account Number" onKeyUp={handleFetchBank} />
					<input type="hidden" id="sellbankaccountname" value=""/>
				</div>
				<div className="container__control--select hide" id="sellbankinfo2">				
					<select
						className="select-input" 
						name="sellbankname" 
						id="sellbankname" 
						onChange={handleFetchBank}
					>
						{bankOptions.map((group) => (
							<optgroup key={group?.label} label={group?.label}>
								{group?.options.map((option) => (
									<option key={option.value} value={option.value}>
										{option?.label}
									</option>
								))}
							</optgroup>
						))}
					</select>
				</div>
				
				<div className="incsizespan center hide" id="sellbanksummary">
					
				</div>
				
				<div className="container__control--input hide" id="sellbankinfo3">
					<input className="text-input" type="text" id="sellemailcode" placeholder="Email Code" onKeyUp={handleKeyUp}/>
				</div>	
				
				<div className="incsizespan hide" id="sellsmswrap">
					<label htmlFor="sellsms"><input type="checkbox" id="sellsms" onClick={onPhoneSMS} /> Receive SMS on Completion <b>₦10</b></label>
				</div>
				
				<div className="container__control--input hide" id="sellsmsphonewrap">
					<input className="text-input" type="text" id="sellsmsphone" placeholder="Phone Number" maxLength="14" onKeyUp={handlePhoneSMS}/>
				</div>
				
				<div className="incsizespan center" id="sellfeedback">
					
				</div>
				
				<div className="container__buttons">
					<button className="clipboard" id="sellproceed" onClick={onSubmitSell}>PROCEED</button>
				</div>
			</div>
			
			<div className="stack animatehide" id="buydisplay">
				<div style={center}>
					<h6 style={lineOne} >BUY FROM ENDOPAY</h6>
					<b><Link to="/" id="HOWTOBUY" style={lineTwo}>CLICK HERE TO SEE HOW TO BUY</Link></b><br/>
					<h4 style={lineThree}>Rate: <span id="buyratedisplay" style={redColor} ></span> | Min: <span id="minbuydisplay" style={redColor} ></span> | Max: <span id="maxbuydisplay" style={redColor} ></span></h4>
				</div>
				
				<>
					<label id="buycurrencydirswitch" style={{left:'calc(50% - 40px)'}}>
					<span className="switch__text"></span>
					<input className="switch__checkbox" type="checkbox" name="buycurrencydir" id="buycurrencydir" value={buycurrencydir} onChange={handleSwitchChange} tabIndex="1" />
					<span className="switch__elements" aria-hidden="true">
						<span className="switch__inner">
							<span className="switch__left" id="buycurrencydirdisplay"></span>
							<span className="switch__right">USD</span>
							<span className="switch__middle"></span>
						</span>
					</span>
					</label>
				</>
				
				<div key="dash178eth" className="incsizespan center" id="buyeth">
					
				</div>
				
				<div className="container__control--select">
					<select className="select-input" name="buycurrency" id="buycurrency" value={buycurrency} onChange={(e) => setBuyCurrency(e.target.value)} >
						<option value="">Select Currency</option>
						<option value="BTC">BTC - Bitcoin</option>
						<option value="USDT">USDT - Tether (TRC20)</option>
						<option value="PM">PM - Perfect Money</option>
						<option value="BCH">BCH - Bitcoin Cash</option>
						<option value="ETH">ETH - Ethereum</option>
						<option value="LTC">LTC - Litecoin</option>
						<option value="GP">GP - Google Play Card</option>
						<option value="IT">IT - ITunes Gift Card</option>
					</select>
				</div>
				
				<div className="container__control--select">
					<select className="select-input" name="buymethod" id="buymethod" value={buymethod} onChange={(e) => setBuyMethod(e.target.value)}>
						<option value="">Select Payment Method</option>
						<option value="CC">Debit Card</option>
						<option value="BT">Bank Transfer</option>
						<option value="BT">Cash Deposit</option>
						<option value="EN">Endopay Account</option>
					</select>
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="text" name="buyamount" id="buyamount" placeholder="Amount In USD" value={buyamount} onChange={(e) => setBuyAmount(e.target.value)} onKeyUp={handleKeyUp} />
				</div>
				
				<div className="incsizespan center" id="buysummary">
					
				</div>
				
				<div className="container__control--input scan fixpost">
					<input className="text-input" type="text" name="buyaccount" id="buyaccount" placeholder="Receive Account" onKeyUp={handleKeyUp} />
					<span id="buyaccountcamera" className="camera icon-camera" onMouseDown={ScanQR}></span>
				</div>				
					
				<div className="incsizespan center" id="buyaccountsummary">
					
				</div>	

				<div className="container__control--input">
					<input className="text-input" type="email" name="buyemail" id="buyemail" placeholder="Email Or Phone" value={buyemail} onChange={(e) => setBuyEmail(e.target.value)} />
				</div>	
				
				<div className="incsizespan center" id="buyemailsummary">
					
				</div>	

				<div className="incsizespansms">
					<label htmlFor="buysms"><input type="checkbox" id="buysms" /> Receive SMS on Completion <b>₦10</b></label>
				</div>
				
				<div className="incsizespan center" id="buyfeedback">
					
				</div>
				
				<div className="container__buttons">
					<button className="clipboard" id="buyproceed" onClick={onSubmitBuy}>PROCEED</button>
				</div>
			</div>
			
			
			
			<div className="stack animatehide" id="depositdisplay">
				<div style={center}>
					<h6 style={lineOne} >FUND ENDOPAY ACCOUNT</h6>
					<b><Link to="/" id="HOWTODEPOSIT" style={lineTwo}>CLICK HERE TO SEE HOW TO DEPOSIT</Link></b><br/>
					<h4 style={lineThree}>Rate: <span id="depositratedisplay" style={redColor} ></span> | Min: <span id="mindepositdisplay" style={redColor} ></span> | Max: <span id="maxdepositdisplay" style={redColor} ></span></h4>			
				</div>
				
				<>
					<label id="depositcurrencydirswitch" style={{left:'calc(50% - 40px)'}}>
					<span className="switch__text"></span>
					<input className="switch__checkbox" type="checkbox" name="depositcurrencydir" id="depositcurrencydir" value={depositcurrencydir} onChange={handleSwitchChange} tabIndex="1" />
					<span className="switch__elements" aria-hidden="true">
						<span className="switch__inner">
							<span className="switch__left" id="depositcurrencydirdisplay"></span>
							<span className="switch__right">USD</span>
							<span className="switch__middle"></span>
						</span>
					</span>
					</label>
				</>				
				
				<div className="container__control--select">
					<select className="select-input" name="depositcurrency" id="depositcurrency" value={depositcurrency} onChange={(e) => setDepositCurrency(e.target.value)}>
						<option value="">Select Currency</option>
						<option value="CC">Debit Card</option>
						<option value="BT">Bank Transfer</option>
						<option value="BTC">BTC - Bitcoin</option>
						<option value="USDT">USDT - Tether (TRC20)</option>
						<option value="PM">PM - Perfect Money</option>
						<option value="BCH">BCH - Bitcoin Cash</option>
						<option value="ETH">ETH - Ethereum</option>
						<option value="LTC">LTC - Litecoin</option>
					</select>
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="text" name="depositamount" id="depositamount" placeholder="Amount In USD" value={depositamount} onChange={(e) => setDepositAmount(e.target.value)} onKeyUp={handleKeyUp} />
				</div>
				
				<div className="incsizespan center" id="depositsummary">
					
				</div>

				<div className="container__control--input">
					<input className="text-input" type="email" name="depositemail" id="depositemail" placeholder="Email Or Phone" value={depositemail} onChange={(e) => setDepositEmail(e.target.value)} />
				</div>	

				<div className="incsizespan center" id="depositemailsummary">
					
				</div>	

				<div className="incsizespansms">
					<label htmlFor="depositsms"><input type="checkbox" id="depositsms" /> Receive SMS on Completion <b>₦10</b></label>
				</div>	
				
				<div className="incsizespan center" id="depositfeedback">
					
				</div>
				
				<div className="container__buttons">
					<button className="clipboard" id="depositproceed" onClick={onSubmitDeposit}>PROCEED</button>
				</div>
			</div>
			
		</div>
		
		)
	]
};

const lineOne = {
   fontSize: '15px',
   color: '#d13529',
   lineHeight: '25px',
   fontWeight: '500'
};
const lineTwo = {
   fontSize: '15px',
   lineHeight: '45px',
   fontWeight: '500'
};
const lineThree = {
   fontSize: '15px',
   lineHeight: '25px',
   fontWeight: '500'
};
const redColor = {
   color: '#d13529'
};
const rectStyleWrap = {
   minWidth: '250px',
   height: '200px',
   marginLeft: '-25px',
   gridColumn: 1
};

const center = {
   textAlign: 'center'   
};

 

export default TradeView;
