import { useEffect, useState } from 'react';
import NavBar from './NavBar';

const RegisterView = ({ onPostRegister, thisrefCode }) => {	
		
	const [registercountry, setRegisterCountry] = useState('');
	const [registerrefcode, setRegisterRefcode] = useState('');	
	
	useEffect(() => {
		document.getElementById('maincontainer').className = 'maincontainer2';
		document.getElementById('signuptab').style.color = '#23527C';
		document.getElementById('signuptab').style.fontWeight = '700';
		document.getElementById('signuptab').style.borderBottom = '1px solid #23527C';
		document.getElementById('registerrefcode').value = thisrefCode;
		setRegisterRefcode(thisrefCode);
	},[thisrefCode]);
	
	if(registercountry === 'NG'){
		document.getElementById('registerstateselectwrap').className="container__control--select";
		document.getElementById('registerstatewrap').className="container__control--input hide";
	}else if(document.getElementById('registerstateselectwrap')){
		document.getElementById('registerstateselectwrap').className="container__control--select hide";
		document.getElementById('registerstatewrap').className="container__control--input";	
	}
	
	const onSubmitRegister = (e) => {
		
		var name, email, pass, country, state, state2, address, phone, refcode, emailcode, output, msg, sel, opts, countryname;
		
		name = document.getElementById('registername').value;
		email = document.getElementById('registeremail').value;
		pass = document.getElementById('registerpassword').value;
		country = document.getElementById('registercountry').value;
		state = document.getElementById('registerstate').value;
		state2 = document.getElementById('registerstateselect').value;
		address = document.getElementById('registeraddress').value;
		phone = document.getElementById('registerphone').value;
		refcode = document.getElementById('registerrefcode').value;

		if(registercountry === 'NG'){
			state = state2;
		}

		if (!name) {
			msg = 'Enter full names';
			document.getElementById('registerfeedback').innerHTML = msg;
			document.getElementById('registerfeedback').className = 'incsizespan_error center';
			return false;
		}else if (!email) {
			msg = 'Enter email address';
			document.getElementById('registerfeedback').innerHTML = msg;
			document.getElementById('registerfeedback').className = 'incsizespan_error center';
			return false;
		}else if (!pass) {
			msg = 'Enter password';
			document.getElementById('registerfeedback').innerHTML = msg;
			document.getElementById('registerfeedback').className = 'incsizespan_error center';
			return false;
		}else if (!country) {
			msg = 'Select country';
			document.getElementById('registerfeedback').innerHTML = msg;
			document.getElementById('registerfeedback').className = 'incsizespan_error center';
			return false;
		}else if (!state || (country === 'NG' && state.toLowerCase() === 'select state')) {
			msg = 'Enter state';
			document.getElementById('registerfeedback').innerHTML = msg;
			document.getElementById('registerfeedback').className = 'incsizespan_error center';
			return false;
		}else if (country === 'NG' && state.toLowerCase() === 'select state') {
			msg = 'Enter state';
			document.getElementById('registerfeedback').innerHTML = msg;
			document.getElementById('registerfeedback').className = 'incsizespan_error center';
			return false;
		}else if (country === 'NG' && state.toLowerCase() === 'selectstate') {
			msg = 'Enter state';
			document.getElementById('registerfeedback').innerHTML = msg;
			document.getElementById('registerfeedback').className = 'incsizespan_error center';
			return false;
		}else if (!address) {
			msg = 'Enter home address';
			document.getElementById('registerfeedback').innerHTML = msg;
			document.getElementById('registerfeedback').className = 'incsizespan_error center';
			return false;
		}else if (!phone) {
			msg = 'Enter phone number';
			document.getElementById('registerfeedback').innerHTML = msg;
			document.getElementById('registerfeedback').className = 'incsizespan_error center';
			return false;
		}
		
		if(!document.getElementById('registeremailcodewrap').classList.contains('hide'))
		{
			emailcode = document.getElementById('registeremailcode').value;	
			if (!emailcode) {
				msg = 'Enter email code';
				document.getElementById('registerfeedback').innerHTML = msg;
				document.getElementById('registerfeedback').className = 'incsizespan_error center';
				return false;
			}
		}
		
		sel = document.getElementsByTagName('select')[0];
		opts = document.getElementsByTagName('option');
		countryname = opts[sel.selectedIndex].innerHTML;
		
		if(emailcode){
			output = {
				'rtype': 'register',
				'email': email,
				'name': name,		
				'pass': pass,
				'country': countryname,
				'state': state,
				'address': address,
				'phone': phone,
				'refcode': refcode,
				'emailcode': emailcode
			}
		}else{
			output = {
				'rtype': 'preregister',
				'email': email,
				'name': name,		
				'pass': pass,
				'country': countryname,
				'state': state,
				'address': address,
				'phone': phone,
				'refcode': refcode
			}
		}
		
		onPostRegister(output);
	}
	
	return [
		(<div className="container__canvas" style={rectStyleWrap} key="1">
			<div className="Context">
				<NavBar />
			</div>
		</div>),
		(<div className="[ stack ] [ container__controls ]" key="2">

			<div className="container__control--switch">
				<button className="switch-btn">
					<span className="switch-btnn__marker" id="switch-btnn__marker"></span>
					<span className="center-xy switch-btn__label" ></span>
					<span className="center-xy switch-btn__label" id="5r" >REGISTER HERE</span>
					<span className="center-xy switch-btn__label" ></span>
				</button>
			</div>
			
			<div className="stack disshow" id="registerdisplay">
			
				<div style={center}>
					<h4 style={lineTwo} >SIGN UP</h4>
				</div>
				
				<div className="incsizespan_info center">
					FILL THE FORM BELOW 
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="text" name="registername" id="registername" placeholder="Full Names" />
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="email" name="registeremail" id="registeremail" placeholder="Email" />
				</div>

				<div className="container__control--input password fixpost">
					<input className="text-input" type="password" name="registerpassword" id="registerpassword" placeholder="Password" autoComplete="new-password" />
					<span className="action_password icon-eye"></span>
				</div>
				
				<div className="container__control--select">
					<select className="select-input" name="registercountry" id="registercountry" value={registercountry} onChange={(e) => setRegisterCountry(e.target.value)}>
						<option value="">Select Country</option>
						<option value="NG">Nigeria</option>
					</select>
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="text" name="registeraddress" id="registeraddress" placeholder="Home Address" />
				</div>
				
				<div  id="registerstatewrap" className="container__control--input">
					<input className="text-input" type="text" name="registerstate" id="registerstate" placeholder="State" />
				</div>
				
				<div id="registerstateselectwrap" className="container__control--select hide">
					<select className="select-input" name="registerstateselect" id="registerstateselect"><option value="Select State">Select State</option><option value="Abia">Abia</option><option value="Abuja">Abuja</option><option value="Adamawa">Adamawa</option><option value="Akwa Ibom">Akwa Ibom</option><option value="Anambra">Anambra</option><option value="Bauchi">Bauchi</option><option value="Bayelsa">Bayelsa</option><option value="Benue">Benue</option><option value="Borno">Borno</option><option value="Cross River">Cross River</option><option value="Delta">Delta</option><option value="Ebonyi">Ebonyi</option><option value="Edo">Edo</option><option value="Ekiti">Ekiti</option><option value="Enugu">Enugu</option><option value="Gombe">Gombe</option><option value="Imo">Imo</option><option value="Jigawa">Jigawa</option><option value="Kaduna">Kaduna</option><option value="Kano">Kano</option><option value="Katsina">Katsina</option><option value="Kebbi">Kebbi</option><option value="Kogi">Kogi</option><option value="Kwara">Kwara</option><option value="Lagos">Lagos</option><option value="Nasarawa">Nasarawa</option><option value="Niger">Niger</option><option value="Ogun">Ogun</option><option value="Ondo">Ondo</option><option value="Osun">Osun</option><option value="Oyo">Oyo</option><option value="Plateau">Plateau</option><option value="Rivers">Rivers</option><option value="Sokoto">Sokoto</option><option value="Taraba">Taraba</option><option value="Yobe">Yobe</option><option value="Zamfara">Zamfara</option></select>
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="text" name="registerphone" id="registerphone" placeholder="Mobile Phone" />
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="text" name="registerrefcode" id="registerrefcode" placeholder="Referral Code [Optional]" value={registerrefcode} onChange={(e) => setRegisterRefcode(e.target.value)} />
				</div>
				
				<div id="registeremailcodewrap" className="container__control--input hide">
					<input className="text-input" type="text" name="registeremailcode" id="registeremailcode" placeholder="Email Code" />
				</div>
				
				<div className="incsizespan center" id="registerfeedback">
					
				</div>	
				
				<div className="container__buttons">
					<button className="clipboard" id="registerproceed" onClick={onSubmitRegister}>GET CODE</button>
				</div>
			</div>			
		</div>)
	]
}

const lineTwo = {
   fontSize: '15px',
   color: '#23527c',
   lineHeight: '45px',
   fontWeight: '700'
}

const rectStyleWrap = {
   minWidth: '250px',
   gridColumn: 1
}

const center = {
   textAlign: 'center'   
}

 

export default RegisterView;