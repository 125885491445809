const TradeSwitch = () => {	

	const mLeft = (e) => {
		e.preventDefault()
		var xprev = document.getElementById('switch-btn__check').getAttribute('data-level');
		var sxprev = xprev.toLowerCase();
		var xval = document.getElementById(e.target.id).innerHTML;
		var sxval = xval.toLowerCase();
		//console.log(xprev+' '+xval)
		if(xprev === 'SELL' && xval === 'BUY'){				
			document.getElementById(sxprev+'display').className = "stack animatehide"
			document.getElementById(sxval+'display').className = "stack disshow"
			document.getElementById('switch-btn__marker').style.transform = "translateX(100%)"
			document.getElementById('switch-btn__check').setAttribute('data-level', xval)
		}else if(xprev === 'SELL' && xval === 'DEPOSIT'){			
			document.getElementById(sxprev+'display').className = "stack animatehide"
			document.getElementById(sxval+'display').className = "stack disshow"
			document.getElementById('switch-btn__marker').style.transform = "translateX(200%)"
			document.getElementById('switch-btn__check').setAttribute('data-level', xval)
		}else if(xprev === 'BUY' && xval === 'DEPOSIT'){
			document.getElementById(sxprev+'display').className = "stack animatehide"
			document.getElementById(sxval+'display').className = "stack disshow"
			document.getElementById('switch-btn__marker').style.transform = "translateX(200%)"
			document.getElementById('switch-btn__check').setAttribute('data-level', xval)
		}else if(xprev === 'BUY' && xval === 'SELL'){
			document.getElementById(sxprev+'display').className = "stack animatehide"
			document.getElementById(sxval+'display').className = "stack disshow"
			document.getElementById('switch-btn__marker').style.transform = "translateX(0)"
			document.getElementById('switch-btn__check').setAttribute('data-level', xval)
		}else if(xprev === 'DEPOSIT' && xval === 'BUY'){
			document.getElementById(sxprev+'display').className = "stack animatehide"
			document.getElementById(sxval+'display').className = "stack disshow"
			document.getElementById('switch-btn__marker').style.transform = "translateX(100%)"
			document.getElementById('switch-btn__check').setAttribute('data-level', xval)
		}else if(xprev === 'DEPOSIT' && xval === 'SELL'){
			document.getElementById(sxprev+'display').className = "stack animatehide"
			document.getElementById(sxval+'display').className = "stack disshow"
			document.getElementById('switch-btn__marker').style.transform = "translateX(0)"
			document.getElementById('switch-btn__check').setAttribute('data-level', xval)
		}
		
	}
	
	return (		
		<button className="switch-btn" id="modeSwitch">
			<span id="switch-btn__check" data-level="SELL" className="hide"></span>
			<span className="switch-btn__marker" id="switch-btn__marker"></span>
			<span className="switch-btn__label" id="tradebtn1" onClick={mLeft} >SELL</span>
			<span className="switch-btn__label" id="tradebtn2" onClick={mLeft} >BUY</span>
			<span className="switch-btn__label" id="tradebtn3" onClick={mLeft} >DEPOSIT</span>
		</button>
	)
};

export default TradeSwitch;
