import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate as Redirect } from 'react-router-dom';
import ActivationLinkView from './components/ActivationLinkView';
import DashboardView from './components/DashboardView';
import ForgotPasswordView from './components/ForgotPasswordView';
import LoginView from './components/LoginView';
import RegisterView from './components/RegisterView';
import BankView from './components/BankView';
import ChangepassView from './components/ChangepassView.js';
import ConfirmationView from './components/ConfirmationView.js';
import PhoneView from './components/PhoneView.js';
import ApiSettingView from './components/ApiSettingView.js';
import HistoryView from './components/HistoryView.js';
import TradeView from './components/TradeView';
import TermsView from './components/TermsView';
import PrivacyView from './components/PrivacyView';
import AffiliateView from './components/AffiliateView';
import ContactView from './components/ContactView';
import FaqView from './components/FaqView';
import ApiDocView from './components/ApidocView';
import AmlView from './components/AmlView';
import RateView from './components/RateView';
import SiteOrder from './components/SiteOrder';
import Params from './components/Params';
import Footer from './components/Footer';
import FullLoader from './components/FullLoader';
import BTCLogo from './components/images/btc.svg';
import BCHLogo from './components/images/bch.svg';
import ETHLogo from './components/images/eth.svg';
import LTCLogo from './components/images/ltc.svg';
import USDTLogo from './components/images/usdt.svg';
import PMLogo from './components/images/pm.svg';
import ArrowDown from './components/images/arrowdown.png';
import ArrowUp from './components/images/arrowup.png';

const App = () => {
	
	var websocket, response, obj, refCode = '', wsUri = "wss://endopay.com:1060", maincontainer = 'maincontainer', useremail = '', noticebar = 'cb-position-bottom';
	if(localStorage.getItem('useremail')){
		useremail = localStorage.getItem('useremail');
	}
	
	if(localStorage.getItem('noticebar')){
		noticebar = localStorage.getItem('noticebar');
	}
	const [showOverlay, setShowOverlay] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
	const [params, setParams] = useState([]);	
	const [user, setUser] = useState(useremail);
	const [order, setOrder] = useState('');
	
	const [loader, setLoader] = useState('<img src="data:image/gif;base64,R0lGODlhKwALAPEAAP///8Hf6eDu88Hf6SH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAKwALAAACMoSOCMuW2diD88UKG95W88uF4DaGWFmhZid93pq+pwxnLUnXh8ou+sSz+T64oCAyTBUAACH5BAkKAAAALAAAAAArAAsAAAI9xI4IyyAPYWOxmoTHrHzzmGHe94xkmJifyqFKQ0pwLLgHa82xrekkDrIBZRQab1jyfY7KTtPimixiUsevAAAh+QQJCgAAACwAAAAAKwALAAACPYSOCMswD2FjqZpqW9xv4g8KE7d54XmMpNSgqLoOpgvC60xjNonnyc7p+VKamKw1zDCMR8rp8pksYlKorgAAIfkECQoAAAAsAAAAACsACwAAAkCEjgjLltnYmJS6Bxt+sfq5ZUyoNJ9HHlEqdCfFrqn7DrE2m7Wdj/2y45FkQ13t5itKdshFExC8YCLOEBX6AhQAADsAAAAAAAAAAAA="/>');
	
	const [notify, setNotify] = useState('');
	
	const [usdbtc, setUSDBTC] = useState('');
	const [usdltc, setUSDLTC] = useState('');
	const [usdbch, setUSDBCH] = useState('');
	const [usdeth, setUSDETH] = useState('');
	
	const [sellbtc, setSELLBTC] = useState('...');
	const [sellltc, setSELLLTC] = useState('...');
	const [sellbch, setSELLBCH] = useState('...');
	const [selleth, setSELLETH] = useState('...');
	const [sellusdt, setSELLUSDT] = useState('...');
	const [sellpm, setSELLPM] = useState('...');
	
	const [buybtc, setBUYBTC] = useState('...');
	const [buyltc, setBUYLTC] = useState('...');
	const [buybch, setBUYBCH] = useState('...');
	const [buyeth, setBUYETH] = useState('...');
	const [buyusdt, setBUYUSDT] = useState('...');
	const [buypm, setBUYPM] = useState('...');
		
	if(document.getElementById('overlay') && showMessage === true){
		document.getElementById('overlay').style.display = "block";
	}else if(document.getElementById('overlay')){
		document.getElementById('overlay').style.display = "none";
	}
	
	
	
	var search = window.location.search;
	var thisparams = new URLSearchParams(search);
	var parsed = thisparams.get('ref');		
	if(parsed !== null && parsed.length === 5 && parseFloat(parsed) > 0){
		refCode = parsed;
	}
	
	useEffect(() => 
	{
		var check = cookieCheck('theme_color');
		if(check === 'dark'){
			window.document.getElementById("mainbody").className = 'dark';
		}
	
		localStorage.setItem("internet", "1");
		localStorage.setItem("obj", "");
		localStorage.setItem("objname", "");
		
		const getURL = async () => {	
			var urln = window.location.pathname;
			var allowed = ['signin', 'dashboard', 'signup', 'fpassword', 'getactive', 'cpass', 'bank', 'ccode', 'phone', 'history', 'apisetting', 'terms', 'apidoc', 'privacy', 'affiliate', 'contactus', 'faq', 'aml', 'rates'];
			var thisr = urln.replace("/", "");
			thisr = (urln.replace(/[^A-Z0-9]/i, "")).toUpperCase();
			if(allowed.indexOf(thisr.toLowerCase()) !== -1)
			{
				if(document.getElementById('maincontainer')){
					document.getElementById('maincontainer').className = 'maincontainer2';
				}
			}else{
				if(document.getElementById('maincontainer')){		
					document.getElementById('maincontainer').className = 'maincontainer';
				}
			}
		}
		
		const getParams = async () => {	
			var href, thisref, paramsFromServer, allowedpath, usdbtc, usdeth, usdltc, usdbch, altbuy, btcbuy, pmbuy, usdtbuy, altsell, btcsell, pmsell, usdtsell;
			allowedpath = ['signin', 'dashboard', 'signup', 'fpassword', 'getactive', 'cpass', 'bank', 'ccode', 'phone', 'history', 'apisetting', 'terms', 'apidoc', 'privacy', 'affiliate', 'contactus', 'faq', 'aml', 'rates'];
			
			if(window.location.pathname !== '/' && refCode === '')
			{
				href = window.location.pathname;
				thisref = href.replace("/", "");
				thisref = (href.replace(/[^A-Z0-9]/i, "")).toUpperCase();
								
				if((isFinite(thisref) && thisref.length === 7) || (href.indexOf(thisref) !== -1 && thisref.length === 7)){
					setOrder(thisref);					
					window.history.pushState(thisref, thisref, thisref);
					
				}else if(href === '' || href === '/') {
					paramsFromServer = await fetchParams();
					setParams(paramsFromServer);
					if(paramsFromServer[0])
					{
						usdbtc = (1 / paramsFromServer[0].usdbtc).toFixed(2);
						usdltc = (1 / paramsFromServer[0].usdltc).toFixed(2);
						usdbch = (1 / paramsFromServer[0].usdbch).toFixed(2);
						usdeth = (1 / paramsFromServer[0].usdeth).toFixed(2);
						altbuy = paramsFromServer[0].bchbuy;
						btcbuy = paramsFromServer[0].btcbuy;
						usdtbuy = paramsFromServer[0].usdtbuy;
						pmbuy = paramsFromServer[0].pmbuy;
						altsell = paramsFromServer[0].bchsell;
						btcsell = paramsFromServer[0].btcsell;
						usdtsell = paramsFromServer[0].usdtsell;
						pmsell = paramsFromServer[0].pmsell;
						setUSDBTC(usdbtc);
						setUSDBCH(usdbch);
						setUSDLTC(usdltc);
						setUSDETH(usdeth);
						setSELLBTC(btcsell);
						setSELLBCH(altsell);
						setSELLLTC(altsell);
						setSELLETH(altsell);
						setSELLUSDT(usdtsell);
						setSELLPM(pmsell);
						setBUYBTC(btcbuy);
						setBUYBCH(altbuy);
						setBUYLTC(altbuy);
						setBUYETH(altbuy);
						setBUYUSDT(usdtbuy);
						setBUYPM(pmbuy);
						setNotify(paramsFromServer[0].notice);						
					}
				}else if(allowedpath.indexOf(thisref.toLowerCase()) !== -1) {
					paramsFromServer = await fetchParams();
					setParams(paramsFromServer);
					if(paramsFromServer[0])
					{
						
						usdbtc = (1 / paramsFromServer[0].usdbtc).toFixed(2);
						usdltc = (1 / paramsFromServer[0].usdltc).toFixed(2);
						usdbch = (1 / paramsFromServer[0].usdbch).toFixed(2);
						usdeth = (1 / paramsFromServer[0].usdeth).toFixed(2);
						altbuy = paramsFromServer[0].bchbuy;
						btcbuy = paramsFromServer[0].btcbuy;
						usdtbuy = paramsFromServer[0].usdtbuy;
						pmbuy = paramsFromServer[0].pmbuy;
						altsell = paramsFromServer[0].bchsell;
						btcsell = paramsFromServer[0].btcsell;
						usdtsell = paramsFromServer[0].usdtsell;
						pmsell = paramsFromServer[0].pmsell;					
						setUSDBTC(usdbtc);
						setUSDBCH(usdbch);
						setUSDLTC(usdltc);
						setUSDETH(usdeth);
						setSELLBTC(btcsell);
						setSELLBCH(altsell);
						setSELLLTC(altsell);
						setSELLETH(altsell);
						setSELLUSDT(usdtsell);
						setSELLPM(pmsell);					
						setBUYBTC(btcbuy);
						setBUYBCH(altbuy);
						setBUYLTC(altbuy);
						setBUYETH(altbuy);
						setBUYUSDT(usdtbuy);
						setBUYPM(pmbuy);					
						setNotify(paramsFromServer[0].notice);
					}
				}else{
					setOrder('');					
					window.location.assign('/');
					paramsFromServer = await fetchParams();
					setParams(paramsFromServer);
					if(paramsFromServer[0])
					{
						usdbtc = (1 / paramsFromServer[0].usdbtc).toFixed(2);
						usdltc = (1 / paramsFromServer[0].usdltc).toFixed(2);
						usdbch = (1 / paramsFromServer[0].usdbch).toFixed(2);
						usdeth = (1 / paramsFromServer[0].usdeth).toFixed(2);
						altbuy = paramsFromServer[0].bchbuy;
						btcbuy = paramsFromServer[0].btcbuy;
						usdtbuy = paramsFromServer[0].usdtbuy;
						pmbuy = paramsFromServer[0].pmbuy;
						altsell = paramsFromServer[0].bchsell;
						btcsell = paramsFromServer[0].btcsell;
						usdtsell = paramsFromServer[0].usdtsell;
						pmsell = paramsFromServer[0].pmsell;
						setUSDBTC(usdbtc);
						setUSDBCH(usdbch);
						setUSDLTC(usdltc);
						setUSDETH(usdeth);
						setSELLBTC(btcsell);
						setSELLBCH(altsell);
						setSELLLTC(altsell);
						setSELLETH(altsell);
						setSELLUSDT(usdtsell);
						setSELLPM(pmsell);
						setBUYBTC(btcbuy);
						setBUYBCH(altbuy);
						setBUYLTC(altbuy);
						setBUYETH(altbuy);
						setBUYUSDT(usdtbuy);
						setBUYPM(pmbuy);
						setNotify(paramsFromServer[0].notice);
					}
					
				}
				
			}
			else
			{	
				paramsFromServer = await fetchParams();
				setParams(paramsFromServer);
				if(paramsFromServer[0])
				{
					
					usdbtc = (1 / paramsFromServer[0].usdbtc).toFixed(2);
					usdltc = (1 / paramsFromServer[0].usdltc).toFixed(2);
					usdbch = (1 / paramsFromServer[0].usdbch).toFixed(2);
					usdeth = (1 / paramsFromServer[0].usdeth).toFixed(2);
					altbuy = paramsFromServer[0].bchbuy;
					btcbuy = paramsFromServer[0].btcbuy;
					usdtbuy = paramsFromServer[0].usdtbuy;
					pmbuy = paramsFromServer[0].pmbuy;
					altsell = paramsFromServer[0].bchsell;
					btcsell = paramsFromServer[0].btcsell;
					usdtsell = paramsFromServer[0].usdtsell;
					pmsell = paramsFromServer[0].pmsell;
					setUSDBTC(usdbtc);
					setUSDBCH(usdbch);
					setUSDLTC(usdltc);
					setUSDETH(usdeth);
					setSELLBTC(btcsell);
					setSELLBCH(altsell);
					setSELLLTC(altsell);
					setSELLETH(altsell);
					setSELLUSDT(usdtsell);
					setSELLPM(pmsell);
					setBUYBTC(btcbuy);
					setBUYBCH(altbuy);
					setBUYLTC(altbuy);
					setBUYETH(altbuy);
					setBUYUSDT(usdtbuy);
					setBUYPM(pmbuy);
					setNotify(paramsFromServer[0].notice);
				}
			}
		}		
		getParams();
		setInterval(getParams, 60000);
		setInterval(getURL, 250);
		// eslint-disable-next-line
	}, []);
	
	// Fetch Params
	const fetchParams = async () => {
		try{		
			
			const res = await fetch('https://endopay.com/sitemodule', {timeout: 5000});
			const data = await res.json();
			if(data[0].key){
				localStorage.setItem('_key', response['key']);
			}
			if(localStorage.getItem("internet") === "1")
			{
				localStorage.setItem("internet", "2");
				document.getElementById('toast').className = 'show';
				document.getElementById('toast').innerHTML = '<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="9.667" width="1.67" height="2.5" fill="#0ECB81"></rect><rect x="4.44446" y="5.667" width="1.66667" height="6.67" fill="#0ECB81"></rect><rect x="8.88892" y="3" width="1.66667" height="10.83" fill="#0ECB81"></rect><rect x="13.3333" width="1.66667" height="15" fill="#0ECB81"></rect></svg>&nbsp;&nbsp;<font color="#0ECB81">Connected</font>';
			}else{
				document.getElementById('toast').className = '';
				document.getElementById('toast').innerHTML = '';
			}						
			return data;	
		}catch(e){
			if(e.message === 'Failed to fetch'){
				localStorage.setItem("internet", "1");
				document.getElementById('toast').className = 'show';
				document.getElementById('toast').innerHTML = '<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="9.667" width="1.67" height="2.5" fill="#ccc"></rect><rect x="4.44446" y="5.667" width="1.66667" height="6.67" fill="#ccc"></rect><rect x="8.88892" y="3" width="1.66667" height="10.83" fill="#ccc"></rect><rect x="13.3333" width="1.66667" height="15" fill="#ccc"></rect></svg>&nbsp;&nbsp;<font color="#ccc">No connection</font>';	
			}			
			return false;
		}		
	}	
	
		
	//Post Sell
	const postSell = (sell) => {
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
		setLoader(loader);
		document.getElementById('sellfeedback').innerHTML = '';
		document.getElementById('sellfeedback').className = 'incsizespan center';
		document.getElementById('sellproceed').innerHTML = loader;
		document.getElementById('sellproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(sell));
		localStorage.setItem("objname", "sell"+sell.rtype);
		doCheck();	
	}
	
	//Post Buy
	const postBuy = (buy) => {
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
		document.getElementById('buyfeedback').innerHTML = '';
		document.getElementById('buyfeedback').className = 'incsizespan center';
		document.getElementById('buyproceed').innerHTML = loader;
		document.getElementById('buyproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(buy));
		localStorage.setItem("objname", "buy"+buy.rtype);		
		doCheck();
	}
	
	//Post Deposit
	const postDeposit = (deposit) => {
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
		document.getElementById('depositfeedback').innerHTML = '';
		document.getElementById('depositfeedback').className = 'incsizespan center';
		document.getElementById('depositproceed').innerHTML = loader;
		document.getElementById('depositproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(deposit));
		localStorage.setItem("objname", "deposit"+deposit.rtype);		
		doCheck();
	}
	
	//Post Login
	const postLogin = (login) => {		
		document.getElementById('loginfeedback').innerHTML = '';
		document.getElementById('loginfeedback').className = 'incsizespan center';
		document.getElementById('loginproceed').innerHTML = loader;
		document.getElementById('loginproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(login));
		localStorage.setItem("objname", login.rtype);
		doCheck();
	}
	
	//Post Register
	const postRegister = (register) => {
		document.getElementById('registerfeedback').innerHTML = '';
		document.getElementById('registerfeedback').className = 'incsizespan center';
		document.getElementById('registerproceed').innerHTML = loader;
		document.getElementById('registerproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(register));
		localStorage.setItem("objname", register.rtype);		
		doCheck();
	}
	
	//Post ForgotPassword 
	const postForgotPassword = (pass) => {		
		document.getElementById('forgotfeedback').innerHTML = '';
		document.getElementById('forgotfeedback').className = 'incsizespan center';
		document.getElementById('forgotproceed').innerHTML = loader;
		document.getElementById('forgotproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(pass));
		localStorage.setItem("objname", pass.rtype);		
		doCheck();
	}
	
	//Post GetActive
	const postGetActive = (active) => {
		document.getElementById('activationfeedback').innerHTML = '';
		document.getElementById('activationfeedback').className = 'incsizespan center';
		document.getElementById('activationproceed').innerHTML = loader;
		document.getElementById('activationproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(active));
		localStorage.setItem("objname", active.rtype);		
		doCheck();
	}	
	//
	const postQuickSellBTC = (quicksell) => {
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
		document.getElementById('quicksellbtcfeedback').innerHTML = '';
		document.getElementById('quicksellbtcfeedback').className = 'incsizespan center';
		document.getElementById('quicksellbtcproceed').innerHTML = loader;
		document.getElementById('quicksellbtcproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(quicksell));
		localStorage.setItem("objname", quicksell.rtype);		
		doCheck();
	}
	//
	const postQuickSellUSDT = (quicksell) => {
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
		document.getElementById('quicksellusdtfeedback').innerHTML = '';
		document.getElementById('quicksellusdtfeedback').className = 'incsizespan center';
		document.getElementById('quicksellusdtproceed').innerHTML = loader;
		document.getElementById('quicksellusdtproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(quicksell));
		localStorage.setItem("objname", quicksell.rtype);		
		doCheck();
	}
	//
	const postQuickSellALT = (quicksell) => {
		//console.log(quicksell);
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
	}
	
	//
	const postWithdraw = (withdraw) => {
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
		document.getElementById('withdrawfeedback').innerHTML = '';
		document.getElementById('withdrawfeedback').className = 'incsizespan center';
		document.getElementById('withdrawproceed').innerHTML = loader;
		document.getElementById('withdrawproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(withdraw));
		localStorage.setItem("objname", withdraw.rtype);		
		doCheck();
	}
	
	//
	const postSendBTC = (sendbtc) => {
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
		document.getElementById('sendbtcfeedback').innerHTML = '';
		document.getElementById('sendbtcfeedback').className = 'incsizespan center';
		document.getElementById('sendbtcproceed').innerHTML = loader;
		document.getElementById('sendbtcproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(sendbtc));
		localStorage.setItem("objname", sendbtc.rtype);		
		doCheck();
	}
	
	//
	const postSendUSDT = (sendusdt) => {
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
		document.getElementById('sendusdtfeedback').innerHTML = '';
		document.getElementById('sendusdtfeedback').className = 'incsizespan center';
		document.getElementById('sendusdtproceed').innerHTML = loader;
		document.getElementById('sendusdtproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(sendusdt));
		localStorage.setItem("objname", sendusdt.rtype);		
		doCheck();
	}
	
	//
	const postSendALT = (sendalt) => {
		//console.log(sendalt);
		obj = localStorage.getItem("obj");
		if(obj !== ''){
			return false;
		}
	}
	
	const postBank = (bank) => {
		document.getElementById('bankfeedback').innerHTML = '';
		document.getElementById('bankfeedback').className = 'incsizespan center';
		document.getElementById('bankproceed').innerHTML = loader;
		document.getElementById('bankproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(bank));
		localStorage.setItem("objname", bank.rtype);		
		doCheck();	
	}
	
	const postPhone = (phone) => {
		document.getElementById('phonefeedback').innerHTML = '';
		document.getElementById('phonefeedback').className = 'incsizespan center';
		document.getElementById('phoneproceed').innerHTML = loader;
		document.getElementById('phoneproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(phone));
		localStorage.setItem("objname", phone.rtype);		
		doCheck();
	}
	
	const postPassword = (pass) => {
		document.getElementById('cpasswordfeedback').innerHTML = '';
		document.getElementById('cpasswordfeedback').className = 'incsizespan center';
		document.getElementById('cpasswordproceed').innerHTML = loader;
		document.getElementById('cpasswordproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(pass));
		localStorage.setItem("objname", pass.rtype);		
		doCheck();
	}
	
	const postConfirmation = (confirmation) => {
		document.getElementById('confirmationfeedback').innerHTML = '';
		document.getElementById('confirmationfeedback').className = 'incsizespan center';
		document.getElementById('confirmationproceed').innerHTML = loader;
		document.getElementById('confirmationproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(confirmation));
		localStorage.setItem("objname", confirmation.rtype);		
		doCheck();
	}
	
	const postReset = (reset) => {
		localStorage.setItem("obj", JSON.stringify(reset));
		localStorage.setItem("objname", reset.rtype);		
		doCheck();
	}
	
	const postIP = (ip) => {
		document.getElementById('apifeedback').innerHTML = '';
		document.getElementById('apifeedback').className = 'incsizespan center';
		document.getElementById('apiproceed').innerHTML = loader;
		document.getElementById('apiproceed').setAttribute('disabled', true);
		localStorage.setItem("obj", JSON.stringify(ip));
		localStorage.setItem("objname", ip.rtype);		
		doCheck();
	}
	
	const postApiGen = (ip) => {
		document.getElementById('apigenerate').innerHTML = loader;
		localStorage.setItem("obj", JSON.stringify(ip));
		localStorage.setItem("objname", ip.rtype);		
		doCheck();
	}
	
	const postApiSwitch = (ip) => {
		localStorage.setItem("obj", JSON.stringify(ip));
		localStorage.setItem("objname", ip.rtype);		
		doCheck();
	}
	
	//Post Logout
	const postLogout = () => {
		if(window.confirm('Do you want to logout?'))
		{
			localStorage.setItem('useremail', '');
			setUser('');
			localStorage.clear();
			localStorage.setItem('maincontainer', 'maincontainer');
			window.location.assign("/");
		}
	}
	
	//Send Error Report
	const sendReport = (type, msg) => {
		var thisobj;
		thisobj = {'rtype': 'report', 'type': type, 'msg': msg};
		localStorage.setItem("objall", JSON.stringify(thisobj));
		localStorage.setItem("obj", JSON.stringify(thisobj));
		localStorage.setItem("objname", thisobj.rtype);
		doCheck();		
	}
	
	//Message Alert
	const msgAlert = (msg) => {
		setShowMessage(!showMessage);
		setShowOverlay(!showOverlay);
		if(document.getElementById('overlay')){
			document.getElementById('overlay').style.display = "block";
		}
		return false;
	}	
	
	//Name Lookup
	const nameLookUp = (obj) => {
		var type, val, thisobj;
		type = obj['type'];
		val = obj['search'];
		thisobj = {'rtype': 'namelookup', 'type': type, 'name': val};
		localStorage.setItem("objall", JSON.stringify(obj));
		localStorage.setItem("obj", JSON.stringify(thisobj));
		localStorage.setItem("objname", thisobj.rtype);
		doCheck();		
	}		
	
	//Unregister Bank Name Lookup
	const fetchBankName = (obj) => {		
		var a, b, c, acct, bank, thisobj;
		a = obj['acct'];
		b = obj['bank'];
		c = obj['display'];
		acct = document.getElementById(a).value;
		bank = document.getElementById(b).value;
		const mainString = bank.toLowerCase();
		const searchStrings = ["opay", "palm"];
		document.getElementById(a+'name').value = '';
		document.getElementById(c).style.display = 'none';
		
		if(acct.length !== 10){			
			if(acct.length === 11 && searchStrings.some(searchString => mainString.includes(searchString))){		
				
			}else{
				return false;
			}
		}else if(!bank){
			return false;
		}
		
		thisobj = {'rtype': 'banklookup', 'bankacct': acct, 'bankname': bank};
		localStorage.setItem("objall", JSON.stringify(obj));
		localStorage.setItem("obj", JSON.stringify(thisobj));
		localStorage.setItem("objname", thisobj.rtype);
		doCheck();
	}
	
	function cookieSet(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires="+d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function cookieGet(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
	
	function cookieCheck(cname){
		var color = cookieGet(cname);
		if(color){
			return color;
		}else{
			cookieSet(cname, 'dark', 30);
			return 'dark';
		}
	}
	
	function doCheck()
	{
		endoSocket();
	}
	
	function endoSocket() 
	{
		try {
			websocket = new WebSocket(wsUri);
			websocket.addEventListener('open', onOpen);
			websocket.addEventListener('close', onClose);
			websocket.addEventListener('message', onMessage);
			websocket.addEventListener('error', onError);
		} catch (e) {
			try {
				fetch('https://endopay.com/connection');
			} catch (error) {
				console.error(error.message);
			}
		}
	}	
	
	function onOpen(evt) 
	{
		let len, session, sessid, bkey;

		if (document.getElementById('bkey')) 
		{
			bkey = document.getElementById('bkey').value;
		} else {
			bkey = '';
		}

		session = sess() + sess() + '-' + sess();
		sessid = ',"thissession":"' + session + '","bkey":"' + bkey + '"}';
		obj = localStorage.getItem('obj');
		len = obj.length - 1;

		if (obj) {
			sessionStorage.setItem('session', session);
			obj = obj.substring(0, len) + sessid;
			doSend(obj);
		}
	}

	function onClose(evt) 
	{
		//console.error('WebSocket connection closed:', evt);
		//websocket.close();

		// Reconnect after a short delay (e.g., 3 seconds)
		setTimeout(() => {
			if (websocket.readyState !== WebSocket.OPEN) {
				endoSocket();
			}
		}, 30000);
	}
	
	function onError(evt) 
	{
		//console.error('WebSocket error:', evt);
		
		if (websocket.readyState !== WebSocket.CLOSED) {
			// Reconnect after a short delay (e.g., 3 seconds)
			setTimeout(() => {
				if (websocket.readyState !== WebSocket.OPEN) {
					endoSocket();
				}
			}, 30000);
		}
	}
	
	function onMessage(evt)
	{
		var sent, sendreport = false, sendmsg = '';
		response = evt.data; 
		if(JSON.parse(response))
		{
			response = JSON.parse(response);

			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'report')
			{
				alert('Unknown error');
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}
			
			//Name LookUp
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'namelookup')
			{
				var type, val, t, field, acctname, acctresolved, regresolved, res, bankname, bankacct, bankacctdisplay, pmname;
				sent = JSON.parse(localStorage.getItem("objall"));
				type = sent['type'];
				val = sent['search'];
				t = sent['product'];
				field = sent['field'];
				acctname = sent['acctname'];
				acctresolved = sent['acctresolved'];
				regresolved = sent['regresolved'];		
				
			
				if(response['msg']){
					res = response['msg'];
				}else if(response['status']){
					if(response['bankname']){
						bankname = response['bankname'];
						bankacct = response['bankaccount'];
						bankacctdisplay = bankacct.substr(0, 3)+'****'+bankacct.substr(7, 3);	
					}else if(response['pmname']){
						pmname = response['pmname'];
					}
				}else{
					localStorage.setItem("obj", "");
					localStorage.setItem("objname", "");
					websocket.close();
					return false;
				}
				
				
				if(res === 'Account does not exist'){
					if(type === 'cc'){
						//display other account field
						document.getElementById("isreg").value = "0";	
						document.getElementById(regresolved).value = '0';
						if(t === 'sell'){
							document.getElementById(t+'emailsummary').className = 'incsizespan center';
							document.getElementById(t+'emailsummary').innerHTML = '<b>Account Resolved</b><br/><b>Login and Add Bank Details</b><br/><font color="#d13529"><b>Or</b></font><br/><label for="sellcontinue"><input type="checkbox" id="sellcontinue" onClick="onSellContinue()" /> <b>Click Here to Continue</b></label>';
							document.getElementById(t+'emailsummary').style.display = 'block';					
						}else{
							document.getElementById(t+'emailsummary').innerHTML = '<b>Account Resolved</b>';					
							document.getElementById(t+'emailsummary').style.display = 'block';	
						}
					}else{
						
						document.getElementById(acctname).value = "";
						document.getElementById(field+'summary').innerHTML = '<b>'+val+'</b> appears to be Invalid <br/><a href="#" style="outline: none;" title="Invalid Perfect Money Account" id="invalidpm_form_click">Learn More</a><br/><label for="buyacctconfirm"><font color="#d13529"><input type="checkbox" id="buyacctconfirm"/> <b>Click Here If Account Is Correct</b></font></label>';					
						document.getElementById(field+'summary').style.display = 'block';
					}
				}else if(res === 'Wrong Account'){ // pm
					document.getElementById(acctname).value = "";
					document.getElementById(field+'summary').className = 'incsizespan_error center';
					document.getElementById(field+'summary').innerHTML = '<b>'+val+'</b> is an Invalid Perfect Money Account';
					document.getElementById(field+'summary').style.display = 'block';
				}else if(res === 'Not a member'){ // cc
					//register
					document.getElementById(regresolved).value = '0';
					
					if(t === 'sell'){
						document.getElementById("isnew").value = '1';
						localStorage.setItem("isnew", "1")
						document.getElementById(t+'emailsummary').className = 'incsizespan center';
						document.getElementById(t+'emailsummary').style.display = 'block';
						document.getElementById(t+'emailsummary').innerHTML = '<b>'+val+'</b><br>is not registered<br/><br/><label for="sellcontinue"><font color="#d13529"><input type="checkbox" id="sellcontinue" onClick="onSellContinue()"/> <b>For Non Registered</b> <br/> <b>Click Here to Continue</b></font></label>';
					}else{
						document.getElementById(t+'emailsummary').className = 'incsizespan_error center';
						document.getElementById(t+'emailsummary').innerHTML = '<b>Invalid Account</b><br/><b><a style="text-decoration: underline; color: #23527c" href="/signup">Sign Up</a></b>';
						document.getElementById(t+'emailsummary').style.display = 'block';
						document.getElementById("isreg").value = '1';
						document.getElementById("isnew").value = '0';
						localStorage.setItem("isnew", "0");
					}
					
				}else{
					if(type === 'cc'){
						//display other account field
						document.getElementById(regresolved).value = '1';
						document.getElementById(t+'emailsummary').className = 'incsizespan center';
						if(t === 'sell'){
							if(!bankacct){
								document.getElementById(t+'emailsummary').innerHTML = '<b>Account Resolved</b><br/><a href="/settings">Add Bank Details</a>';
								document.getElementById(t+'emailsummary').style.display = 'block';
							}else{
								if(document.getElementById(t+'continue')){
									document.getElementById(t+'continue').setAttribute('checked', false);
								}
								document.getElementById("isreg").value = '1';
								document.getElementById("isnew").value = '0';						
								if(t === 'sell' && document.getElementById(t+'smswrap')){
									document.getElementById(t+'sms').checked = false;
									document.getElementById(t+'sms').setAttribute('checked', false);
									document.getElementById(t+'smswrap').className = 'incsizespan hide';
									document.getElementById(t+'smsphonewrap').className = 'container__control--input hide';
								}
								localStorage.setItem("isnew", "0");
								document.getElementById(t+'emailsummary').innerHTML = '<b>Account Resolved</b><br/>Bank Name: <b>'+bankname+'</b><br/>Bank Account: <b>'+bankacctdisplay+'</b>';
								document.getElementById(t+'emailsummary').style.display = 'block';
							}
							
						}else{
							document.getElementById(t+'emailsummary').innerHTML = '<b>Account Resolved</b>';
							document.getElementById(t+'emailsummary').style.display = 'block';
						}
						
					}else{				
						document.getElementById(acctresolved).value = '1';
						document.getElementById(acctname).value = pmname;
						document.getElementById(field+'summary').innerHTML = '<b>'+val+' = '+pmname+'</b>';
						document.getElementById(field+'summary').style.display = 'block';
					}
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}
			
			//Bank LookUp
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'banklookup')
			{
				var a, b, c, acct, bank;
				sent = JSON.parse(localStorage.getItem("objall"));
				a = sent['acct'];
				b = sent['bank'];				
				c = sent['display'];
				
				acct = document.getElementById(a).value;
				bank = document.getElementById(b).value;
				const mainString = bank.toLowerCase();
				const searchStrings = ["opay", "palm"];
				document.getElementById(a+'name').value = '';
				document.getElementById(c).style.display = 'none';
				if(acct.length !== 10){
					if(acct.length === 11 && searchStrings.some(searchString => mainString.includes(searchString))){		
						
					}else{
						localStorage.setItem("obj", "");
						localStorage.setItem("objname", "");
						websocket.close();
						return false;
					}
				}else if(!bank){
					localStorage.setItem("obj", "");
					localStorage.setItem("objname", "");
					websocket.close();
					return false;
				}
				
				if(response['status']){
					if(document.getElementById('sellcontinue') && document.getElementById('sellcontinue').checked){
						document.getElementById('sellreg').value = '1';
						document.getElementById(c).style.display = 'block';
						document.getElementById(c).className = 'incsizespansms center show';
						document.getElementById(c).innerHTML = '<b>BANK ACCOUNT NAME</b><br/>'+response['msg'];	
						document.getElementById(a+'name').value = response['msg'];
						if(c === 'sellbanksummary' && document.getElementById('sellsmswrap')){
							document.getElementById('sellsms').checked = false;
							document.getElementById('sellsms').setAttribute('checked', false);
							document.getElementById('sellsmswrap').className = 'incsizespansms';
							document.getElementById('sellsmsphonewrap').className = 'container__control--input hide';
						}
					}
				}else{	
					if(document.getElementById('sellbankinfo1') && document.getElementById('sellbankinfo1').className !== 'container__control--input hide'){
						document.getElementById('sellreg').value = '0';
						document.getElementById(c).style.display = 'block';
						document.getElementById(c).className = 'incsizespan_error center show';
						document.getElementById(c).innerHTML = '<b>INVALID BANK INFO</b>';				
						document.getElementById(a+'name').value = '';
						if(c === 'sellbanksummary' && document.getElementById('sellsmswrap')){
							document.getElementById('sellsms').checked = false;
							document.getElementById('sellsms').setAttribute('checked', false);
							document.getElementById('sellsmswrap').className = 'incsizespan hide';
							document.getElementById('sellsmsphonewrap').className = 'container__control--input hide';
						}
					}			
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}
			
			//
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'getprice')
			{
				var currency;
				sent = JSON.parse(localStorage.getItem("obj"));
				currency = sent['currency'];
				document.getElementById('usdalt').value = ''+(1 / parseFloat(response[''+currency.toUpperCase()]));		
			}
				
			//LOGIN
			if(localStorage.getItem("objname") && (localStorage.getItem("objname") === 'prelogin' || localStorage.getItem("objname") === 'login'))
			{	
				
				if(response && response['status']){
					if(response && response['emailcode']){
						document.getElementById('loginfeedback').innerHTML = 'Check Email For Login ID: '+response['emailcode'];
						document.getElementById('loginfeedback').className = 'incsizespan_info center';
						document.getElementById('loginemailcodewrap').className = 'container__control--input';
						document.getElementById('loginemailcode').setAttribute('placeholder', response['msg']+response['emailcode']);
						document.getElementById('loginemailcode').focus();
						document.getElementById('loginproceed').innerHTML = 'LOGIN';
						document.getElementById('loginproceed').removeAttribute('disabled');
					}else if(response['status'] === 'success'){
						setUser(response['msg']);
						document.getElementById('maincontainer').className = 'maincontainer2';
						localStorage.setItem('maincontainer', 'maincontainer2');
						localStorage.setItem('useremail', response['msg']);
						localStorage.setItem('_key', response['key']);
						window.location.assign("dashboard");
					}			
				}else if(response && response['msg']){
					document.getElementById('loginfeedback').innerHTML = response['msg'];
					document.getElementById('loginfeedback').className = 'incsizespan_error center';
					document.getElementById('loginproceed').innerHTML = 'GET CODE';
					document.getElementById('loginproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('loginproceed').innerHTML = 'GET CODE';
					document.getElementById('loginproceed').removeAttribute('disabled');
					sendreport = true;
					sendmsg = localStorage.getItem('obj');					
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				if(sendreport === true){
					sendReport('Login unknown error', sendmsg);
				}
				return false;
			}		
			
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'selltrade')
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}				
				if(response && response['status'] && response['order']){
					document.getElementById('sellfeedback').innerHTML = response['order'];
					document.getElementById('sellfeedback').className = 'incsizespan_info center';
					if(response['order'])
					{
						setOrder(response['order']);
						window.history.pushState(response['order'], response['order'], response['order']);
					}
					else
					{
						document.getElementById('sellproceed').innerHTML = 'PROCEED';
						document.getElementById('sellproceed').removeAttribute('disabled');
					}
				}else if(response && response['msg']){					
					document.getElementById('sellfeedback').innerHTML = response['msg'];
					document.getElementById('sellfeedback').className = 'incsizespan_error center';
					document.getElementById('sellproceed').innerHTML = 'PROCEED';
					document.getElementById('sellproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('sellfeedback').innerHTML = 'Unknown socket error';
					document.getElementById('sellfeedback').className = 'incsizespan_error center';
					document.getElementById('sellproceed').innerHTML = 'PROCEED';
					document.getElementById('sellproceed').removeAttribute('disabled');
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}
			
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'buytrade')
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status'] && response['order']){
					document.getElementById('buyfeedback').innerHTML = response['order'];
					document.getElementById('buyfeedback').className = 'incsizespan_info center';
					if(response['order'])
					{
						setOrder(response['order']);
						window.history.pushState(null, null, response['order']);
					}
					else
					{
						document.getElementById('buyproceed').innerHTML = 'PROCEED';
						document.getElementById('buyproceed').removeAttribute('disabled');
					}
				}else if(response && response['msg']){
					document.getElementById('buyfeedback').innerHTML = response['msg'];
					document.getElementById('buyfeedback').className = 'incsizespan_error center';
					document.getElementById('buyproceed').innerHTML = 'PROCEED';
					document.getElementById('buyproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('buyfeedback').innerHTML = 'Unknown socket error';
					document.getElementById('buyfeedback').className = 'incsizespan_error center';
					document.getElementById('buyproceed').innerHTML = 'PROCEED';
					document.getElementById('buyproceed').removeAttribute('disabled');
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}
			
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'deposittrade')
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status'] && response['order']){
					document.getElementById('depositfeedback').innerHTML = response['order'];
					document.getElementById('depositfeedback').className = 'incsizespan_info center';
					if(response['order'])
					{
						setOrder(response['order']);
						window.history.pushState(null, null, response['order']);
					}
					else
					{
						document.getElementById('depositproceed').innerHTML = 'PROCEED';
						document.getElementById('depositproceed').removeAttribute('disabled');
					}
				}else if(response && response['msg']){
					document.getElementById('depositfeedback').innerHTML = response['msg'];
					document.getElementById('depositfeedback').className = 'incsizespan_error center';
					document.getElementById('depositproceed').innerHTML = 'PROCEED';
					document.getElementById('depositproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('depositfeedback').innerHTML = 'Unknown socket error';
					document.getElementById('depositfeedback').className = 'incsizespan_error center';
					document.getElementById('depositproceed').innerHTML = 'PROCEED';
					document.getElementById('depositproceed').removeAttribute('disabled');
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}					
		
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'quicksellbtc')
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status'] && response['order']){
					document.getElementById('quicksellbtcfeedback').innerHTML = response['order'];
					document.getElementById('quicksellbtcfeedback').className = 'incsizespan_info center';
					if(response['order'])
					{
						setOrder(response['order']);
						window.history.pushState(null, null, response['order']);
					}
					else
					{
						document.getElementById('quicksellbtcproceed').innerHTML = 'PROCEED';
						document.getElementById('quicksellbtcproceed').removeAttribute('disabled');
					}
				}else if(response && response['msg']){
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					document.getElementById('quicksellbtcfeedback').innerHTML = response['msg'];
					document.getElementById('quicksellbtcfeedback').className = 'incsizespan_error center';
					document.getElementById('quicksellbtcproceed').innerHTML = 'PROCEED';
					document.getElementById('quicksellbtcproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('quicksellbtcfeedback').innerHTML = 'Unknown socket error';
					document.getElementById('quicksellbtcfeedback').className = 'incsizespan_error center';
					document.getElementById('quicksellbtcproceed').innerHTML = 'PROCEED';
					document.getElementById('quicksellbtcproceed').removeAttribute('disabled');
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}	

			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'quicksellusdt')
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status'] && response['order']){
					document.getElementById('quicksellusdtfeedback').innerHTML = response['order'];
					document.getElementById('quicksellusdtfeedback').className = 'incsizespan_info center';
					if(response['order'])
					{
						setOrder(response['order']);
						window.history.pushState(null, null, response['order']);
					}
					else
					{
						document.getElementById('quicksellusdtproceed').innerHTML = 'PROCEED';
						document.getElementById('quicksellusdtproceed').removeAttribute('disabled');
					}
				}else if(response && response['msg']){
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					document.getElementById('quicksellusdtfeedback').innerHTML = response['msg'];
					document.getElementById('quicksellusdtfeedback').className = 'incsizespan_error center';
					document.getElementById('quicksellusdtproceed').innerHTML = 'PROCEED';
					document.getElementById('quicksellusdtproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('quicksellusdtfeedback').innerHTML = 'Unknown socket error';
					document.getElementById('quicksellusdtfeedback').className = 'incsizespan_error center';
					document.getElementById('quicksellusdtproceed').innerHTML = 'PROCEED';
					document.getElementById('quicksellusdtproceed').removeAttribute('disabled');
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}	

			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'withdraw')
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status'] && response['order']){
					document.getElementById('withdrawfeedback').innerHTML = response['order'];
					document.getElementById('withdrawfeedback').className = 'incsizespan_info center';
					
					if(response['order'])
					{
						setOrder(response['order']);
						window.history.pushState(null, null, response['order']);
					}
					else
					{
						document.getElementById('withdrawproceed').innerHTML = 'PROCEED';
						document.getElementById('withdrawproceed').removeAttribute('disabled');
					}
				}else if(response && response['msg']){
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					document.getElementById('withdrawfeedback').innerHTML = response['msg'];
					document.getElementById('withdrawfeedback').className = 'incsizespan_error center';
					document.getElementById('withdrawproceed').innerHTML = 'PROCEED';
					document.getElementById('withdrawproceed').removeAttribute('disabled');
					
				}else if(response){
					document.getElementById('withdrawfeedback').innerHTML = 'Unknown socket error';
					document.getElementById('withdrawfeedback').className = 'incsizespan_error center';
					document.getElementById('withdrawproceed').innerHTML = 'PROCEED';
					document.getElementById('withdrawproceed').removeAttribute('disabled');
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}
			
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'sendbtc')
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status'] && response['order']){
					document.getElementById('sendbtcfeedback').innerHTML = response['order'];
					document.getElementById('sendbtcfeedback').className = 'incsizespan_info center';
					
					if(response['order'])
					{
						setOrder(response['order']);
						window.history.pushState(null, null, response['order']);
					}
					else
					{
						document.getElementById('sendbtcproceed').innerHTML = 'PROCEED';
						document.getElementById('sendbtcproceed').removeAttribute('disabled');
					}
				}else if(response && response['msg']){
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					document.getElementById('sendbtcfeedback').innerHTML = response['msg'];
					document.getElementById('sendbtcfeedback').className = 'incsizespan_error center';
					document.getElementById('sendbtcproceed').innerHTML = 'PROCEED';
					document.getElementById('sendbtcproceed').removeAttribute('disabled');
					
				}else if(response){
					document.getElementById('sendbtcfeedback').innerHTML = 'Unknown socket error';
					document.getElementById('sendbtcfeedback').className = 'incsizespan_error center';
					document.getElementById('sendbtcproceed').innerHTML = 'PROCEED';
					document.getElementById('sendbtcproceed').removeAttribute('disabled');
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}
		
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'sendusdt')
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status'] && response['order']){
					document.getElementById('sendusdtfeedback').innerHTML = response['order'];
					document.getElementById('sendusdtfeedback').className = 'incsizespan_info center';
					
					if(response['order'])
					{
						setOrder(response['order']);
						window.history.pushState(null, null, response['order']);
					}
					else
					{
						document.getElementById('sendusdtproceed').innerHTML = 'PROCEED';
						document.getElementById('sendusdtproceed').removeAttribute('disabled');
					}
				}else if(response && response['msg']){
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					document.getElementById('sendusdtfeedback').innerHTML = response['msg'];
					document.getElementById('sendusdtfeedback').className = 'incsizespan_error center';
					document.getElementById('sendusdtproceed').innerHTML = 'PROCEED';
					document.getElementById('sendusdtproceed').removeAttribute('disabled');
					
				}else if(response){
					document.getElementById('sendusdtfeedback').innerHTML = 'Unknown socket error';
					document.getElementById('sendusdtfeedback').className = 'incsizespan_error center';
					document.getElementById('sendusdtproceed').innerHTML = 'PROCEED';
					document.getElementById('sendusdtproceed').removeAttribute('disabled');
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}	
		
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'phone')
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status']){				
					document.getElementById('phonefeedback').innerHTML = response['response'];
					document.getElementById('phonefeedback').className = 'incsizespan_info center';
					document.getElementById('phoneproceed').innerHTML = 'PROCEED';
					document.getElementById('phoneproceed').removeAttribute('disabled');
				}else if(response && response['msg']){
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					document.getElementById('phonefeedback').innerHTML = response['msg'];
					document.getElementById('phonefeedback').className = 'incsizespan_error center';
					document.getElementById('phoneproceed').innerHTML = 'PROCEED';
					document.getElementById('phoneproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('phoneproceed').innerHTML = 'PROCEED';
					document.getElementById('phoneproceed').removeAttribute('disabled');
					sendreport = true;
					sendmsg = localStorage.getItem('obj');					
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				if(sendreport === true){
					sendReport('Phone unknown error', sendmsg);
				}
				return false;
			}
		
			if(localStorage.getItem("objname") && (localStorage.getItem("objname") === 'pregetactive' || localStorage.getItem("objname") === 'getactive'))
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status']){
					document.getElementById('activationfeedback').innerHTML = response['response'];
					document.getElementById('activationfeedback').className = 'incsizespan_info center';
					document.getElementById('activationemailcodewrap').className = 'container__control--input';
					document.getElementById('activationemailcode').focus();
					document.getElementById('activationproceed').innerHTML = 'PROCEED';
					document.getElementById('activationproceed').removeAttribute('disabled');			
				}else if(response && response['msg']){
					document.getElementById('activationfeedback').innerHTML = response['msg'];
					document.getElementById('activationfeedback').className = 'incsizespan_error center';
					document.getElementById('activationproceed').innerHTML = 'GET CODE';
					document.getElementById('activationproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('activationproceed').innerHTML = 'GET CODE';
					document.getElementById('activationproceed').removeAttribute('disabled');
					sendreport = true;
					sendmsg = localStorage.getItem('obj');					
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				if(sendreport === true){
					sendReport('Activation unknown error', sendmsg);
				}
				return false;
			}
		
			if(localStorage.getItem("objname") && (localStorage.getItem("objname") === 'fpass' || localStorage.getItem("objname") === 'fpassreset'))
			{
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status']){
					document.getElementById('forgotfeedback').innerHTML = response['response'];
					document.getElementById('forgotfeedback').className = 'incsizespan_info center';
					document.getElementById('forgotcodewrap').className = 'container__control--input';
					document.getElementById('forgotcode').focus();
					document.getElementById('forgotpasswrap').className = 'container__control--input password fixpost';
					document.getElementById('forgotrepasswrap').className = 'container__control--input password fixpost';
					document.getElementById('forgotproceed').innerHTML = 'PROCEED';
					document.getElementById('forgotproceed').removeAttribute('disabled');
					if(response['response'] === 'Successfully Updated'){
						document.getElementById('forgotcodewrap').className = 'container__control--input hide';
						document.getElementById('forgotpasswrap').className = 'container__control--input password fixpost hide';
						document.getElementById('forgotrepasswrap').className = 'container__control--input password fixpost hide';
						localStorage.clear();
					}
				}else if(response && response['msg']){
					document.getElementById('forgotfeedback').innerHTML = response['msg'];
					document.getElementById('forgotfeedback').className = 'incsizespan_error center';
					document.getElementById('forgotproceed').innerHTML = 'GET CODE';
					document.getElementById('forgotproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('forgotproceed').innerHTML = 'GET CODE';
					document.getElementById('forgotproceed').removeAttribute('disabled');
					sendreport = true;
					sendmsg = localStorage.getItem('obj');					
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				if(sendreport === true){
					sendReport('Forgot unknown error', sendmsg);
				}
				return false;
			}
		
			if(localStorage.getItem("objname") && (localStorage.getItem("objname") === 'addbank' || localStorage.getItem("objname") === 'addbank2'))
			{	
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status'])
				{
					if(response['response'] === 'Enter the amount sent to your account.'){
						document.getElementById('bankcodewrap').className = 'container__control--input';
					}
					document.getElementById('bankfeedback').innerHTML = response['response'];
					document.getElementById('bankfeedback').className = 'incsizespan_info center';
					document.getElementById('bankproceed').innerHTML = 'PROCEED';
					document.getElementById('bankproceed').removeAttribute('disabled');	
					if(response['response'] === 'Successfully Updated'){
						window.location.assign("dashboard");
					}
				}else if(response && response['msg']){
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					document.getElementById('bankfeedback').innerHTML = response['msg'];
					document.getElementById('bankfeedback').className = 'incsizespan_error center';
					document.getElementById('bankproceed').innerHTML = 'PROCEED';
					document.getElementById('bankproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('bankproceed').innerHTML = 'PROCEED';
					document.getElementById('bankproceed').removeAttribute('disabled');
					sendreport = true;
					sendmsg = localStorage.getItem('obj');					
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				if(sendreport === true){
					sendReport('Bank unknown error', sendmsg);
				}
				return false;
			}
		
			if(localStorage.getItem("objname") && (localStorage.getItem("objname") === 'cpass'))
			{	
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status']){					
					document.getElementById('cpasswordfeedback').innerHTML = response['response'];
					document.getElementById('cpasswordfeedback').className = 'incsizespan_info center';
					document.getElementById('cpasswordproceed').innerHTML = 'PROCEED';
					document.getElementById('cpasswordproceed').removeAttribute('disabled');	
					if(response['response'] === 'Successfully Updated'){
						window.location.assign("dashboard");				
					}
				}else if(response && response['msg']){
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					document.getElementById('cpasswordfeedback').innerHTML = response['msg'];
					document.getElementById('cpasswordfeedback').className = 'incsizespan_error center';
					document.getElementById('cpasswordproceed').innerHTML = 'PROCEED';
					document.getElementById('cpasswordproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('cpasswordproceed').innerHTML = 'PROCEED';
					document.getElementById('cpasswordproceed').removeAttribute('disabled');
					sendreport = true;
					sendmsg = localStorage.getItem('obj');					
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				if(sendreport === true){
					sendReport('Cpass unknown error', sendmsg);
				}
				return false;
			}
			
			if(localStorage.getItem("objname") && (localStorage.getItem("objname") === 'ccode' || localStorage.getItem("objname") === 'ccodereset'))
			{	
				if(response['key']){
					localStorage.setItem('_key', response['key']);					
				}
				if(response && response['status']){	
					if(localStorage.getItem("objname") === 'ccodereset')
					{
						alert(response['response']);	
						localStorage.setItem("obj", "");
						localStorage.setItem("objname", "");						
						window.location.assign("dashboard");
						return false;
					}
					document.getElementById('confirmationfeedback').innerHTML = response['response'];
					document.getElementById('confirmationfeedback').className = 'incsizespan_info center';
					document.getElementById('confirmationproceed').innerHTML = 'PROCEED';
					document.getElementById('confirmationproceed').removeAttribute('disabled');	
					if(response['response'] === 'Successfully Updated'){
						window.location.assign("dashboard");				
					}
				}else if(response && response['msg']){					
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					if(localStorage.getItem("objname") === 'ccodereset')
					{
						alert(response['msg']);
						localStorage.setItem("obj", "");
						localStorage.setItem("objname", "");
						return false;
					}
					document.getElementById('confirmationfeedback').innerHTML = response['msg'];
					document.getElementById('confirmationfeedback').className = 'incsizespan_error center';
					document.getElementById('confirmationproceed').innerHTML = 'PROCEED';
					document.getElementById('confirmationproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('confirmationproceed').innerHTML = 'PROCEED';
					document.getElementById('confirmationproceed').removeAttribute('disabled');
					sendreport = true;
					sendmsg = localStorage.getItem('obj');					
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				if(sendreport === true){
					sendReport('Confirmation unknown error', sendmsg);
				}
				return false;
			}
		
			if(localStorage.getItem("objname") && (localStorage.getItem("objname") === 'preregister' || localStorage.getItem("objname") === 'register'))
			{
				if(response && response['status']){					
					document.getElementById('registerfeedback').innerHTML = response['response'];
					document.getElementById('registerfeedback').className = 'incsizespan_info center';
					document.getElementById('registerproceed').innerHTML = 'PROCEED';
					document.getElementById('registerproceed').removeAttribute('disabled');	
					if(response['response'] === 'Successfully Registered')
					{
						document.getElementById('registerfeedback').innerHTML = response['msg'];
						if(response['msg'] !== ''){
							document.getElementById('registeremailcodewrap').className = 'container__control--input';
							document.getElementById('registeremailcode').focus();
						}else{
							document.getElementById('registerfeedback').innerHTML = 'Use activation page to activate account. You will be redirected in 5 secs...';
							setTimeout(function(){ window.location.assign('getactive') }, 5000);
						}
					}
					else if(response['response'] === 'Successfully Activated')
					{
						document.getElementById('registerfeedback').innerHTML = response['response']+". Login in...";
						setTimeout(function(){
							setUser(response['msg']);
							document.getElementById('maincontainer').className = 'maincontainer2';
							localStorage.setItem('maincontainer', 'maincontainer2');
							localStorage.setItem('useremail', response['msg']);
							localStorage.setItem('_key', response['key']);
							window.location.assign("dashboard");
						}, 3000);
						
					}
				}else if(response && response['msg']){
					document.getElementById('registerfeedback').innerHTML = response['msg'];
					document.getElementById('registerfeedback').className = 'incsizespan_error center';
					document.getElementById('registerproceed').innerHTML = 'PROCEED';
					document.getElementById('registerproceed').removeAttribute('disabled');
				}else{
					document.getElementById('registerproceed').innerHTML = 'PROCEED';
					document.getElementById('registerproceed').removeAttribute('disabled');
					sendreport = true;
					sendmsg = localStorage.getItem('obj');					
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				if(sendreport === true){					
					sendReport('Register unknown error', sendmsg);
				}
				return false;
			}		
		
			if(localStorage.getItem("objname") && (localStorage.getItem("objname") === 'api' || localStorage.getItem("objname") === 'apiswitch'))
			{	
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response && response['status']){					
					document.getElementById('apifeedback').innerHTML = response['response'];
					document.getElementById('apifeedback').className = 'incsizespan_info center';
					document.getElementById('apiproceed').innerHTML = 'PROCEED';
					document.getElementById('apiproceed').removeAttribute('disabled');	
					if(response['response'] === 'Successfully Updated'){
						window.location.assign("dashboard");			
					}
				}else if(response && response['msg']){
					if(response['msg'] === 'Please Relogin' && !response['key']){
						localStorage.clear();
						window.location.assign("signin");
					}
					document.getElementById('apifeedback').innerHTML = response['msg'];
					document.getElementById('apifeedback').className = 'incsizespan_error center';
					document.getElementById('apiproceed').innerHTML = 'PROCEED';
					document.getElementById('apiproceed').removeAttribute('disabled');
				}else if(response){
					document.getElementById('apiproceed').innerHTML = 'PROCEED';
					document.getElementById('apiproceed').removeAttribute('disabled');
					sendreport = true;
					sendmsg = localStorage.getItem('obj');					
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				if(sendreport === true){
					sendReport('API unknown error', sendmsg);
				}
				return false;
			}
		
			if(localStorage.getItem("objname") && localStorage.getItem("objname") === 'apigen')
			{	
				if(response['key']){
					localStorage.setItem('_key', response['key']);
				}
				if(response['response'] === 'Successfully Updated'){
					localStorage.setItem("obj", "");
					localStorage.setItem("objname", "");
					window.location.assign("dashboard");		
				}
				if(response && response['msg']){
					alert(response['msg']);
				}
				localStorage.setItem("obj", "");
				localStorage.setItem("objname", "");
				websocket.close();
				return false;
			}
		
		}		
		websocket.close();
		return false;
	}
		
	function doSend(message)
	{
		if(message && message !== ''){			
			try{
				if(!websocket.send(message)){
					//throw new Error("Endopay! Trading Crypto");
				}
			}catch(e){
				if(e.message === "Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.")
				{
					//console.log(message);
				}
			}
		}
		return false;
	}
	
	function sess() 
	{
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	}
	
	function gotoSignin(){
		window.location.assign('/signin');
	}
	
	if(order === '')
	{	
		return [		
		(			
			<Router key="ap2">			
				{<div className={maincontainer}  id="maincontainer" key="ap21">		
					<Routes>
					
					{params.length < 1 && (<Route path='/' element={user ? (<Redirect to="/dashboard" />) : (<FullLoader/>)} exact></Route>)}
					
					{params.length > 0 && (<Route path='/' element={user ? (<Redirect to="/dashboard" />) : (<TradeView onPostSell={postSell} onPostBuy={postBuy} onPostDeposit={postDeposit} alertMessage={msgAlert} nameLookUp={nameLookUp} fetchBankName={fetchBankName} />)} exact></Route>)}
					
					{params.length < 1 && (<Route path='/signin' element={<FullLoader/>}></Route>)}
					
					{params.length > 0 && (<Route path='/signin' element={user ? (<Redirect to="/dashboard" />) : (<LoginView onPostLogin={postLogin} alertMessage={msgAlert} />)} ></Route>)}
					
					<Route path='/signup' element={<RegisterView onPostRegister={postRegister} thisrefCode={refCode} />} ></Route>				
					
					<Route path='/fpassword' element={<ForgotPasswordView onPostForgotpassword={postForgotPassword} />} ></Route>
					
					<Route path='/getactive' element={<ActivationLinkView onPostGetActivationLink={postGetActive} />} ></Route>
					
					<Route path='/rates' element={<RateView />}></Route>
					
					<Route path='/terms' element={<TermsView />} ></Route>
					
					<Route path='/privacy' element={<PrivacyView />} ></Route>
					
					<Route path='/affiliate' element={<AffiliateView />} ></Route>
					
					<Route path='/contactus' element={<ContactView />} ></Route>
					
					<Route path='/apidoc' element={<ApiDocView />} ></Route>

					<Route path='/faq' element={<FaqView />} ></Route>
					
					<Route path='/aml' element={<AmlView />} ></Route>			
					
					<Route path='/dashboard' element={!user ? (<Redirect to="/signin" />) : (<DashboardView onPostLogout={postLogout} onPostSell={postSell} onPostBuy={postBuy} onPostDeposit={postDeposit} onPostWithdraw={postWithdraw} onPostQuickSellBTC={postQuickSellBTC} onPostQuickSellUSDT={postQuickSellUSDT}  onPostQuickSellALT={postQuickSellALT} onPostSendBTC={postSendBTC} onPostSendUSDT={postSendUSDT} onPostSendALT={postSendALT} alertMessage={msgAlert} nameLookUp={nameLookUp} fetchBankName={fetchBankName} />)} ></Route>
					
					<Route path='/history' element={!user ? (<Redirect to="/" />) : (<HistoryView />)} ></Route>
					
					<Route path='/cpass' element={!user ? (<Redirect to="/" />) : (<ChangepassView onPostPassword={postPassword} />)}></Route>
					
					<Route path='/ccode' element={!user ? (<Redirect to="/" />) : (<ConfirmationView onPostConfirmation={postConfirmation} onPostReset={postReset} />)}></Route>
					
					<Route path='/bank' element={!user ? (<Redirect to="/" />) : (<BankView onPostBank={postBank} onPostLogout={postLogout}/>)} ></Route>
					
					<Route path='/phone' element={!user ? (<Redirect to="/" />) : (<PhoneView onPostPhone={postPhone} onPostLogout={postLogout}/>) } ></Route>

					<Route path='/apisetting' element={!user ? (<Redirect to="/" />) : (<ApiSettingView onPostIP={postIP} onPostApiGen={postApiGen} onPostApiSwitch={postApiSwitch} />) } ></Route>
					</Routes>
					
					{params.length > 0 ? (<Params params={params} />) : ('')}
				</div>}
				{<div className="wave" key="ap22">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="xMidYMid slice">
					<path fill="#0099ff" fillOpacity="1" d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
					</svg>
					<div></div>			
				</div>				
				}
				{<Footer />}
				{<div id="toast" key="ap23">Hello world</div>}
			</Router>
		),(
			
			<div id="headbar" className="cb-position-bottom" key="ap3">
				<div className="headbar-container">
					<div id="cb-message" className="cb-content-part"><font color="#40e0d0">ENDOPAY</font> <br/> Sell and Buy. Best Rate.</div>
					<div id="cb-steps" className="cb-content-part">
						<div id="cb-step-1" className="cb-step"><span className="cb-step-text"><span className="cb-step-name"dangerouslySetInnerHTML={{__html:`` || `${notify}`}} style={{textAlign:'center'}}></span><span className="cb-step-desc" style={{textAlign:'center'}}>WHATSAPP/TELEGRAM: +2349075523145</span></span></div>
					</div>
					<div id="cb-data" className="cb-content-part">
						<span id="cb-confirmed-number">
							<span className="cb-number" id="cb-confirmed">
						
							</span>
							<span className="cb-number-desc">
								<>
									<label id="themedirswitch" style={{left:'calc(50% - 40px)', position: 'relative'}}>
									<span className="switch__text"></span>
									<input className="switch__checkbox" type="checkbox" name="themedir" id="themedir" onClick={window.changeTheme} tabIndex="1" />			
									<input type="hidden" name="themeunit" id="themeunit"/>
									<span className="switch__elements" aria-hidden="true">
										<span className="switch__inner">
											<span className="switch__left" id="themedirdisplay"></span>
											<span className="switch__right"></span>
											<span className="switch__middle"></span>
										</span>
									</span>
									</label>
								</>
							</span>
						</span>
						{user ? (<span id="cb-recovered-number" onClick={postLogout} alt="LogOut" title="LogOut" style={{cursor: 'pointer'}}><span className="cb-number" id="cb-recovered">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="#23527c"><path fillRule="evenodd" d="M4 0a1 1 0 010 2H2v12h2a1 1 0 110 2H1a1 1 0 01-1-1V1a1 1 0 011-1h3zm5.707 1.293l6 6a.997.997 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L12.586 9H6a1 1 0 110-2h6.586L8.293 2.707a1 1 0 011.414-1.414z" clipRule="evenodd"></path></svg></span><span className="cb-number-desc"></span></span>) : (<span id="cb-recovered-number"><span className="cb-number" id="cb-recovered"><span onClick={gotoSignin} alt="LogIn" style={{cursor: 'pointer'}}>LOGIN</span></span><span className="cb-number-desc"></span></span>)}
					</div>
				</div>				
			</div>
		),(		
		<div id="marquee" className="marquee cb-position-bottom" key="ap4">
			<div id="marquee__inner" className="cb-content-part marquee__inner">
				<div id="marquee-li-0" className="cb-step cbstepper"><span className="cb-step-icon"><img src={PMLogo} alt="PM" width="30" height="40"/></span><span className="cb-step-text"><span className="cb-step-name">PM<font className="pricetext"></font></span><span className="cb-step-desc"><font color="red" size="4"><img src={ArrowDown} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${sellpm}`}</font> | <font color="green" size="4"><img src={ArrowUp} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${buypm}`}</font></span></span></div>
				<div id="marquee-li-1" className="cb-step cbstepper"><span className="cb-step-icon"><img src={USDTLogo} alt="USDT" width="30" height="40"/></span><span className="cb-step-text"><span className="cb-step-name">USDT</span><span className="cb-step-desc"><font color="red" size="4"><img src={ArrowDown} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${sellusdt}`}</font> | <font color="green" size="4"><img src={ArrowUp} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${buyusdt}`}</font></span></span></div>
				<div id="marquee-li-2" className="cb-step cbstepper"><span className="cb-step-icon"><img src={BTCLogo} alt="BTC" width="30" height="40"/></span><span className="cb-step-text"><span className="cb-step-name">BTC - <font className="pricetext"> ${`${usdbtc}` || `${0.00}`}</font></span><span className="cb-step-desc"><font color="red" size="4"><img src={ArrowDown} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${sellbtc}`}</font> | <font color="green" size="4"><img src={ArrowUp} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${buybtc}`}</font></span></span></div>
				<div id="marquee-li-3" className="cb-step cbstepper"><span className="cb-step-icon"><img src={ETHLogo} alt="ETH" width="30" height="40"/></span><span className="cb-step-text"><span className="cb-step-name">ETH - <font className="pricetext"> ${`${usdeth}` || `${0.00}`}</font></span><span className="cb-step-desc"><font color="red" size="4"><img src={ArrowDown} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${selleth}`}</font> | <font color="green" size="4"><img src={ArrowUp} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${buyeth}`}</font></span></span></div>
				<div id="marquee-li-4" className="cb-step cbstepper"><span className="cb-step-icon"><img src={LTCLogo} alt="LTC" width="30" height="40"/></span><span className="cb-step-text"><span className="cb-step-name">LTC - <font className="pricetext"> ${`${usdltc}` || `${0.00}`}</font></span><span className="cb-step-desc" id="ltcrate"><font color="red" size="4"><img src={ArrowDown} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${sellltc}`}</font> | <font color="green" size="4"><img src={ArrowUp} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${buyltc}`}</font> </span><span className="cb-step-desc hide" id="ltcprice">${`${usdltc}` || `${0.00}`}</span></span></div>
				<div id="marquee-li-5" className="cb-step cbstepper"><span className="cb-step-icon"><img src={BCHLogo} alt="BCH" width="30" height="40"/></span><span className="cb-step-text"><span className="cb-step-name">BCH - <font className="pricetext"> ${`${usdbch}` || `${0.00}`}</font></span><span className="cb-step-desc"><font color="red" size="4"><img src={ArrowDown} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${sellbch}`}</font> | <font color="green" size="4"><img src={ArrowUp} width="20" height="20" alt="" style={{marginBottom: '-3px'}}/> &#8358;{`${buybch}`}</font></span></span></div>
			</div>
		</div>
		)
		];
	}
	else
	{
		return [
			(<Router key="ap2">			
				{<div className="maincontain"  id="maincontain" key="ap21">	
					{order ? (<SiteOrder orderId={order} />) : ('Invalid Order')}
				</div>}
				{<div className="wave" key="ap22">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="xMidYMid slice">
					<path fill="#0099ff" fillOpacity="1" d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
					</svg>
					<div></div>
				</div>
				}
			</Router>),
			(<div id="noticebar" className={`${noticebar}` || `cb-position-bottom`} key="ap4"><div className="noticebar-container"><div id="cb-message" className="cb-content-part">NOTICE: </div><div id="cb-steps" className="cb-content-part"dangerouslySetInnerHTML={{__html:`For Crypto Sale - You will receive value subjected to final rate on network confirmation` || ``}}></div></div></div>)
		]		
		
	}
};
export default App;