import { Link } from 'react-router-dom';
import DashboardNavBar from './DashboardNavBar';

const NavBar = () => {
	//const [user, setUser] = useState(localStorage.getItem('useremail'));
	const user = localStorage.getItem('useremail');
	/*
	const postHome = (e) => {
		setUser(user);
		window.location.assign("/");
	}*/
	
	if(user){
		return (<DashboardNavBar />)
	}else{
		return (
			<>
				<div className="Context__header">
					<Link to="/" style={homepage}>
						<svg width="188" height="40" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
					 <g className="layer">
					  <g fill="#000000" id="svg_1" transform="translate(0, 160) scale(0.1, -0.1)">
					   <path d="m132.01636,1565.2004c-38.64033,-21.39013 -50.23244,-29.77841 -69.0006,-49.49088c-10.48809,-10.90477 -18.76816,-20.97071 -18.76816,-23.06778c0,-1.67766 -3.86403,-8.38828 -8.83208,-15.51833c-21.52818,-31.45607 -18.21616,-100.23999 6.62405,-140.08434c18.76816,-30.61723 73.41664,-71.30041 115.36899,-86.39932c29.25625,-10.48536 92.7368,-20.55129 99.36085,-15.51833c1.10402,1.25825 -7.17606,6.71063 -19.32017,13.00184c-26.49623,13.42125 -44.16039,31.45607 -68.44859,69.62276c-25.39222,40.68318 -33.12029,76.33338 -29.25625,137.98727c2.76002,44.4579 10.48809,75.07514 25.94423,106.11179c8.83208,16.77656 -6.07205,18.45422 -33.67229,3.35532l0,0l0,0.00001l0.00001,-0.00001l0.00001,0z" id="svg_3"/>
					   <path d="m285.47368,1552.19856c-13.80012,-10.48536 -33.12029,-30.61723 -43.05637,-44.87732c-15.45613,-22.22895 -18.76816,-31.03665 -20.42418,-62.49271c-2.20802,-30.19782 -1.10402,-37.32787 6.07205,-39.42493c12.69611,-3.35532 94.39281,33.97255 126.96109,58.71799c16.00814,11.7436 33.67229,28.52016 39.19233,36.90845l11.04009,15.51833l-29.80826,20.97071c-16.56014,11.7436 -34.2243,22.64836 -39.74434,23.90661c-5.52005,1.25825 -9.93609,4.19414 -9.93609,6.29121c0,8.8077 -17.11215,2.51648 -40.29635,-15.51833l0,0l0,-0.00001l0.00002,-0.00001l0.00001,0.00001z" id="svg_4"/>
					   <path d="m439.48302,1467.89629c-11.5921,-26.42309 -105.43291,-81.78577 -163.94541,-96.04585c-14.90412,-3.77473 -28.70425,-8.38828 -30.36026,-10.48536c-1.65602,-2.09707 6.62405,-17.19598 18.21616,-33.97255c21.52818,-31.45607 73.41664,-74.65572 89.42478,-74.65572c4.96804,0 21.52818,8.38828 36.43232,18.45422c64.03255,42.36083 91.08078,119.11363 64.03255,182.8646c-8.28007,19.71247 -9.93609,21.80954 -13.80012,13.84067l0,0l-0.00001,0l-0.00001,-0.00001z" id="svg_5"/>
					  </g>
					  <text fill="#000000" fontFamily="Montserrat" fontSize="49" fontWeight="bold" id="svg_2" stroke="#000000" strokeWidth="0" textAnchor="end" transform="matrix(0.607299, 0, 0, 0.499256, 0.151, 16.6683)" x="250.82191" y="23.05614">HOME</text>
					 </g>
					</svg></Link>
				</div>
				<p className="Context__copy">
					<Link to="/signin" style={lineThree} id="signintab">LOGIN</Link><br/>
					<Link to="/signup" style={lineThree} id="signuptab">CREATE ACCOUNT</Link><br/>
					<Link to="/fpassword" style={lineThree} id="fpasswordtab">RESET PASSWORD</Link><br/>
					<Link to="/getactive" style={lineThree} id="getactivetab">ACTIVATE ACCOUNT</Link><br/>
					<Link to="/rates" style={lineThree} id="ratetab">RATES</Link>			
				</p>
				
			</>
		)
	}
}

const lineThree = {
   fontSize: '1em',
   lineHeight: '40px',
   fontWeight: '500'
}

const homepage = {
   fontSize: '23px',
   lineHeight: '70px',
   fontWeight: '700',
   marginTop: '-50px'
}

export default NavBar;

/*<Link to="/"><img className="Context__header-icon Context__Header-icon--default" src={EndopayLogo} alt="Endopay Logo" /></Link>*/