import { useState } from 'react';
import { Link } from 'react-router-dom';

const DashboardNavBar = () => {
	const [thisclass, setClass] = useState(localStorage.getItem('class'));
	
	const onPostLogout = (e) => {
		if(window.confirm('Do you want to logout?')){
			setClass('');
			localStorage.setItem('useremail', '');
			localStorage.clear();
			localStorage.setItem('maincontainer', 'maincontainer');
			window.location.assign("/");
		}
	}	
	
	return (
		<>
			<div className="Context__header">
				<Link to="/dashboard" style={homepage}>HOME</Link>
			</div>
			<p className="Context__copy">
				<span onMouseDown={onPostLogout} style={lineRed}>
					LOGOUT
				</span><br/>
				<Link to="/history" style={lineThree} id="historytab">TRADE HISTORY</Link><br/>
				<Link to="/bank" style={lineThree} id="banktab">ADD BANK ACCOUNT</Link><br/>
				<Link to="/phone" style={lineThree} id="phonetab">UPDATE PHONE</Link><br/>
				<Link to="/ccode" style={lineThree} id="ccodetab">CONFIRMATION CODE</Link><br/>
				<Link to="/cpass" style={lineThree} id="cpasstab">CHANGE PASSWORD</Link><br/>
				{thisclass === '1' ? (<Link to="/apisetting" style={lineThree} id="apisettingtab">API SETTING</Link>):('')}
			</p>
		</>
	)
}

const lineThree = {
   fontSize: '1em',
   lineHeight: '40px',
   fontWeight: '500'
}

const lineRed = {
   fontSize: '1em',
   color: 'red',
   lineHeight: '45px',
   fontWeight: '700'
}

const homepage = {
   fontSize: '23px',
   lineHeight: '70px',
   fontWeight: '700',
   marginTop: '-50px'
}


export default DashboardNavBar;
