import { useEffect } from 'react';
import NavBar from './NavBar';

const FaqView = () => {
	
	useEffect(() => {
		
		document.getElementById('maincontainer').className = 'maincontainer2';
	},[]);
	
	
	return [
		(<div className="container__canvas" style={rectStyleWrap} key="1">
			<div className="Context">
				<NavBar />
			</div>
		</div>),
		(<div className="[ stack ] [ container__controls ]" key="2">		
			<div className="container__control--switch">
				<button className="switch-btn">
					<span className="switch-btnn__marker" id="switch-btnn__marker"></span>
					<span className="center-xy switch-btn__label" ></span>
					<span className="center-xy switch-btn__label" id="4r1" >FAQ</span>
					<span className="center-xy switch-btn__label" ></span>
				</button>				
			</div>		
			<div className="stack disshow" id="faqdisplay">
				<div style={center}>
					<h4 style={lineTwo} >FAQ</h4>
				</div>	
				<div className="container__control--input" style={{lineHeight: '2rem'}}>		

<b>FAQ</b><br/><br/>

			</div>
		</div>
	</div>)
	]
}
 
const lineTwo = {
   fontSize: '15px',
   color: '#23527c',
   lineHeight: '45px',
   fontWeight: '700'
}

const center = {
   textAlign: 'center'   
}

const rectStyleWrap = {
   minWidth: '250px',
   gridColumn: 1
}

export default FaqView;