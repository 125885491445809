const Dashboardparam = ({ dashboardparam }) => {
	return (
		<>
			<input type="hidden" id="loggedemail" value={dashboardparam.email || ''} />	
		</>
	)
}

export default Dashboardparam;

