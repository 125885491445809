//DashboardView
//BankView
//TradeView

const bankOptions = [
	{ label: 'Select Bank', value: '', options: [] },
	{
		label: 'NIGERIAN BANKS',
		options: [
			{ label: 'Access Bank', value: 'Access Bank|063-044|ABP' },
			{ label: 'Citi Bank Nigeria', value: 'Citi Bank Nigeria|023|CITI' },
			{ label: 'Ecobank', value: 'Ecobank|050|ECO' },
			{ label: 'Fidelity Bank', value: 'Fidelity Bank|070|FBP' },
			{ label: 'First Bank of Nigeria', value: 'First Bank|011|FBN' },
			{ label: 'First City Monument Bank', value: 'First City Monument Bank|214|FCMB' },
			{ label: 'Guaranty Trust Bank', value: 'Guaranty Trust Bank|058|GTB' },
			{ label: 'Heritage Bank', value: 'Heritage Bank|030|HTG' },
			{ label: 'Keystone Bank', value: 'Keystone Bank|082|KEYSTONE' },
			{ label: 'Polaris Bank', value: 'Polaris Bank|076|POLARIS' },
			{ label: 'Rubies MFB', value: 'Rubies MFB|090175|RUBIES' },
			{ label: 'Stanbic IBTC Bank', value: 'Stanbic IBTC Bank|221|STANBIC' },
			{ label: 'Standard Chartered Bank', value: 'Standard Chartered Bank|068|SCB' },
			{ label: 'Sterling Bank', value: 'Sterling Bank|232|SBP' },
			{ label: 'Union Bank', value: 'Union Bank|032|UBN' },
			{ label: 'United Bank of Africa', value: 'United Bank of Africa|033|UBA' },
			{ label: 'Unity Bank', value: 'Unity Bank|215|UNITY' },
			{ label: 'Wema Bank', value: 'Wema Bank|035|WEMA' },
			{ label: 'Zenith Bank', value: 'Zenith Bank|057|ZIB' },				
			{ label: 'FinaTrust MFB', value: 'FinaTrust MFB|608-090111|FinaTrust' },
			{ label: 'FSDH Merchant Bank', value: 'FSDH Merchant Bank|400001|FSDH' },
			{ label: 'Globus Bank', value: 'Globus Bank|784-103|Globus' },
			{ label: 'Jaiz Bank', value: 'Jaiz Bank|301|Jaiz' },
			{ label: 'Kuda MFB', value: 'Kuda MFB|090267-50211|Kuda' },
			{ label: 'Paga', value: 'Paga|100002-327|Paga' },
			{ label: 'Palm Pay', value: 'Palm Pay|100033|Palm' },
			{ label: 'Opay Paycom', value: 'Opay Paycom|305|Opay' },
			{ label: 'Providus Bank', value: 'Providus Bank|101|Providus' },
			{ label: 'Rand Merchant Bank', value: 'Rand Merchant Bank|502|Rand' },
			{ label: 'SunTrust Bank', value: 'SunTrust Bank|100|SunTrust' },
			{ label: 'TCF MFB', value: 'TCF MFB|090115-51211|TCF' },
			{ label: 'VFD MFB', value: 'VFD MFB|090110-566|VFD' },
			{ label: 'Carbon', value: 'Carbon|940|CARBON' },
			{ label: 'AB MFB', value: 'AB MFB|090270|AB' },
			{ label: 'Abbey Mortgage Bank', value: 'Abbey Mortgage Bank|070010|ABBEY' },
			{ label: 'Accion MFB', value: 'Accion MFB|090134|ACCION' },
			{ label: 'KongaPay', value: 'KongaPay|939|KONGA' },
			{ label: 'Titan Trust Bank', value: 'Titan Trust Bank|102|TITAN' },
			{ label: 'Taj Bank', value: 'Taj Bank|302|TAJ' },
			{ label: 'Lapo MFB', value: 'Lapo MFB|090177|LAPO' },
			{ label: 'Legend MFB', value: 'Legend MFB|090372|LEGEND' },
			{ label: 'Enterprise Bank', value: 'Enterprise|084|ENTERPISE' },
			{ label: 'Haggai Mortage Bank', value: 'Haggai Mortgage Bank|070017|HAGGAI' },
			{ label: 'Moniepoint MFB', value: 'Moniepoint MFB|50515|MONIEPOINT' },
			{ label: 'Mayfair MFB', value: 'Mayfair MFB|090321|MAYFAIR' },
			// Add more banks as needed
		],
	},
];

// Sort the options alphabetically
bankOptions[1].options.sort((a, b) => a.label.localeCompare(b.label));
	
export default bankOptions;
