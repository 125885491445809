const Param = ({ param }) => {
/* import queryString from 'query-string';
console.log(location.search);
// Output: '?id=1&type=cornPizza'

const parsed = queryString.parse(location.search);
console.log(parsed);
// Output: {id: "1", type: "cornPizza"}

console.log(location.hash); */	
//alert(param.length)	
	return (
		<>
			<input type="hidden" id="sellpmrate" value={param.pmsell} />
			<input type="hidden" id="sellbtcrate" value={param.btcsell} />
			<input type="hidden" id="sellusdtrate" value={param.usdtsell} />
			<input type="hidden" id="sellaltrate" value={param.bchsell} />
			<input type="hidden" id="sellaltmax" value={param.maxbchsell} />
			<input type="hidden" id="sellbtcmax" value={param.maxbtcsell} />
			<input type="hidden" id="sellusdtmax" value={param.maxusdtsell} />
			<input type="hidden" id="sellpmmax" value={param.maxpmsell} />
			<input type="hidden" id="sellaltmin" value={param.minbchsell} />
			<input type="hidden" id="sellbtcmin" value={param.minbtcsell} />
			<input type="hidden" id="sellusdtmin" value={param.minusdtsell} />
			<input type="hidden" id="sellpmmin" value={param.minpmsell} />
			<input type="hidden" id="sellreg" value="0"/>
			<input type="hidden" id="sellacct" value="0"/>
			<input type="hidden" id="sellaccount" value=""/>
			<input type="hidden" id="sellaccountname" value=""/>
			<input type="hidden" id="selltotal" value=""/>
			<input type="hidden" id="sellamt" value=""/>
			<input type="hidden" id="sellcur" value=""/>
			<input type="hidden" id="selltype" value=""/>			
			
			<input type="hidden" id="addedrate" value={param.cardadded}/>
			<input type="hidden" id="buyfee" value={param.paystackfee}/>
			<input type="hidden" id="buybankfee" value={param.bankfee}/>
			<input type="hidden" id="buypmrate" value={param.pmbuy}/>
			<input type="hidden" id="buybtcrate" value={param.btcbuy}/>
			<input type="hidden" id="buyusdtrate" value={param.usdtbuy}/>
			<input type="hidden" id="buygiftrate" value={param.buycard}/>
			<input type="hidden" id="buyaltrate" value={param.bchbuy}/>
			<input type="hidden" id="buyreg" value="0"/>
			<input type="hidden" id="buyacct" value="0"/>			
			<input type="hidden" id="buyaltmax" value={param.maxbchbuy} />
			<input type="hidden" id="buybtcmax" value={param.maxbtcbuy} />
			<input type="hidden" id="buyusdtmax" value={param.maxusdtbuy} />
			<input type="hidden" id="buypmmax" value={param.maxpmbuy} />
			<input type="hidden" id="buygiftmax" value={param.maxbuycard} />
			<input type="hidden" id="buyaltmin" value={param.minbchbuy} />
			<input type="hidden" id="buybtcmin" value={param.minbtcbuy} />
			<input type="hidden" id="buyusdtmin" value={param.minusdtbuy} />
			<input type="hidden" id="buypmmin" value={param.minpmbuy} />
			<input type="hidden" id="buygiftmin" value={param.minbuycard} />
			<input type="hidden" id="buyaccountname" value=""/>
			<input type="hidden" id="buytotal" value=""/>
			<input type="hidden" id="buyamt" value=""/>
			<input type="hidden" id="buymtd" value="" />
			<input type="hidden" id="buycur" value="" />
			<input type="hidden" id="buytype" value=""/>
									
			<input type="hidden" id="depositpmrate" value={param.pmsell} />
			<input type="hidden" id="depositbtcrate" value={param.btcsell} />
			<input type="hidden" id="depositusdtrate" value={param.usdtsell} />
			<input type="hidden" id="depositbtcenrate" value={param.btcsell} />
			<input type="hidden" id="depositaltrate" value={param.bchsell} />
			<input type="hidden" id="depositbtadded" value={param.btdepositadded} />
			<input type="hidden" id="depositccrate" value={param.btdepositrate} />
			<input type="hidden" id="depositbtrate" value={param.btdepositrate} />
			<input type="hidden" id="depositfee" value={param.paystackfee} />
			<input type="hidden" id="depositbankfee" value={param.bankfee} />
			<input type="hidden" id="depositaltmax" value={param.maxbchsell} />
			<input type="hidden" id="depositbtcmax" value={param.maxbtcsell} />
			<input type="hidden" id="depositusdtmax" value={param.maxusdtsell} />
			<input type="hidden" id="depositbtcenmax" value={param.maxbtcsell} />
			<input type="hidden" id="depositpmmax" value={param.maxpmsell} />
			<input type="hidden" id="depositaltmin" value={param.minbchsell} />
			<input type="hidden" id="depositbtcenmin" value={param.minbtcsell} />
			<input type="hidden" id="depositbtcmin" value={param.minbtcsell} />
			<input type="hidden" id="depositusdtmin" value={param.minusdtsell} />
			<input type="hidden" id="depositpmmin" value={param.minpmsell} />
			<input type="hidden" id="depositccmin" value={param.minwithdraw} />
			<input type="hidden" id="depositbtmin" value={param.minwithdraw} />
			<input type="hidden" id="depositccmax" value={param.medwithdraw} />
			<input type="hidden" id="depositbtmax" value={param.maxwithdraw} />
			<input type="hidden" id="depositreg" value="0" />
			<input type="hidden" id="depositacct" value="0" />
			<input type="hidden" id="depositaccount" value="" />
			<input type="hidden" id="depositaccountname" value="" />
			<input type="hidden" id="deposittotal" value="" />
			<input type="hidden" id="depositamt" value="" />
			<input type="hidden" id="depositcur" value="" />
			<input type="hidden" id="deposittype" value="" />	
			
			<input type="hidden" id="usdbtc" value={param.usdbtc} />
			<input type="hidden" id="usdbtcen" value={param.usdbtc} />
			<input type="hidden" id="usdeth" value={param.usdeth} />
			<input type="hidden" id="usdbch" value={param.usdbch} />
			<input type="hidden" id="usdltc" value={param.usdltc} />
			<input type="hidden" id="usdusdt" value={param.usdusdt} />
			<input type="hidden" id="btenbtrate" value={param.usdbtc} />	
			<input type="hidden" id="usdtenusdtrate" value={param.usdusdt} />	
			<input type="hidden" id="usdalt" value="0.00173079" />
			<input type="hidden" id="isreg" value="1" />
			<input type="hidden" id="isnew" value="0" />			
			<input type="hidden" id="ipo" value={param.ipo} />			
			<input type="hidden" id="a" value="" />
			<input type="hidden" id="b" value="" />
			<input type="hidden" id="c" value="" />
			<input type="hidden" id="d" value="" />			
			
			<input type="hidden" id="quicksellbtcamt" value="" />
			<input type="hidden" id="quicksellbchamt" value="" />
			<input type="hidden" id="quicksellethamt" value="" />
			<input type="hidden" id="quicksellltcamt" value="" />
			<input type="hidden" id="quicksellusdtamt" value="" />
			
			<input type="hidden" id="sendbtcnetworkfee" value=""/>
			<input type="hidden" id="sendbtcnetworksize" value=""/>			
			
			<input type="hidden" id="sendbtctype" value=""/>
			<input type="hidden" id="sendbtcreg" value="0"/>
			<input type="hidden" id="sendbtcacct" value="0"/>
			<input type="hidden" id="sendbtcaccountname" value="Bitcoin"/>
			<input type="hidden" id="sendbtcamt" value=""/>
			<input type="hidden" id="enbdrate" value="1"/>
			<input type="hidden" id="withdrawcurrencydir" value="NGN"/>
			<input type="hidden" id="withdrawfee" value={param.withdrawfee} />
			<input type="hidden" id="withdrawlimitfee" value={param.maxwithdraw} />
			<input type="hidden" id="withdrawtype" value=""/>			
			<input type="hidden" id="withdrawreg" value="0"/>
			<input type="hidden" id="withdrawacct" value="0"/>
			<input type="hidden" id="withdrawamt" value=""/>
			<input type="hidden" id="bkey" value={param.bkey}/>
			
		</>
	)
}

export default Param;


/*

<input type="hidden" id="sendbtcnetworkfee" value=""/>
															<input type="hidden" id="sendbtcnetworksize" value=""/>
															
															<input type="hidden" id="btenbtrate" value="<?=$usdbtc?>"/>
															<input type="hidden" id="sendbtctype" value=""/>
															<input type="hidden" id="sendbtcreg" value="0"/>
															<input type="hidden" id="sendbtcacct" value="0"/>
															<input type="hidden" id="sendbtcaccountname" value="Bitcoin"/>
															<input type="hidden" id="sendbtcamt" value=""/>
															<input type="hidden" id="sendbtcemail" value="<?=$sessionemail?>"/>
															<input type="hidden" id="sendbtcunit" value="USD"/>
															
															<input type="hidden" id="enbdrate" value="1"/>
															<input type="hidden" id="withdrawfee" value="<?=$mywithdrawfee?>"/>
															<input type="hidden" id="withdrawlimitfee" value="<?=$mediumwithdraw?>"/>
															<input type="hidden" id="withdrawbank" value="<?=$mybankname?>"/>
															<input type="hidden" id="withdrawbankaccount" value="<?=$mybankacct?>"/>
															<input type="hidden" id="withdrawtype" value=""/>
															<input type="hidden" id="withdrawreg" value="0"/>
															<input type="hidden" id="withdrawacct" value="0"/>
															<input type="hidden" id="withdrawaccount" value=""/>
															<input type="hidden" id="withdrawaccountname" value=""/>
															<input type="hidden" id="withdrawamt" value=""/>
															<input type="hidden" id="withdrawemail" value="<?=$sessionemail?>"/>
*/