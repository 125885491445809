import { Link } from 'react-router-dom';

const DashboardNavBar2 = () => {
	
	const onPostLogout = (e) => {
		if(window.confirm('Do you want to logout?')){
			localStorage.setItem('useremail', '');
			localStorage.clear();
			localStorage.setItem('maincontainer', 'maincontainer');
			window.location.assign("/");
		}
	}	
	
	return (
		<>
			<div className="Context__header">
				<Link to="/bank" style={homepage}>MANAGE ACCOUNT</Link>
			</div>
			<p className="Context__copy">
				<span onMouseDown={onPostLogout} style={lineRed}>
					LOGOUT
				</span><br/>
				<Link to="/bank" style={lineThree} id="banktab">ADD BANK ACCOUNT</Link><br/>
				<Link to="/ccode" style={lineThree} id="ccodetab">CONFIRMATION CODE</Link>
			</p>
		</>
	)
}

const lineThree = {
   fontSize: '1em',
   lineHeight: '40px',
   fontWeight: '500'
}

const lineRed = {
   fontSize: '1em',
   color: 'red',
   lineHeight: '45px',
   fontWeight: '700',
   cursor: 'pointer'
}

const homepage = {
   fontSize: '23px',
   lineHeight: '70px',
   fontWeight: '700',
   marginTop: '-50px'
}


export default DashboardNavBar2;
