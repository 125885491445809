import Param from './Param';

const Params = ({params}) => {	
	
	return (
		<>
			{params.map((param, index) => (
				<Param key={index} param={param} />
			))}
		</>
	)
};

export default Params;
