import { useEffect } from 'react';
import NavBar from './NavBar';


const ActivationLinkView = ({ onPostGetActivationLink }) => {
	
	useEffect(() => {
		document.getElementById('maincontainer').className = 'maincontainer2';
		document.getElementById('getactivetab').style.color = '#23527C';
		document.getElementById('getactivetab').style.fontWeight = '700';
		document.getElementById('getactivetab').style.borderBottom = '1px solid #23527C';
	},[]);
	
	const onSubmitActivation = (e) => {
		//e.preventDefault();
		
		var email, msg, output, emailcode;
		email = document.getElementById('activationemail').value;
		
		if(!email){
			msg = 'Enter a valid email';
			document.getElementById('activationfeedback').innerHTML = msg;
			document.getElementById('activationfeedback').className = 'incsizespan_error center';
			return false;
		}
		
		if(!document.getElementById('activationemailcodewrap').classList.contains('hide')){
			emailcode = document.getElementById('activationemailcode').value;
			if(!emailcode){
				msg = 'Enter a valid email code';
				document.getElementById('activationfeedback').innerHTML = msg;
				document.getElementById('activationfeedback').className = 'incsizespan_error center';
				return false;
			}
		}		
		
		if(emailcode){
			output = {
				'rtype': 'getactive',
				'email': email,
				'code': emailcode			
			}
		}else{
			output = {
				'rtype': 'pregetactive',
				'email': email		
			}
		}

		onPostGetActivationLink(output);
	}
	
	return [
		(<div className="container__canvas" style={rectStyleWrap} key="1">
			<div className="Context">
				<NavBar></NavBar>
			</div>
		</div>),
		(<div className="[ stack ] [ container__controls ]" key="2">
		
			<div className="container__control--switch">
				<button className="switch-btn">
					<span className="switch-btnn__marker" id="switch-btnn__marker"></span>
					<span className="center-xy switch-btn__label" ></span>
					<span className="center-xy switch-btn__label" id="4r1" >GET ACTIVE</span>
					<span className="center-xy switch-btn__label" ></span>
				</button>
				
			</div>
		
			<div className="stack disshow" id="activationdisplay">
				<div style={center}>
					<h4 style={lineTwo} >ACTIVATE ACCOUNT</h4>
				</div>
				
				<div className="incsizespan_info center">
					REGISTERED EMAIL
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="email" name="activationemail" id="activationemail" placeholder="Email" />
				</div>
				
				
				<div className="container__control--input hide" id="activationemailcodewrap">
					<input className="text-input" type="text" id="activationemailcode" placeholder="Email Code" />
				</div>	
				
				<div className="incsizespan center" id="activationfeedback">
					
				</div>
				
				<div className="container__buttons">
					<button className="clipboard" id="activationproceed" onClick={onSubmitActivation}>GET CODE</button>
				</div>
			</div>			
		</div>)
	]
}


const lineTwo = {
   fontSize: '15px',
   color: '#23527c',
   lineHeight: '45px',
   fontWeight: '700'
}

const rectStyleWrap = {
   minWidth: '250px',
   gridColumn: 1
}

const center = {
   textAlign: 'center'   
}

export default ActivationLinkView;