import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="sitefooter">      
      <Link to="/faq">FAQ</Link> | <Link to="/terms">Terms</Link> | <Link to="/affiliate">Affiliate</Link> | <Link to="/aml">AML Policy</Link> | <Link to="/privacy">Privacy Policy</Link> | <Link to="/contactus">Contact</Link> | <Link to="/apidoc">API Documentation</Link>
      <p>Copyright &copy; {currentYear}</p>
    </footer>
  );
};

export default Footer;
