import { useEffect, useState } from 'react';
import NavBar from './NavBar';

const LoginView = ({ onPostLogin, alertMessage }) => {
	
	useEffect(() => {
		document.getElementById('maincontainer').className = 'maincontainer2';
		document.getElementById('signintab').style.color = '#23527C';
		document.getElementById('signintab').style.fontWeight = '700';
		document.getElementById('signintab').style.borderBottom = '1px solid #23527C';
	},[]);
	
	const [loginemail, setLoginEmail] = useState('');
	const [loginpassword, setLoginPassword] = useState('');
	const [loginswitch, setLoginSwitch] = useState('OFF');
	
	var output;
	
	const handleSwitchChange = (e) => {
		var target;
        target = e.target   
		setLoginSwitch(loginswitch)
        if(target.checked){
			document.getElementById('loginswitch').value = 'ON'
			document.getElementById('loginswitchdisplay').innerHTML = 'ON'
        }else{
			document.getElementById('loginswitch').value = 'OFF'
			document.getElementById('loginswitchdisplay').innerHTML = 'OFF'
        }       
    }
	
	const onSubmitLogin = (e) => {
		var email, pass, switchmode, emailcode, msg;
		
		email = document.getElementById('loginemail').value
		pass = document.getElementById('loginpassword').value
		document.getElementById('loginfeedback').innerHTML = ''
		document.getElementById('loginfeedback').className = 'incsizespan center'
		
		if(document.getElementById('loginswitch').checked){
			switchmode = '1'
		}else{
			switchmode = '0'
		}
		
		if(!email){
			msg = 'Please enter email'
			document.getElementById('loginfeedback').innerHTML = msg
			document.getElementById('loginfeedback').className = 'incsizespan_error center'
			return
		}
		else if(!pass)
		{
			msg = 'Please enter password'
			document.getElementById('loginfeedback').innerHTML = msg
			document.getElementById('loginfeedback').className = 'incsizespan_error center'
			return
		}
		else if(switchmode === '0')
		{
			msg = 'Turn Switch "ON"'
			document.getElementById('loginfeedback').innerHTML = msg
			document.getElementById('loginfeedback').className = 'incsizespan_error center'
			return
		}
		
		if(document.getElementById('loginemailcode')){
			emailcode = document.getElementById('loginemailcode').value
		}		
		
		if(emailcode){
			output = {
				'rtype': 'login',
				'login': email,
				'logincode': emailcode			
			}
		}else{
			output = {
				'rtype': 'prelogin',
				'email': email,
				'switch': switchmode,
				'pass': pass			
			}
		}
		onPostLogin(output)
	}
	
	return [
		(<div className="container__canvas" style={rectStyleWrap} key="1">
			<div className="Context">
				<NavBar />
			</div>
		</div>),
		(<div className="[ stack ] [ container__controls ]" key="2">
		
			<div className="container__control--switch">
				<button className="switch-btn">
					<span className="switch-btnn__marker" id="switch-btnn__marker"></span>
					<span className="center-xy switch-btn__label" ></span>
					<span className="center-xy switch-btn__label" id="4r1" >LOGIN HERE</span>
					<span className="center-xy switch-btn__label" ></span>
				</button>
				
			</div>
		
			<div className="stack disshow" id="logindisplay">
				<div style={center}>
					<h4 style={lineTwo} >SIGN IN</h4>
				</div>
				
				<div className="incsizespan_info center">
					ALL FIELDS REQUIRED
				</div>
				
				<div className="container__control--input">
					<input className="text-input" type="email" name="loginemail" id="loginemail" placeholder="Email Or Phone" value={loginemail} onChange={(e) => setLoginEmail(e.target.value)} />
				</div>

				<div className="container__control--input password fixpost">
					<input className="text-input" type="password" name="loginpassword" id="loginpassword" placeholder="Password" value={loginpassword} onChange={(e) => setLoginPassword(e.target.value)} autoComplete="new-password"/>
					<span className="action_password icon-eye"></span>
				</div>
				<label id="logindirswitch dishow">
					Put Switch "ON"
					<span className="switch__text"></span>
					<input className="switch__checkbox" type="checkbox" name="loginswitch" id="loginswitch" value={loginswitch} onChange={handleSwitchChange} tabIndex="1" />
					<span className="switch__elements" aria-hidden="true" style={{float: 'right', marginTop: '-5px'}}>
						<span className="switch__inner">
							<span className="switch__left" id="loginswitchdisplay"></span>
							<span className="switch__right">OFF</span>
							<span className="switch__middle"></span>
						</span>
					</span>
				</label>
				<br/>
				
				<div className="container__control--input hide" id="loginemailcodewrap">
					<input className="text-input" type="text" id="loginemailcode" placeholder="Email Code" maxLength="7"/>
				</div>	
				
				<div className="incsizespan center" id="loginfeedback">
					
				</div>				
				<div className="container__buttons">
					<button className="clipboard" id="loginproceed" onClick={onSubmitLogin}>GET CODE</button>
				</div>
			</div>			
		</div>)
	]
}
 
const lineTwo = {
   fontSize: '15px',
   color: '#23527c',
   lineHeight: '45px',
   fontWeight: '700'
}

const center = {
   textAlign: 'center'   
}

const rectStyleWrap = {
   minWidth: '250px',
   gridColumn: 1
}

export default LoginView;