import { useState, useEffect } from 'react';

const SiteOrder = ({ orderId }) => {
  var websocket, response, obj, wsUri = "wss://endopay.com:1060";

  const [order, setOrder] = useState('');
  const [orderdata, setOrderData] = useState([]);

  function doCheck() {
    endoSocket();
  }

  function endoSocket() {
		try{
			websocket = new WebSocket(wsUri);
			websocket.onopen = function (evt) { onOpen(evt) };
			websocket.onclose = function (evt) { onClose(evt) };
			websocket.onmessage = function (evt) { onMessage(evt) };
			websocket.onerror = function (evt) { onError(evt) };
		}catch (e) {
			//console.error('Error sending message:', e.message);
		}
  }

  function onOpen(evt) {
    obj = localStorage.getItem("obj2");
    if (obj) {
      doSend(obj);
    }
  }

  function onClose(evt) {
    //console.error('WebSocket connection closed:', evt);

    // Reconnect after a short delay (e.g., 3 seconds)
    setTimeout(() => {
			if (websocket.readyState !== WebSocket.OPEN) {
				console.log('onClose');
				endoSocket();
			}
    }, 30000);
  }
	
	
  function onError(evt) {
    //console.error('WebSocket error:', evt);

		if (websocket.readyState !== WebSocket.CLOSED) {
			setTimeout(() => {
				if (websocket.readyState !== WebSocket.OPEN) {
					console.log('onError');
					endoSocket();
				}
			}, 30000);
		}
  }

  function onMessage(evt) {
    response = evt.data;
    if (JSON.parse(response)) {
      response = JSON.parse(response);
      if (localStorage.getItem("objname2") && localStorage.getItem("objname2") === 'orderlookup') {
        setOrderData(response);
        if (response['msg']) {
          alert(response['msg']);
        } else {
          setTimeout(function () {
            if (document.getElementById('thisorder')) {
              var thisorder = document.getElementById('thisorder').value;
              if (localStorage.getItem('thisorder') && localStorage.getItem('thisorder') === thisorder) {

              } else if (thisorder && thisorder !== 'undefined') {
                localStorage.setItem('thisorder', thisorder);
              } else {
                websocket.close();
                window.location.reload();
              }
              if (response['state'] !== 'pending') {
                websocket.close();
              }
            } else {
              //Permission like error
              alert("Order does exist or cancelled");
              window.location.assign("/dashboard");
            }
          }, 500);
        }
      }

    } else {
      websocket.close();
      return false;
    }
  }
  
	function doSend(message) {
		if (message && message !== '') {
			try {
				if (websocket && websocket.readyState === WebSocket.OPEN) {	
					websocket.send(message);
				} else {
					//throw new Error("WebSocket is not in the OPEN state");
				}
			} catch (e) {
				//console.error('Error sending message:', e.message);
			}
		}
		return false;
	}


  useEffect(() => {
    var thisobj;
    setOrder(orderId);
    thisobj = { 'rtype': 'orderlookup', 'id': orderId };
    localStorage.setItem("objall2", JSON.stringify(thisobj));
    localStorage.setItem("obj2", JSON.stringify(thisobj));
    localStorage.setItem("objname2", thisobj.rtype);
    localStorage.setItem('thisorder', '');
    document.getElementById('root').style.width = '';		
    doCheck();
    // eslint-disable-next-line
  }, [orderId]);
	
	const handletoggleInfo = (e) => {
		var target, thisclass;
        target = document.getElementById('displayInfo');
        thisclass = target.className;
		if(thisclass === 'show'){
			document.getElementById('displayInfo').className = 'hide';
			document.getElementById('toggleInfo').innerHTML = 'Show Info';
		}else{
			localStorage.setItem('thisorder', order);
			localStorage.setItem('thisemail', orderdata.email);
			document.getElementById('displayInfo').className = 'show';
			document.getElementById('toggleInfo').innerHTML = 'Hide Info';			
		}
	}
	
	const goHome = (e) => {
		if(localStorage.getItem('useremail')){
			window.location.assign("/dashboard");
		}else{
			window.location.assign("/");
		}		
	}
	
	
	
	if(!orderdata.email)
	{
		return (		
			<div className="statustimeline">
				<div className="card" key="p1">

				  <div className="card-content">
					<div className="author">
					  <div className="profile-img animated-bg" id="profile-img">&nbsp;</div>
					  <div className="author-info">
						<strong className="animated-bg animated-bg-text" id="name"
						  >&nbsp;</strong
						>
						<small className="animated-bg animated-bg-text" id="date">&nbsp;</small>
					  </div>
					</div>
					<br/>
					<h3 className="card-title animated-bg animated-bg-text" id="title">
					  &nbsp;
					</h3>
					<p className="card-excerpt" id="excerpt">
					  &nbsp;
					  <span className="animated-bg animated-bg-text">&nbsp;</span>
					  <span className="animated-bg animated-bg-text">&nbsp;</span>
					  <span className="animated-bg animated-bg-text">&nbsp;</span>
					</p>
					
				  </div>
				  
				  <div className="card-header animated-bg" id="header">&nbsp;</div>
				</div>
			</div>
		);
	}
	else
	{

		return (
			<div key='tte'><br/>
			<h3 className="siteorder" onClick={goHome} style={{cursor: 'pointer', fontWeight: '700', textAlign: 'center'}}>
			<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
			 <g className="layer">
			  <g fill="#000000" id="svg_1" transform="translate(0, 160) scale(0.1, -0.1)">
			   <path d="m112.7258,1553.26096c-31.91754,-21.26496 -41.49282,-29.60415 -56.99562,-49.20126c-8.66333,-10.84096 -15.50281,-20.84799 -15.50281,-22.93279c0,-1.66784 -3.19175,-8.33919 -7.29544,-15.42752c-17.78263,-31.27199 -15.04685,-99.65339 5.47157,-139.26457c15.50281,-30.43806 60.64335,-70.88316 95.29667,-85.89371c24.16614,-10.424 76.60211,-20.43102 82.07369,-15.42752c0.91194,1.25089 -5.92754,6.67136 -15.95878,12.92575c-21.88632,13.34271 -36.4772,31.27199 -56.53966,69.21533c-20.97439,40.4451 -27.3579,75.88668 -24.16614,137.17977c2.27982,44.19773 8.66333,74.6358 21.43036,105.49083c7.29544,16.67838 -5.01561,18.34623 -27.81386,3.33568l0,0l0,0.00001l0.00001,-0.00001l0.00001,0z" id="svg_3"/>
			   <path d="m239.48405,1540.33521c-11.39912,-10.424 -27.3579,-30.43806 -35.56527,-44.6147c-12.76702,-22.09887 -15.50281,-30.85502 -16.87071,-62.127c-1.82386,-30.0211 -0.91194,-37.10943 5.01561,-39.19422c10.48719,-3.33568 77.97001,33.77374 104.87194,58.37437c13.22299,11.67488 27.81386,28.35326 32.37351,36.69246l9.11929,15.42752l-24.62211,20.84799c-13.67895,11.67488 -28.26983,22.51582 -32.82948,23.76671c-4.55965,1.25089 -8.20737,4.1696 -8.20737,6.25439c0,8.75616 -14.13492,2.50175 -33.28544,-15.42752l0,0l0,-0.00001l0.00002,-0.00001l0.00001,0.00001z" id="svg_4"/>
			   <path d="m366.69829,1456.52628c-9.57526,-26.26846 -87.08931,-81.30716 -135.42159,-95.48379c-12.31105,-3.75264 -23.71018,-8.33919 -25.07807,-10.424c-1.3679,-2.0848 5.47157,-17.09535 15.04685,-33.77374c17.78263,-31.27199 60.64335,-74.21884 73.86633,-74.21884c4.10368,0 17.78263,8.33919 30.09369,18.34623c52.89193,42.11294 75.23421,118.41658 52.89193,181.79448c-6.83947,19.59711 -8.20737,21.68191 -11.39912,13.75967l0,0l-0.00001,0l-0.00001,-0.00001z" id="svg_5"/>
			  </g>
			 </g>
			</svg>HOME PAGE</h3><br/>
			<div className="statustimeline">
				<div className="statustimeline__header">
					<span>ORDER TRACKING</span>
					<h1>{`${order}` || ``}</h1>
					<span className="toggleInfo" onClick={handletoggleInfo}><font className="sendclick" id="toggleInfo">Show Info</font></span>
					<p id='displayInfo' className='hide' dangerouslySetInnerHTML={{__html: orderdata.info || ``}}></p><input type="hidden" id="thisorder" value={`${order}` || ``} />
					<div className="statusprocess">
						<div className={`xd${orderdata.progresslevel}` || `xd`}></div>
					</div>
				</div>
				<div className={`statusline ${orderdata.stamp}` || `statusline pending`}>
					<div className="statusline-content">
						<div className={orderdata.cls1 || "statuscontent pending"}>
							<h1><b>{orderdata.label || "Payment"}</b></h1>
							<p id="labelinfo" dangerouslySetInnerHTML={{__html:orderdata.labelinfo || `- For Order #${order}`}}></p>
						</div>
						<div className={orderdata.cls2 || "statuscontent pending"}>
							<h1><b>{orderdata.label2 || `. `}</b></h1>
							<p dangerouslySetInnerHTML={{__html:orderdata.labelinfo2 || `. `}}></p>
						</div>
						<div className={orderdata.cls3 || "statuscontent pending"}>
							<h1><b>{orderdata.label3 || `. `}</b></h1>
							<p dangerouslySetInnerHTML={{__html:orderdata.labelinfo3 || `. `}}></p>
						</div>
					</div>
				</div>
			</div>
			</div>
		);
	}		
}

export default SiteOrder;

